import {Action} from '@ngrx/store';
import {ParsNumber, ParsRequest} from 'src/app/core/models';

export enum EParsActions {
  GetParsNumbers = '[Pars] Get Pars Numbers',
  GetParsNumbersSuccess = '[Pars] Get Pars Numbers Success',
  GetParsRequest = '[Pars] Get Pars Request',
  GetParsRequestSuccess = '[Pars] Get Pars Request Success',
}

export class GetParsNumbers implements Action {
  public readonly type = EParsActions.GetParsNumbers;
}

export class GetParsNumbersSuccess implements Action {
  public readonly type = EParsActions.GetParsNumbersSuccess;
  constructor(public payload: ParsNumber[]) {}
}

export class GetParsRequest implements Action {
  public readonly type = EParsActions.GetParsRequest;
  constructor(public payload: number) {}
}

export class GetParsRequestSuccess implements Action {
  public readonly type = EParsActions.GetParsRequestSuccess;
  constructor(public payload: ParsRequest) {}
}

export type ParsActions = GetParsNumbers | GetParsNumbersSuccess | GetParsRequest | GetParsRequestSuccess;
