import {PIFacility} from 'src/app/core/models';

export interface FacilityState {
  facilities: PIFacility[];
  activeFacility: PIFacility;
}

export const initialFacilityState: FacilityState = {
  facilities: null,
  activeFacility: null,
};
