import {BatchSummary} from '../../models/batch-summary';
import {BatchSummaryDto} from '../dto/batch-summary.dto';

export function convertBatchSummaryDtosToModels(dtos: BatchSummaryDto[]): BatchSummary[] {
  return dtos.map(dto => convertBatchSummaryDtoToModel(dto));
}

function convertBatchSummaryDtoToModel(dto: BatchSummaryDto): BatchSummary {
  return {
    clearable: Boolean(dto.IsClearable),
    pieceCount: dto.PieceCount,
    pieceType: dto.PieceType,
    pieceTypeLabel: dto.PieceTypeLabel,
    statusType: dto.StatusType,
    statusTypeLabel: dto.StatusTypeLabel,
    totalWeight: dto.TotalWeight,
  };
}
