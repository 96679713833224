import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {Configuration} from 'src/app/core/models/configuration';
import {MessageService} from 'src/app/core/services';
import {GetUserInfoAction} from 'src/app/core/store/workspace/workspace.action';
import {ConfigurationApiService} from './../../../core/api/configuration-api.service';
import {ApplicationCategory, ApplicationCategoryApp} from './../../../core/models/application-category';
import {selectApplicationCategories, selectConfiguration} from './../../../core/store/workspace/workspace.selector';

@Component({
  selector: 'login-landing-page',
  templateUrl: './login-landing-page.component.html',
  styleUrls: ['./login-landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginLandingPageComponent implements OnInit {
  public applicationCategories$: Observable<ApplicationCategory[]>;
  public configuration$: Observable<Configuration>;

  public errorMessage$ = new BehaviorSubject<string>('');

  constructor(
    private messageService: MessageService,
    private configurationApiService: ConfigurationApiService,
    private router: Router,
    private store$: Store
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(
      new GetUserInfoAction({
        onFailure: err => this.onGetUserInfoFailure(err),
      })
    );

    this.configuration$ = this.store$.pipe(select(selectConfiguration));
    this.applicationCategories$ = this.store$.pipe(select(selectApplicationCategories));
  }

  private onGetUserInfoFailure(err: HttpErrorResponse) {
    this.messageService.add(err.message || 'There was an error getting user info.');
  }

  public onNavigate(application: ApplicationCategoryApp) {
    this.router.navigate([this.findUrl(application.caption)]);
    this.configurationApiService.sendLogInfo(application.appGuid).subscribe();
  }

  private findUrl(name: string) {
    switch (name) {
      case 'Clearance InspectR':
        return '/clearance-inspectr';
      case 'Batch InspectR':
        return '/batch-inspectr';
      case 'Document Repository':
        return '/file-search';
      case 'Gateway Batch Manager':
        return '/gateway';
      case 'Piece InspectR':
        return '/piece-inspectr';
      case 'Batch Tracking':
        return '/batch-tracking';
      case 'Gateway Games':
        return '/piece-inspectr-game';
      case 'Shipment Manager':
        return '/dashboard';
      case 'Piece Tracking':
        return '/tracking';
      default:
        return '/login';
    }
  }
}
