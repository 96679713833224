import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {BarcodeScannerComponent} from './barcode-scanner.component';
import {BarcodeScannerDialogComponent} from './dialog/barcode-scanner-dialog.component';
import {QuaggaJsComponent} from './quagga-js/quagga-js.component';

@NgModule({
  declarations: [BarcodeScannerComponent, QuaggaJsComponent, BarcodeScannerDialogComponent],
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule, MatIconModule],
  exports: [BarcodeScannerComponent],
})
export class BarcodeScannerModule {}
