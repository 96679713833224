import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  messages: string[] = [];

  constructor(private snackBar: MatSnackBar) {}

  add(message: string, duration?: number) {
    this.snackBar.open(message, 'Dismiss', {
      duration: duration || 10000,
      data: {},
    });
  }

  clear(message: string) {
    this.messages = this.messages.filter(m => m !== message);
  }
}
