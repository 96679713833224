<button
  *ngIf="applicationNameArray[1] !== 'ManifestR'"
  mat-flat-button
  (click)="onApplication()"
  [attr.data-testid]="applicationNameArray[0].toLowerCase().replace(' ', '') + '-button'"
>
  <div class="inner-button-container">
    <img [src]="iconSrc" class="icon" />
    <span class="title">{{ applicationNameArray[0] }}</span>
    <span class="title">{{ applicationNameArray[1] }} {{ applicationNameArray[2] || '' }}</span>
    <div class="description-container">
      <span class="description">{{ application.description }}</span>
    </div>
  </div>
</button>
