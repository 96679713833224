import {EParsActions, ParsActions} from './pars.actions';
import {ParsState, initialParsState} from './pars.state';

export function parsReducers(state = initialParsState, action: ParsActions): ParsState {
  switch (action.type) {
    case EParsActions.GetParsNumbersSuccess: {
      return {
        ...state,
        parsNumbers: action.payload,
      };
    }
    case EParsActions.GetParsRequestSuccess: {
      return {
        ...state,
        parsRequest: action.payload,
      };
    }
    default:
      return state;
  }
}
