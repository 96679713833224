<app-navbar (closeQuickAccessMenuIfOpen)="toggleQuickAccessDrawer(true)"></app-navbar>
<app-navbar-secondary
  (quickAccessMenuToggle)="toggleQuickAccessDrawer()"
  [drawerIsCollapsed]="drawerIsCollapsed"
></app-navbar-secondary>

<main [ngClass]="{'squeeze-right': !drawerIsCollapsed}">
  <router-outlet></router-outlet>
</main>

<app-menu-drawer (closeMenu)="drawerIsCollapsed = true"></app-menu-drawer>
