<aside>
  <div class="training-mode-closed-header">
    <button mat-icon-button (click)="onToggle()">
      <img src="assets/icons/plus-square.svg" />
    </button>
  </div>
  <div class="lesson-number-container">
    <div
      *ngFor="let lesson of lessons"
      class="lesson-number"
      [class.active]="activeLessonNumber === lesson.lessonNumber"
    >
      {{ lesson.lessonNumber }}
    </div>
  </div>
</aside>
