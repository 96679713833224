<header>
  <div class="back-button-container">
    <back-button (click)="onBack()"></back-button>
    <span>back</span>
  </div>

  <div class="right-button-container">
    <button *ngIf="lesson.status === TrainingModeStatus.Finished || !lesson.status" mat-flat-button class="blue-button">
      Start
    </button>
    <button *ngIf="lesson.status === TrainingModeStatus.Started" mat-flat-button class="grey-button">Cancel</button>
    <button *ngIf="lesson.status === TrainingModeStatus.Started" mat-flat-button class="blue-button">Finish</button>
  </div>
</header>

<training-mode-list-item [lesson]="lesson" fromDetail="true"></training-mode-list-item>

<training-mode-test-packages
  *ngIf="lesson.testPackages?.length"
  [lesson]="lesson"
  (copyPackages)="onCopy($event)"
></training-mode-test-packages>
