import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {RouterModule} from '@angular/router';
import {MobileHeaderModule} from './mobile-header/mobile-header.module';

@NgModule({
  imports: [CommonModule, MatButtonModule, RouterModule],
  exports: [MobileHeaderModule],
})
export class MobileModule {}
