import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrainingModeComponent} from './training-mode.component';
import {TrainingModeListItemComponent} from './item/training-mode-list-item.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {TrainingModeDetailComponent} from './detail/training-mode-detail.component';
import {BackButtonModule} from '../back-button/back-button.module';
import {TrainingModeTestPackagesComponent} from './detail/test-packages/training-mode-test-packages.component';
import {TrainingModeDrawerComponent} from './drawer/training-mode-drawer.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {TrainingModeDrawerCollapsedComponent} from './drawer-collapsed/training-mode-drawer-collapsed.component';

@NgModule({
  declarations: [
    TrainingModeComponent,
    TrainingModeListItemComponent,
    TrainingModeDetailComponent,
    TrainingModeTestPackagesComponent,
    TrainingModeDrawerComponent,
    TrainingModeDrawerCollapsedComponent,
  ],
  imports: [CommonModule, MatButtonModule, BackButtonModule, MatSidenavModule],
  exports: [TrainingModeDrawerComponent, TrainingModeComponent],
})
export class TrainingModeModule {}
