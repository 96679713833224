import {BatchInspectorSummaryParamsDto} from './../dto/batch-inspector-summary-params.dto';
import {BatchInspectorSummaryParams} from './../../models/batch-inspector-summary-params';
import * as moment from 'moment';

export function convertBatchInspectorSummaryParamsModelToDto(
  params: BatchInspectorSummaryParams
): BatchInspectorSummaryParamsDto {
  return {
    companyID: params.companyId,
    origin: params.origin,
    originFacilityCode: params.originFacility,
    facilityCode: params.destinationFacility,
    batchType: params.batchType,
    dateSearchType: formatDateSearchType(params.dateRangeSelection),
    startDate: moment(params.startDate).format('MM/DD/YYYY'),
    endDate: moment(params.endDate).format('MM/DD/YYYY'),
    batchNumber: params.search && `*${params.search}*`,
    pageNumber: params.page,
    rowsPerPage: params.pageSize,
    sortByFields: createSortByFields(params.sortColumns, params.sortDirections),
  };
}

function formatDateSearchType(type: string) {
  if (['WITHIN 1 WEEK', 'WITHIN 1 MONTH'].includes(type)) {
    return 'BETWEEN';
  }
  if (type === 'TODAY') {
    return 'ON';
  }
  return type;
}

function createSortByFields(sortColumns: string[], sortDirections: string[]): string {
  return sortColumns
    .map((column, index) => `${convertPieceDetailColumnModelToDto(column)} ${sortDirections[index]}`)
    .join(',');
}

function convertPieceDetailColumnModelToDto(column: string): string {
  switch (column) {
    case 'batchHeader':
      return 'BatchHeader';
    case 'batchNumber':
      return 'BatchNumber';
    case 'batchType':
      return 'BatchTypeLabel';
    case 'shipDate':
      return 'ShipDate';
    case 'packageCount':
      return 'PackageCount';
    case 'totalWeight':
      return 'TotalWeight';
    case 'statusType':
      return 'StatusType';
  }
}
