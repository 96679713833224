import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Lesson} from 'src/app/core/models/lesson';
import {TrainingModeStatus} from '../training-mode-status';

@Component({
  selector: 'training-mode-detail',
  templateUrl: './training-mode-detail.component.html',
  styleUrls: ['./training-mode-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingModeDetailComponent {
  @Input()
  public lesson: Lesson;

  @Output()
  public back = new EventEmitter();

  @Output()
  public copyPackages = new EventEmitter<string>();

  public readonly TrainingModeStatus = TrainingModeStatus;

  public onBack() {
    this.back.emit();
  }

  public onCopy(value: string) {
    this.copyPackages.emit(value);
  }

  public onStart() {
    console.log('start');
  }

  public onCancel() {
    console.log('cancel');
  }

  public onFinish() {
    console.log('finish');
  }
}
