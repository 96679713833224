import * as moment from 'moment';
import {GatewayBatch} from '../../models/gateway-batch';
import {GatewayBatchDto} from '../dto/gateway-batch.dto';
import {convertGatewayPieceDtoToModel} from './convert-gateway-piece-dto-to-model';
import {convertBatchFileDtoToModel} from './convert-batch-file-dto-to-model';

export function convertGatewayBatchDtoToModel(dto: GatewayBatchDto): GatewayBatch {
  return {
    batchHeader: dto.BatchHeader,
    batchLabel: dto.BatchLabel,
    batchIdentifier: dto.BatchIdentifier,
    batchNumber: dto.BatchNumber,
    batchType: dto.BatchType,
    batchTypeLabel: dto.BatchTypeLabel,
    cargoControlNumber: dto.CargoControlNumber || null,
    carrierTrackingNumber: dto.CarrierTrackingNumber || null,
    clearanceType: dto.ClearanceType,
    clearanceTypeLabel: dto.ClearanceTypeLabel,
    containerCount: dto.ContainerCount,
    depotCode: dto.DepotCode,
    documents: dto.Documents?.map(doc => convertBatchFileDtoToModel(doc)),
    origin: dto.Origin,
    originLabel: dto.OriginLabel,
    packageCount: dto.PackageCount,
    shipDate: dto.ShipDate ? moment.utc(dto.ShipDate) : null,
    statusType: dto.StatusType,
    statusTypeLabel: dto.StatusTypeLabel,
    totalWeight: dto.TotalWeight,
    pieces: dto.Pieces?.map(piece => convertGatewayPieceDtoToModel(piece)),
    reference1: dto.Reference1 || null,
    reference2: dto.Reference2 || null,
    reference3: dto.Reference3 || null,
    reference4: dto.Reference4 || null,
    reference5: dto.Reference5 || null,
  };
}
