import * as moment from 'moment';
import 'moment-timezone';

/**
 * Get timezone abbreviation for display in templates
 * @param timeZone - time zone locale name, e.g. 'America/New York'
 */
export function getTimeZoneAbbreviation(timeZone: string): string {
  const formattedTimeZone = timeZone.replace(' ', '_');
  /*
    NOTE: Angular DatePipe does not support timezone names/abbreviations,
    so we use moment.js for this.
    See: https://github.com/angular/angular/issues/20225
  */
  return moment.tz(formattedTimeZone).format('z');
}
