import {ParsRequest, ParsNumber} from 'src/app/core/models';

export interface ParsState {
  parsNumbers: ParsNumber[];
  parsRequest: ParsRequest;
}

export const initialParsState: ParsState = {
  parsNumbers: null,
  parsRequest: null,
};
