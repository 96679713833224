import {AuthToken} from '../../models/auth-token';
import {AuthTokenDto} from '../dto/auth-token.dto';

export function convertAuthTokenDtoToModel(dto: AuthTokenDto): AuthToken {
  return {
    accessToken: dto.access_token,
    expirationDate: dto.expires ? new Date(dto.expires) : null,
    tokenType: dto.token_type,
    userName: dto.userName,
    endpointUrl: dto.endpoint_url,
    instanceType: dto.instance_type,
  };
}
