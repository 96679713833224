<ng-container *ngIf="{activeLesson: activeLesson$ | async} as state">
  <header>
    <span>Training</span>
    <button *ngIf="!mobile" mat-icon-button (click)="onToggle()">
      <img src="assets/icons/minus-square.svg" />
    </button>
  </header>
  <section class="lessons-container">
    <ng-container *ngIf="!state.activeLesson; else lessonDetail">
      <training-mode-list-item
        *ngFor="let lesson of lessons"
        [lesson]="lesson"
        (click)="onLesson(lesson)"
      ></training-mode-list-item>
    </ng-container>

    <ng-template #lessonDetail>
      <training-mode-detail
        [lesson]="state.activeLesson"
        (back)="onBack()"
        (copyPackages)="onCopy($event)"
      ></training-mode-detail>
    </ng-template>
  </section>
</ng-container>
