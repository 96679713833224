import * as momentTimezone from 'moment-timezone';

const moment = momentTimezone;

/**
 * Get timezone offset in the format readable by Angular DatePipe ('+xxxx')
 * @param timeZone - time zone locale name, e.g. 'America/New York'
 */
export function getTimeZoneOffset(timeZone: string): string {
  const formattedTimeZone = timeZone.replace(' ', '_');
  /*
    NOTE: Angular DatePipe does not support timezone names/abbreviations,
    so we use moment.js for this.
    See: https://github.com/angular/angular/issues/20225
  */
  return moment().tz(formattedTimeZone).format('Z');
}
