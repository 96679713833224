/* eslint-disable */
import {DEFAULT_WORKSPACE_KEY} from './default-workspace-key';

export function getDefaultWorkspace(): Record<string, number> {
  try {
    return JSON.parse(window.localStorage.getItem(DEFAULT_WORKSPACE_KEY) || '{}');
  } catch (error) {
    return {};
  }
}
