<h2>Enter username and password</h2>

<form [formGroup]="form" (submit)="onSubmit()">
  <p
    [ngClass]="{
      'has-error': usernameControl.touched && usernameControl.errors
    }"
  >
    <img src="assets/user-v-2.svg" />
    <input type="text" placeholder="User name" formControlName="username" />
  </p>
  <p
    [ngClass]="{
      'has-error': passwordControl.touched && passwordControl.errors
    }"
  >
    <img src="assets/password.svg" />
    <input type="password" placeholder="Password" formControlName="password" />
  </p>
  <button mat-flat-button color="primary" [disabled]="loading$ | async" type="submit">
    <img src="assets/arrow-right.svg" />
    Login
  </button>

  <div class="error-message">{{ loginError$.value }}</div>
</form>
