import {GatewayGameLap, GatewayGameStatistics} from '../../models/piece-inspector-batch';
import {PieceInspectorScanPiece} from '../../models/piece-inspector-scan-piece';
import {GatewayGameLapDto, GatewayGameStatisticsDto} from '../dto/piece-inspector-batch.dto';
import {PieceInspectorScanPieceDto} from '../dto/piece-inspector-scan-piece.dto';

export function convertPieceInspectorScanPieceDtoToModel(dto: PieceInspectorScanPieceDto): PieceInspectorScanPiece {
  return {
    action: dto.Action,
    batchId: dto.BatchID,
    color: dto.Color,
    created: dto.Created,
    facility: dto.Facility,
    fontSize: dto.FontSize?.toLowerCase(),
    id: dto.ID,
    image: dto.Image,
    imageDuration: dto.ImageDuration,
    message: dto.Message,
    newStatus: dto.NewStatus,
    oldStatus: dto.OldStatus,
    pin: dto.PIN,
    pieceCount: dto.PieceCount,
    pieceCountMessage: dto.MessagePieceCount,
    pieceId: dto.PieceID,
    scanCode: dto.ScanCode,
    scanInterval: dto.ScanInterval,
    scanLog: dto.ScanLog ? dto.ScanLog.map(dto => convertPieceInspectorScanPieceDtoToModel(dto)) : null,
    severity: dto.Severity,
    sound: dto.Sound,
    userGuid: dto.UserGUID,
    username: dto.Username,
    statistics: dto.Statistics ? convertGatewayGameStatisticDtoToModel(dto.Statistics) : null,
  };
}

export function convertGatewayGameStatisticDtoToModel(dto: GatewayGameStatisticsDto): GatewayGameStatistics {
  return {
    scanCount: dto.ScanCount,
    remainingCount: dto.RemainingCount,
    totalCount: dto.TotalCount,
    currentLap: dto.CurrentLap,
    totalLaps: dto.TotalLaps,
    avgScanIntervalOverall: dto.AvgScanIntervalOverall,
    avgScanIntervalCurrentLap: dto.AvgScanIntervalCurrentLap,
    lastScanInterval: dto.LastScanInterval,
    totalTime: dto.TotalTime,
    laps: dto.Laps?.map(lap => convertGatewayGameLapDtoToModel(lap)),
  };
}

function convertGatewayGameLapDtoToModel(dto: GatewayGameLapDto): GatewayGameLap {
  return {
    index: dto.Index,
    scanCount: dto.ScanCount,
    remainingCount: dto.RemainingCount,
    totalCount: dto.TotalCount,
    avgScanInterval: dto.AvgScanInterval,
    totalTime: dto.TotalTime,
  };
}
