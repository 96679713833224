import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Constants} from 'src/app/shared/constants';
import {SharedModule} from 'src/app/shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {CoreModule} from './core/core.module';
import {MenuModule} from './menu/menu.module';
import 'moment-duration-format';
import {MatNativeDateModule} from '@angular/material/core';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AuthModule,
    MenuModule,
    AppRoutingModule,
    MatNativeDateModule,
  ],
  providers: [
    Constants,
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
