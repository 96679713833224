import {Component, ViewChild} from '@angular/core';
import {MenuDrawerComponent} from 'src/app/shared/components';

@Component({
  selector: 'app-dra-layout',
  templateUrl: './dra-layout.component.html',
  styleUrls: ['./dra-layout.component.scss'],
})
export class DRALayoutComponent {
  @ViewChild(MenuDrawerComponent) quickAccessDrawer: MenuDrawerComponent;
  public drawerIsCollapsed: boolean = false;

  public toggleQuickAccessDrawer(closeOnly?: boolean): void {
    if (closeOnly && this.quickAccessDrawer.collapsed) {
      return;
    }
    this.drawerIsCollapsed = !this.quickAccessDrawer.collapsed;
    this.quickAccessDrawer.toggleMenu();
  }
}
