import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {Configuration} from '../models/configuration';
import {selectConfiguration} from '../store/workspace/workspace.selector';
import {BatchSummaryParamsDto} from './dto/batch-summary-params.dto';
import {BatchSummaryDto} from './dto/batch-summary.dto';
import {PieceDetailParamsExcelDto} from './dto/piece-detail-params-excel.dto';
import {PieceDetailParamsDto} from './dto/piece-detail-params.dto';
import {PieceDetailsPageDto} from './dto/piece-detail.dto';

@Injectable({
  providedIn: 'root',
})
export class StatsApiService {
  private configuration$: Observable<Configuration>;
  private url: string;

  constructor(private http: HttpClient, private store$: Store) {
    this.configuration$ = this.store$.pipe(select(selectConfiguration));
    this.configuration$
      .pipe(
        filter(config => !!config),
        take(1)
      )
      .subscribe(config => {
        this.url = `${config.userInformation.endpointUrlDra}/api`;
      });
  }

  public getBatchSummaries(params?: BatchSummaryParamsDto): Observable<BatchSummaryDto[]> {
    return this.http.get<BatchSummaryDto[]>(`${this.url}/StatsSummary`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceDetails(params: PieceDetailParamsDto): Observable<PieceDetailsPageDto> {
    return this.http.get<PieceDetailsPageDto>(`${this.url}/StatsDetail`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceDetailsExcelFile(params: PieceDetailParamsExcelDto) {
    return this.http.get(`${this.url}/StatsDownload`, {
      params: JSON.parse(JSON.stringify({...params})),
      responseType: 'blob',
    });
  }
}
