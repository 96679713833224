import {ApplicationId} from '../../models/application';
import {NewContainerParams, NewContainerParamsDto} from '../../models/new-container-form';

export function convertNewContainerParamsModelToDto(dto: NewContainerParams): NewContainerParamsDto {
  return {
    AccountGroup: dto.accountGroup,
    AppID: ApplicationId.ClientDashboard,
    CompanyID: dto.company,
    OriginLocation: dto.origin,
    InductionLocation: dto.induction,
    BatchNumber: dto.name,
    BatchType: 1002,
    DepartureDate: dto.edd?.toISOString() || null,
    InductionDate: dto.eda?.toISOString() || null,
    PinList: dto.pinList,
    OriginCountry: dto.country,
    IgnoreInvalidPins: dto.ignoreInvalidPins,
  };
}
