import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {RouterModule} from '@angular/router';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MenuDrawerComponent, NavbarComponent, NavbarSecondaryComponent} from './components';
import {ModalBodyDirective, ModalComponent, ModalFooterDirective} from './components/modal/modal.component';
import {DraDesignModule} from './dra-design/dra-design.module';
import {DRALayoutComponent} from './dra-layout/dra-layout.component';
import {DraSortModule} from './dra-sort/dra-sort.module';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {PipesModule} from './pipes/pipes.module';
import {StandalonePageModule} from './standalone-page/standalone-page.module';
import {TrackingLayoutModule} from './tracking-layout/tracking-layout.module';
import {MobileModule} from './mobile/mobile.module';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {ShareFilesDialogModule} from './file-search/share-files-dialog/share-files-dialog.module';
import {InfoContainerComponent} from './info-container/info-container.component';
import {DraMatSelectComponent} from './dra-mat-select/dra-mat-select.component';
import {DraDatePickerComponent} from './dra-date-picker/dra-date-picker.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DraEmptyModule} from './dra-empty/dra-empty.module';
import {DebuggingPageModule} from './debugging-page/debugging-page.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    AutoCompleteModule,
    MatProgressBarModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatGridListModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatSnackBarModule,
    MatDatepickerModule,
    StandalonePageModule,
    PipesModule,
    DraSortModule,
    TrackingLayoutModule,
    MobileModule,
    DraDesignModule,
    ShareFilesDialogModule,
    ReactiveFormsModule,
    DraEmptyModule,
    DebuggingPageModule,
  ],
  declarations: [
    DRALayoutComponent,
    MenuDrawerComponent,
    NavbarComponent,
    NavbarSecondaryComponent,
    PageNotFoundComponent,
    ModalComponent,
    ModalBodyDirective,
    ModalFooterDirective,
    InfoContainerComponent,
    DraMatSelectComponent,
    DraDatePickerComponent,
  ],
  exports: [
    DRALayoutComponent,
    MatProgressBarModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MenuDrawerComponent,
    NavbarComponent,
    NavbarSecondaryComponent,
    ModalComponent,
    ModalBodyDirective,
    ModalFooterDirective,
    StandalonePageModule,
    PipesModule,
    DraSortModule,
    TrackingLayoutModule,
    MobileModule,
    DraDesignModule,
    InfoContainerComponent,
    DraMatSelectComponent,
    DraDatePickerComponent,
    DraEmptyModule,
  ],
})
export class SharedModule {}
