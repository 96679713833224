import {Piece} from 'projects/dra-lib/src/public-api';

export interface AuthTrackingState {
  loaded: boolean;
  piecesMap: Record<string, Piece[]>;
}

export const initialAuthTrackingState: AuthTrackingState = {
  loaded: false,
  piecesMap: {},
};
