import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'loading-results',
  templateUrl: './loading-results.component.html',
  styleUrls: ['./loading-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingResultsComponent {
  @Input()
  public text: string;

  @Input()
  public marginTop: number;
}
