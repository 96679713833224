import {GatewaySummaryParams} from '../../models/gateway-summary-params';
import {GatewaySummaryParamsDto} from '../dto/gateway-summary-params.dto';

export function convertGatewaySummaryParamsModelToDto(params: GatewaySummaryParams): GatewaySummaryParamsDto {
  return {
    facilityCode: params.facility || 'null',
    origin: params.shipper,
    statusType: params.statusType,
    batchNumber: params.search && `*${params.search}*`,
    pageNumber: params.page,
    rowsPerPage: params.pageSize,
    sortByFields: createSortByFields(params.sortColumns, params.sortDirections),
    companyId: params.companyId,
  };
}

function createSortByFields(sortColumns: string[], sortDirections: string[]): string {
  return sortColumns
    .map((column, index) => `${convertPieceDetailColumnModelToDto(column)} ${sortDirections[index]}`)
    .join(',');
}

function convertPieceDetailColumnModelToDto(column: string): string {
  switch (column) {
    case 'batchNumber':
      return 'BatchNumber';
    case 'affiliate':
      return 'BatchHeader';
    case 'clearanceType':
      return 'ClearanceType';
    case 'shipDate':
      return 'ShipDate';
    case 'packageCount':
      return 'PackageCount';
    case 'totalWeight':
      return 'TotalWeight';
    case 'statusType':
      return 'StatusType';
  }
}
