import {Carrier} from 'src/app/core/models';

export interface CarrierState {
  carriers: Carrier[];
  activeCarrier: Carrier;
}

export const initialCarrierState: CarrierState = {
  carriers: null,
  activeCarrier: null,
};
