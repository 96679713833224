import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MobileHeaderModule} from '../shared/mobile/mobile-header/mobile-header.module';
import {SharedModule} from '../shared/shared.module';
import {MobileMenuComponent} from './mobile-menu.component';

@NgModule({
  declarations: [MobileMenuComponent],
  imports: [CommonModule, MatButtonModule, MobileHeaderModule, SharedModule],
})
export class MenuModule {}
