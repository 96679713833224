<div class="menu" *ngIf="!collapsed">
  <header>
    <h2>Quick Access</h2>
    <img src="assets/close-v-1.svg" alt="close" (click)="toggleMenu()" [style.cursor]="'pointer'" />
  </header>

  <ul>
    <li *ngFor="let c of categories" (click)="setActiveCategory(c.text)">
      <a [style.borderBottom]="c.text === activeCategory ? '3px solid ' + c.color : 'none'">
        <img [src]="c.text === activeCategory ? c.activeIcon : c.inactiveIcon" [alt]="c.text + ' icon'" />
        <span [style.color]="c.text === activeCategory ? c.color : '#c6c6c8'">
          {{ c.text }}
        </span>
      </a>
    </li>
  </ul>

  <div class="menu-item" *ngFor="let m of filteredMenuItems">
    <span>{{ m.name }}</span>
    <span><img src="assets/arrow-right-small-v-1.svg" alt="arrow icon" /></span>
  </div>
</div>
