import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BackButtonModule} from '../../back-button/back-button.module';
import {MobileHeaderComponent} from './mobile-header.component';

@NgModule({
  declarations: [MobileHeaderComponent],
  imports: [CommonModule, BackButtonModule],
  exports: [MobileHeaderComponent],
})
export class MobileHeaderModule {}
