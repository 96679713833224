import {BatchSummaryParams} from '../../models/batch-summary-params';
import {BatchSummaryParamsDto} from '../dto/batch-summary-params.dto';

export function convertBatchSummaryParamsModelToDto(params: BatchSummaryParams): BatchSummaryParamsDto {
  const dto: BatchSummaryParamsDto = {};

  if (params.facility) {
    dto.facilityCode = params.facility;
  }

  if (params.shipper) {
    dto.origin = params.shipper;
  }

  if (params.pieceType) {
    dto.pieceType = params.pieceType;
  }

  if (params.companyId) {
    dto.companyId = params.companyId;
  }

  return dto;
}
