/* eslint-disable */

import {ActionReducerMap} from '@ngrx/store';
import {AppState} from './app.state';
import {authReducer} from './auth/auth.reducer';
import {batchTrackingReducer} from './batch-tracking/batch-tracking.reducer';
import {carrierReducers} from './carrier/carrier.reducers';
import {facilityReducers} from './facility/facility.reducers';
import {parsReducers} from './pars/pars.reducers';
import {customRouterReducer} from './router/router.reducer';
import {workspaceReducer} from './workspace/workspace.reducer';
import {gatewayReducer} from './gateway/gateway.reducer';
import {batchInspectorReducer} from './batch-inspector/batch-inspector.reducer';
import {pieceInspectorReducer} from './piece-inspector/piece-inspector.reducer';
import {clearanceInspectorReducer} from './clearance-inspector/clearance-inspector.reducer';
import {clientDashboardReducer} from './client-dashboard/client-dashboard.reducer';
import {authTrackingReducer} from './auth-tracking/auth-tracking.reducer';

export const appReducer: ActionReducerMap<AppState, any> = {
  auth: authReducer,
  batchTracking: batchTrackingReducer,
  carriers: carrierReducers,
  facilities: facilityReducers,
  pars: parsReducers,
  router: customRouterReducer,
  workspace: workspaceReducer,
  gateway: gatewayReducer,
  batchInspector: batchInspectorReducer,
  pieceInspector: pieceInspectorReducer,
  clearanceInspector: clearanceInspectorReducer,
  clientDashboard: clientDashboardReducer,
  authTracking: authTrackingReducer,
};
