import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {StandalonePageComponent} from './standalone-page.component';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [StandalonePageComponent],
  exports: [StandalonePageComponent],
})
export class StandalonePageModule {}
