import {UserInfo} from '../../models/user-info';
import {UserInfoDto} from '../dto/user-info.dto';

export function convertUserInfoModelToDto(model: Partial<UserInfo>): Partial<UserInfoDto> {
  return {
    Username: model.username,
    DisplayName: model.displayName,
    GameHandle: model.gameHandle,
  };
}
