import {Pipe, PipeTransform} from '@angular/core';
import {Piece} from './model/piece';

@Pipe({
  name: 'activePieceStatusIndex',
})
export class ActivePieceStatusIndexPipe implements PipeTransform {
  public transform(piece: Piece): number {
    return piece?.statusEvents?.reduce(
      (lastIndex, statusEvent, index) => (statusEvent.timestamp ? index : lastIndex),
      0
    );
  }
}
