import {ClearanceInspectorCheckedMap} from './../../models/clearance-inspector-checked-map';
import {ClearanceInspectorData} from './../../models/clearance-inspector-data';
export interface ClearanceInspectorState {
  clearanceDataMap: Record<string, ClearanceInspectorData>;
  checkedMap: ClearanceInspectorCheckedMap;
  loadingAfterCloseout: boolean;
}

export const initialClearanceInspectorState: ClearanceInspectorState = {
  clearanceDataMap: {},
  checkedMap: {} as ClearanceInspectorCheckedMap,
  loadingAfterCloseout: false,
};
