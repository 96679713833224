import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingConfigurationComponent} from './loading-configuration.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';

@NgModule({
  declarations: [LoadingConfigurationComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [LoadingConfigurationComponent],
})
export class LoadingConfigurationModule {}
