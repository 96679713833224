import {BatchComparisonDetailParams} from './../../models/batch-comparison-detail-params';
import {PieceDetail} from './../../models/piece-detail';
import {BatchComparison} from '../../models/batch-comparison';
import {BatchManagerBatch} from './../../models/batch-manager-batch';

export interface BatchInspectorState {
  batchesCount: number;
  batches: BatchManagerBatch[];
  batchComparison: BatchComparison;
  pieces: PieceDetail[];
  piecesCount: number;
  piecesParams: BatchComparisonDetailParams;
}

export const initialBatchInspectorState: BatchInspectorState = {
  batchesCount: 0,
  batches: [],
  batchComparison: {} as BatchComparison,
  pieces: [],
  piecesCount: 0,
  piecesParams: {} as BatchComparisonDetailParams,
};
