import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'dra-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  public unsupportedBrowser: boolean;

  ngOnInit(): void {
    const userAgent = navigator.userAgent;
    this.unsupportedBrowser = userAgent.includes('MSIE ') || userAgent.includes('Trident/');
  }
}
