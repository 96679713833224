import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {BatchTransitionType} from '../models/batch-transition-type';
import {ClientDashboardParams} from '../models/client-dashboard-summary-params';
import {Configuration} from '../models/configuration';
import {NewContainerParamsDto} from '../models/new-container-form';
import {selectConfiguration} from '../store/workspace/workspace.selector';
import {ClientDashboardBatchDto} from './dto/client-dashboard-batch.dto';
import {ClientDashboardBatchesDto} from './dto/client-dashboard-batches.dto';
import {ClientDashboardParamsDto} from './dto/client-dashboard-params.dto';
import {ClientDashboardTrainerPackageParamsDto} from './dto/client-dashboard-trainer-package-params.dto';
import {PieceDetailsPageDto} from './dto/piece-detail.dto';

@Injectable({
  providedIn: 'root',
})
export class ClientDashboardApiService {
  private configuration$: Observable<Configuration>;
  private url: string;

  constructor(private http: HttpClient, private store$: Store) {
    this.configuration$ = this.store$.pipe(select(selectConfiguration));
    this.configuration$
      .pipe(
        filter(config => !!config),
        take(1)
      )
      .subscribe(config => {
        this.url = `${config.userInformation.endpointUrlDra}/api`;
      });
  }

  public getRecentBatches(params: ClientDashboardParams): Observable<ClientDashboardBatchesDto> {
    return this.http.get<ClientDashboardBatchesDto>(`${String(this.url)}/ClientBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getBatch(batchId: string): Observable<ClientDashboardBatchDto> {
    return this.http.get<ClientDashboardBatchDto>(`${String(this.url)}/ClientBatch`, {
      params: {batchId},
    });
  }

  public getPieceDetails(params: ClientDashboardParamsDto): Observable<PieceDetailsPageDto> {
    return this.http.get<PieceDetailsPageDto>(`${String(this.url)}/ClientBatchPieces`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public closeoutByPieces(
    appId: string,
    companyId: number,
    pinList: string[],
    transitionType: string,
    origin?: string
  ): Observable<ClientDashboardBatchDto> {
    return this.http.post<ClientDashboardBatchDto>(`${String(this.url)}/ClientBatch`, {
      AppId: appId,
      CompanyID: companyId,
      PinList: pinList,
      TransitionType: transitionType,
      Origin: origin,
      DepotCode: 'DTW',
    });
  }

  public closeoutByContainer(batchNumber: string, transitionType: BatchTransitionType, excludedPieceIds: string[]) {
    return this.http.put(`${String(this.url)}/ClientBatchStatus`, {
      BatchNumber: batchNumber,
      TransitionType: transitionType,
      ExcludePieceIDs: excludedPieceIds,
    });
  }

  public createContainer(params: NewContainerParamsDto): Observable<ClientDashboardBatchDto> {
    return this.http.post<ClientDashboardBatchDto>(
      `${String(this.url)}/ClientBatch`,
      JSON.parse(JSON.stringify({...params}))
    );
  }

  // ----------Trainer Mode----------
  public getTrainerPackages(params: ClientDashboardTrainerPackageParamsDto): Observable<string[]> {
    console.log(params);
    return of(s());
  }

  createTestPackages(count: number): string[] {
    const arr = [];
    for (let i = 0; i < count; i++) {
      arr.push(`TST00000000${i}`);
    }
    return arr;
  }
}

function s(): string[] {
  const arr = [];
  for (let i = 0; i < 1000; i++) {
    arr.push(`TST00000000${i}`);
  }
  return arr;
}
