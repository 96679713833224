import {PieceInspectorScanPiece} from './../../models/piece-inspector-scan-piece';
import {PieceInspectorBatch} from './../../models/piece-inspector-batch';

export interface PieceInspectorState {
  batchesCount: number;
  batches: PieceInspectorBatch[];
  queryBatchesMap: Record<string, PieceInspectorBatch[]>;
  singleBatch: PieceInspectorBatch;
  scanPiece: PieceInspectorScanPiece;
  createdBatch: PieceInspectorBatch;
  recentBatches: PieceInspectorBatch[];
  batchesFromScan: PieceInspectorBatch[];
  queryCloseoutBatchesMap: Record<string, PieceInspectorBatch[]>;
  backgroundColor: string;
  gameState: string;
}

export const initialPieceInspectorState: PieceInspectorState = {
  batchesCount: 0,
  batches: [],
  queryBatchesMap: {},
  singleBatch: null,
  scanPiece: null,
  createdBatch: null,
  recentBatches: null,
  batchesFromScan: null,
  queryCloseoutBatchesMap: {},
  backgroundColor: '',
  gameState: '',
};
