import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {Application} from '../../models/application';
import {Configuration} from '../../models/configuration';
import {UserInfo} from '../../models/user-info';

export enum WorkspaceActionType {
  GET_CONFIGURATION = '[Workspace] Get Configuration',
  GET_CONFIGURATION_SUCCESS = '[Workspace] Get Configuration :: Success',

  GET_APP_SETTINGS = '[Workspace] Get Application Settings',
  GET_APP_SETTINGS_SUCCESS = '[Workspace] Get Application Settings :: Success',

  SELECT = '[Workspace] Select',

  UPDATE_SIDENAV_STATUS = '[Workspace] Update Sidenav Status',

  GET_USER_INFO = '[Workspace] Get User Info',
  GET_USER_INFO_SUCCESS = '[Workspace] Get User Info :: Success',

  UPDATE_USER_INFO = '[Workspace] Update User Info',
  UPDATE_USER_INFO_SUCCESS = '[Workspace] Update User Info :: Success',

  UPDATE_RIGHT_DRAWER_STATUS = '[Workspace] Update Right Drawer Status',

  UPDATE_PADDING_COLOR = '[Workspace] Update Workspace Padding Color',

  CLEAR = '[Workspace] Clear',
}

export class GetConfigurationAction implements Action {
  public readonly type = WorkspaceActionType.GET_CONFIGURATION;

  constructor(
    public payload: {
      force?: boolean;
      onSuccess?: (configuration: Configuration) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetConfigurationSuccessAction implements Action {
  public readonly type = WorkspaceActionType.GET_CONFIGURATION_SUCCESS;

  constructor(public payload: {configuration: Configuration}) {}
}

export class GetAppSettingsAction implements Action {
  public readonly type = WorkspaceActionType.GET_APP_SETTINGS;

  constructor(
    public payload: {
      applicationId: string;
      force?: boolean;
      onSuccess?: (appSettings: Application) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetAppSettingsSuccessAction implements Action {
  public readonly type = WorkspaceActionType.GET_APP_SETTINGS_SUCCESS;

  constructor(public payload: {applicationId: string; appSettings: Application}) {}
}

export class SelectWorkspaceAction implements Action {
  public readonly type = WorkspaceActionType.SELECT;

  constructor(public payload: {applicationId: string; companyId: number}) {}
}

export class UpdateSidenavStatusAction implements Action {
  public readonly type = WorkspaceActionType.UPDATE_SIDENAV_STATUS;

  constructor(public payload: {opened: boolean}) {}
}

export class GetUserInfoAction implements Action {
  public readonly type = WorkspaceActionType.GET_USER_INFO;

  constructor(
    public payload: {
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetUserInfoSuccessAction implements Action {
  public readonly type = WorkspaceActionType.GET_USER_INFO_SUCCESS;

  constructor(public payload: {userInfo: UserInfo}) {}
}

export class UpdateUserInfoAction implements Action {
  public readonly type = WorkspaceActionType.UPDATE_USER_INFO;

  constructor(
    public payload: {
      userInfo: Partial<UserInfo>;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateUserInfoSuccessAction implements Action {
  public readonly type = WorkspaceActionType.UPDATE_USER_INFO_SUCCESS;

  constructor(public payload: {userInfo: UserInfo}) {}
}

export class UpdateRightDrawerStatusAction implements Action {
  public readonly type = WorkspaceActionType.UPDATE_RIGHT_DRAWER_STATUS;

  constructor(public payload: {opened: boolean}) {}
}

export class UpdatePageWrapperPaddingColorAction implements Action {
  public readonly type = WorkspaceActionType.UPDATE_PADDING_COLOR;

  constructor(public payload: {paddingColor: string}) {}
}

export class ClearWorkspaceAction implements Action {
  public readonly type = WorkspaceActionType.CLEAR;
}

export type WorkspaceAction =
  | GetConfigurationAction
  | GetConfigurationSuccessAction
  | GetAppSettingsAction
  | GetAppSettingsSuccessAction
  | SelectWorkspaceAction
  | UpdateSidenavStatusAction
  | GetUserInfoAction
  | GetUserInfoSuccessAction
  | UpdateUserInfoAction
  | UpdateUserInfoSuccessAction
  | UpdateRightDrawerStatusAction
  | UpdatePageWrapperPaddingColorAction
  | ClearWorkspaceAction;
