// import { convertBatchFileDtoToModel } from 'src/app/core/api/converters/convert-batch-file-dto-to-model';
import {Address, Piece, StatusEvent, TrackingEvent} from '../../model/piece';
import {AddressDto, PieceDto, StatusEventDto, TrackingEventDto} from '../dto/piece.dto';
import {convertPieceDocumentDtoToModel} from './convert-piece-document-dto-to-model';

export function convertPieceDtoToModel(dto: PieceDto): Piece {
  return (
    dto && {
      consoleTrackingNumber: dto.ConsoleTrackingNumber,
      defaultTimeZone: dto.DefaultTimeZone,
      displayTrackingNumber: dto.DisplayTrackingNumber,
      documents: dto.Documents?.map(document => convertPieceDocumentDtoToModel(document)),
      fromAddress: convertAddressDtoToModel(dto.FromAddress),
      orderNumber: dto.OrderNumber,
      id: dto.PieceId,
      trackingNumber: dto.PieceTrackingNumber,
      projectedArrivalDate: dto.ProjectedArrivalDate ? new Date(dto.ProjectedArrivalDate) : null,
      serviceType: dto.ServiceType,
      shipperAccount: dto.ShipperAccount,
      shipperName: dto.ShipperName,
      statusEvents: dto.StatusEvents?.map(statusEvent => convertStatusEventDtoToModel(statusEvent)),
      toAddress: convertAddressDtoToModel(dto.ToAddress),
      trackingEvents: dto.TrackingEvents?.map(trackingEvent => convertTrackingEventDtoToModel(trackingEvent)),
    }
  );
}

function convertAddressDtoToModel(dto: AddressDto): Address {
  return (
    dto && {
      city: dto.City,
      country: dto.Country,
      postalCode: dto.PostalCode,
      stateProvince: dto.StateProvince,
    }
  );
}

function convertStatusEventDtoToModel(dto: StatusEventDto): StatusEvent {
  return (
    dto && {
      label: dto.Label,
      timestamp: dto.Timestamp ? new Date(dto.Timestamp) : null,
    }
  );
}

function convertTrackingEventDtoToModel(dto: TrackingEventDto): TrackingEvent {
  return (
    dto && {
      city: dto.City,
      country: dto.Country,
      label: dto.Label,
      labelDetails: dto.LabelDetails,
      locationDisplay: dto.LocationDisplay,
      stateProvince: dto.StateProvince,
      timestamp: dto.Timestamp ? new Date(dto.Timestamp) : null,
    }
  );
}
