<ng-container
  *ngIf="{
    authenticated: authenticated$ | async,
    configuration: configuration$ | async,
    mobile: mobile$ | async,
    userInfo: userInfo$ | async,
    pageTitle: pageTitle$ | async,
    sideNavOpened: sideNavOpened$ | async,
    showCondensedSideNav: showCondensedSideNav$ | async,
    batchTrackingDetailDrawerOpened: batchTrackingDetailDrawerOpened$ | async,
    rightDrawerOpened: rightDrawerOpened$ | async,
    paddingColor: paddingColor$ | async,
    showSideNav: showSideNav$ | async,
    loading: loading$ | async,
    defaultPadding: defaultPadding$ | async,
    configurationLoadingError: configurationLoadingError$ | async,
    trainingMode: trainingMode$ | async,
    trainingModeMobileOpen: trainingModeMobileOpen$ | async
  } as state"
>
  <ng-container *ngIf="state.loading">
    <loading-configuration></loading-configuration>
  </ng-container>

  <ng-container *ngIf="state.configurationLoadingError">
    <configuration-loading-error></configuration-loading-error>
  </ng-container>

  <ng-container *ngIf="!state.loading && !state.configurationLoadingError">
    <training-mode-drawer
      *ngIf="state.trainingMode && !state.mobile"
      class="main-training-container"
      [lessons]="lessons"
    >
      <ng-container *ngTemplateOutlet="mainContent"></ng-container>
    </training-mode-drawer>
    <div *ngIf="!state.trainingMode || state.mobile" class="main-container">
      <ng-container *ngTemplateOutlet="mainContent"></ng-container>
    </div>
  </ng-container>

  <ng-template #content>
    <ng-content select="[slot=content]"></ng-content>
  </ng-template>

  <ng-template #rightDrawer>
    <ng-content select="[slot=right-drawer]"></ng-content>
  </ng-template>

  <ng-template #mainContent>
    <ng-container *ngIf="!state.mobile; else mobile">
      <tracking-header
        [authenticated]="state.authenticated"
        [pageTitle]="state.pageTitle"
        [showLogout]="!state.showSideNav"
        [trainingMode]="state.trainingMode"
        class="tracking-header"
      ></tracking-header>
      <div *ngIf="state.showSideNav" class="lower-main-container">
        <tracking-side-nav
          *ngIf="state.authenticated && !state.sideNavOpened"
          [applications]="state.configuration?.applications"
          (sideMenu)="toggleDesktopDrawer()"
          [class.show-nav]="!state.sideNavOpened"
          [class.hide-nav]="state.sideNavOpened"
        ></tracking-side-nav>
        <mat-drawer-container hasBackdrop="false" class="flex-grow">
          <mat-drawer #desktopDrawer mode="side" [opened]="state.sideNavOpened">
            <tracking-drawer-nav
              [applications]="state.configuration?.applications"
              [username]="state.userInfo?.displayName"
              [mobile]="state.mobile"
              (collapse)="toggleDesktopDrawer()"
            ></tracking-drawer-nav>
          </mat-drawer>

          <mat-drawer mode="side" position="end" [opened]="state.rightDrawerOpened" class="right-drawer">
            <ng-container *ngTemplateOutlet="rightDrawer"></ng-container>
          </mat-drawer>
          <mat-drawer-content class="main-drawer-content">
            <div class="lower-main-container-content">
              <div
                class="desktop-container"
                [class.tracking-background-color]="!state.paddingColor"
                [class.primary-background-color]="state.paddingColor === 'primary'"
                [class.green]="state.paddingColor === 'green'"
                [class.blue]="state.paddingColor === 'blue'"
                [class.yellow]="state.paddingColor === 'yellow'"
                [class.orange]="state.paddingColor === 'orange'"
                [class.red]="state.paddingColor === 'red'"
              >
                <div
                  class="desktop-content-container"
                  [class.shortened-width]="!state.batchTrackingDetailDrawerOpened"
                  [class.full-width]="state.batchTrackingDetailDrawerOpened"
                  [class.drawer-content-container-margin]="!state.sideNavOpened"
                  [class.no-padding]="!state.defaultPadding"
                >
                  <ng-container *ngTemplateOutlet="content"></ng-container>
                </div>
              </div>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>
      <ng-container *ngIf="!state.showSideNav">
        <div class="desktop-container">
          <div
            class="desktop-content-container"
            [class.shortened-width]="!state.batchTrackingDetailDrawerOpened"
            [class.full-width]="state.batchTrackingDetailDrawerOpened"
            [class.drawer-content-container-margin]="!state.sideNavOpened && state.showSideNav"
            [class.no-padding]="!state.defaultPadding"
          >
            <ng-container *ngTemplateOutlet="content"></ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #mobile>
      <ng-container *ngIf="state.showSideNav; else hideMobileNav">
        <mat-drawer-container class="mobile-sidenav-container" hasBackdrop="true">
          <mat-drawer #mobileDrawer mode="over">
            <tracking-drawer-nav
              [applications]="state.configuration?.applications"
              [username]="state.userInfo?.displayName"
              [mobile]="state.mobile"
              (collapse)="mobileDrawer.toggle()"
            ></tracking-drawer-nav>
          </mat-drawer>
          <mat-drawer
            mode="over"
            position="end"
            [opened]="state.rightDrawerOpened"
            class="right-drawer"
            (closedStart)="onRightDrawerBackdropClick()"
          >
            <ng-container *ngIf="state.trainingModeMobileOpen; else otherContent">
              <training-mode [lessons]="lessons" [mobile]="state.mobile"></training-mode>
            </ng-container>
            <ng-template #otherContent>
              <ng-container *ngTemplateOutlet="rightDrawer"></ng-container>
            </ng-template>
          </mat-drawer>

          <mat-drawer-content class="main-drawer-content">
            <mobile-tracking-header
              (menu)="mobileDrawer.toggle()"
              [pageTitle]="state.pageTitle"
              [authenticated]="state.authenticated"
              [showLogout]="!state.showSideNav"
              [trainingMode]="state.trainingMode"
              (toggleTrainingMode)="onTrainingModeMobileDrawerToggle()"
            ></mobile-tracking-header>

            <div class="lower-main-container">
              <tracking-side-nav
                *ngIf="state.authenticated && state.showCondensedSideNav"
                [applications]="state.configuration?.applications"
                [mobile]="true"
              ></tracking-side-nav>
              <div class="lower-main-container-content">
                <div
                  class="mobile-content-container"
                  [class.drawer-content-container-margin]="state.showCondensedSideNav && state.showSideNav"
                  class="mobile-content-container"
                  [class.drawer-content-container-margin]="state.showCondensedSideNav"
                  [class.tracking-background-color]="!state.paddingColor"
                  [class.primary-background-color]="state.paddingColor === 'primary'"
                  [class.green]="state.paddingColor === 'green'"
                  [class.blue]="state.paddingColor === 'blue'"
                  [class.yellow]="state.paddingColor === 'yellow'"
                  [class.orange]="state.paddingColor === 'orange'"
                  [class.red]="state.paddingColor === 'red'"
                  [class.no-padding]="!state.defaultPadding"
                >
                  <ng-container *ngTemplateOutlet="content"></ng-container>
                </div>
              </div>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </ng-container>

      <ng-template #hideMobileNav>
        <mobile-tracking-header
          [pageTitle]="state.pageTitle"
          [authenticated]="state.authenticated"
          [showLogout]="!state.showSideNav"
        ></mobile-tracking-header>

        <ng-container *ngIf="state.loading; else mobileLoaded">
          <loading-results [text]="'Loading Configuration...'"></loading-results>
        </ng-container>

        <ng-template #mobileLoaded>
          <div
            class="mobile-content-container"
            [class.drawer-content-container-margin]="state.showCondensedSideNav && state.showSideNav"
          >
            <ng-container *ngTemplateOutlet="content"></ng-container>
          </div>
        </ng-template>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-container>
