import {AuthAction, AuthActionType} from './auth.action';
import {AuthState, initialAuthState} from './auth.state';

export function authReducer(state = initialAuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionType.LOGIN_SUCCESS:
      return {...state, token: action.payload.token};
    case AuthActionType.LOGOUT:
      return {...state, token: null};
    default:
      return state;
  }
}
