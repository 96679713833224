import {ErrorHandler, Inject, Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {Environment, ENVIRONMENT} from '../environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

  public handleError(error: any): void {
    if (/Loading chunk [\d]+ failed/.test(error?.message)) {
      window.location.reload();
      return;
    }

    if (this.environment.sentryDsn) {
      Sentry.captureException(error.originalError || error);
    }

    throw error;
  }
}
