import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {catchError, filter, map, mergeMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {clearDefaultWorkspace} from '../../../shared/utils/workspace/clear-default-workspace';
import {setDefaultApplicationCompany} from '../../../shared/utils/workspace/set-default-application-company';
import {ConfigurationApiService} from '../../api/configuration-api.service';
import {convertApplicationDtoToModel} from '../../api/converters/convert-application-dto-to-model';
import {convertConfigurationDtoToModel} from '../../api/converters/convert-configuration-dto-to-model';
import {convertUserInfoDtoToModel} from '../../api/converters/convert-user-info-dto-to-model';
import {convertUserInfoModelToDto} from '../../api/converters/convert-user-info-model-to-dto';
import {ApplicationId} from '../../models/application';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  ClearWorkspaceAction,
  GetAppSettingsAction,
  GetAppSettingsSuccessAction,
  GetConfigurationAction,
  GetConfigurationSuccessAction,
  GetUserInfoAction,
  GetUserInfoSuccessAction,
  SelectWorkspaceAction,
  UpdateUserInfoAction,
  WorkspaceActionType,
} from './workspace.action';
import {selectAppSettings, selectConfiguration} from './workspace.selector';

@Injectable()
export class WorkspaceEffects {
  public getAppSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAppSettingsAction>(WorkspaceActionType.GET_APP_SETTINGS),
      mergeMap(action => {
        const {applicationId, force, onSuccess, onFailure} = action.payload;

        return this.store$.pipe(
          select(selectAppSettings(applicationId)),
          take(1),
          filter(appSettings => !appSettings || force),
          mergeMap(() => this.configurationApiService.getAppSettings(applicationId)),
          map(dto => convertApplicationDtoToModel(dto)),
          mergeMap(appSettings => [
            new GetAppSettingsSuccessAction({applicationId, appSettings}),
            ...createCallbackActions(onSuccess, appSettings),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetConfigurationAction>(WorkspaceActionType.GET_CONFIGURATION),
      withLatestFrom(this.store$.pipe(select(selectConfiguration))),
      filter(([action, configuration]) => !configuration || action.payload.force),
      mergeMap(([action]) =>
        this.configurationApiService.getConfiguration().pipe(
          map(dto => convertConfigurationDtoToModel(dto)),
          mergeMap(configuration => [
            new GetConfigurationSuccessAction({configuration}),
            ...createCallbackActions(action.payload.onSuccess, configuration),
          ]),
          catchError(error => emitErrorActions(error, action.payload.onFailure))
        )
      )
    )
  );

  public select$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SelectWorkspaceAction>(WorkspaceActionType.SELECT),
        tap(action => {
          const {applicationId, companyId} = action.payload;
          setDefaultApplicationCompany(applicationId, companyId);
          this.navigateToWorkspace(applicationId);
        })
      ),
    {dispatch: false}
  );

  public getUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetUserInfoAction>(WorkspaceActionType.GET_USER_INFO),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.configurationApiService.getUserInfo().pipe(
          map(dto => convertUserInfoDtoToModel(dto)),
          mergeMap(userInfo => [new GetUserInfoSuccessAction({userInfo}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateUserInfoAction>(WorkspaceActionType.UPDATE_USER_INFO),
      mergeMap(action => {
        const {userInfo, onSuccess, onFailure} = action.payload;
        const userDto = convertUserInfoModelToDto(userInfo);

        return this.configurationApiService.updateUserInfo(userDto).pipe(
          mergeMap(() => [new GetUserInfoAction({}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public clear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ClearWorkspaceAction>(WorkspaceActionType.CLEAR),
        tap(() => clearDefaultWorkspace())
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private configurationApiService: ConfigurationApiService,
    private router: Router,
    private store$: Store<{}>
  ) {}

  private navigateToWorkspace(applicationId: string) {
    switch (applicationId) {
      case ApplicationId.BatchTracking:
        return this.router.navigate(['/batch-tracking']);
      case ApplicationId.GatewayBatchManager:
        return this.router.navigate(['/gateway']); // TODO maybe change path?
      case ApplicationId.Documents:
        return this.router.navigate(['/file-search']); // TODO maybe change path?
      case ApplicationId.BatchInspectR:
        return this.router.navigate(['/batch-inspectr']); // TODO maybe change path?
      case ApplicationId.PieceInspectR:
        return this.router.navigate(['/piece-inspectr']); // TODO maybe change path?
      case ApplicationId.ClearanceInspectR:
        return this.router.navigate(['/clearance-inspectr']); // TODO maybe change path
      case ApplicationId.Games:
        return this.router.navigate(['/piece-inspectr-game']); // TODO maybe change path
    }
  }
}
