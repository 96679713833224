import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {AuthToken} from '../../models/auth-token';

export enum AuthActionType {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login :: Success',

  LOGOUT = '[Auth] Logout',
}

export class LoginAction implements Action {
  public readonly type = AuthActionType.LOGIN;

  constructor(
    public payload: {
      username: string;
      password: string;
      url: string;
      onSuccess?: (token: AuthToken) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class LoginSuccessAction implements Action {
  public readonly type = AuthActionType.LOGIN_SUCCESS;

  constructor(public payload: {token: AuthToken}) {}
}

export class LogoutAction implements Action {
  public readonly type = AuthActionType.LOGOUT;
}

export type AuthAction = LoginAction | LoginSuccessAction | LogoutAction;
