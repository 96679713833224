import {ClearanceInspectorCloseoutReturn} from './../../models/clearance-inspector-closeout-return';
import {HttpErrorResponse} from '@angular/common/http';
import {ClearanceInspectorCheckedMap} from './../../models/clearance-inspector-checked-map';
import {ClearanceInspectorData} from './../../models/clearance-inspector-data';
/* eslint-disable */
import {Action} from '@ngrx/store';

export enum ClearanceInspectorActionType {
  GET_PIECES = '[Clearance Inspector] Get Pieces',
  GET_PIECES_SUCCESS = '[Clearance Inspector] Get Pieces :: Success',

  UPDATE_LOADING = '[Clearance Inspector] Update Loading',

  UPDATE_CHECKED_MAP = '[Clearance Inspector] Update Checked Map',

  DOWNLOAD = '[Clearance Inspector] Download',

  CLOSEOUT = '[Clearance Inspector] Closeout',
}

export class GetClearanceInspectorPiecesAction implements Action {
  public readonly type = ClearanceInspectorActionType.GET_PIECES;

  constructor(
    public payload: {pieceIds: string[]; onSuccess?: () => void; onFailure?: (error: HttpErrorResponse) => void}
  ) {}
}

export class GetClearanceInspectorPiecesSuccessAction implements Action {
  public readonly type = ClearanceInspectorActionType.GET_PIECES_SUCCESS;

  constructor(public payload: {pieceIds: string[]; clearanceData: ClearanceInspectorData}) {}
}

export class UpdateClearanceInspectorLoadingAction implements Action {
  public readonly type = ClearanceInspectorActionType.UPDATE_LOADING;

  constructor(public payload: {loadingAfterCloseout: boolean}) {}
}

export class UpdateClearanceInspectorCheckedMapAction implements Action {
  public readonly type = ClearanceInspectorActionType.UPDATE_CHECKED_MAP;

  constructor(public payload: {checkedMap: ClearanceInspectorCheckedMap}) {}
}

export class DownloadClearanceInspectorSpreadsheetAction implements Action {
  public readonly type = ClearanceInspectorActionType.DOWNLOAD;

  constructor(
    public payload: {pieceIds: string[]; onSuccess?: () => void; onFailure?: (error: HttpErrorResponse) => void}
  ) {}
}

export class ClearanceInspectorCloseoutAction implements Action {
  public readonly type = ClearanceInspectorActionType.CLOSEOUT;

  constructor(
    public payload: {
      pieceIds: string[];
      onSuccess: (data: ClearanceInspectorCloseoutReturn) => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export type ClearanceInspectorAction =
  | GetClearanceInspectorPiecesAction
  | GetClearanceInspectorPiecesSuccessAction
  | UpdateClearanceInspectorLoadingAction
  | UpdateClearanceInspectorCheckedMapAction
  | DownloadClearanceInspectorSpreadsheetAction
  | ClearanceInspectorCloseoutAction;
