import {BatchFile} from '../../models/batch-file';
import {BatchFileDto} from '../dto/batch-file.dto';

export function convertBatchFileDtoToModel(dto: BatchFileDto): BatchFile {
  return {
    activity: dto.Activity,
    activityId: dto.ActivityID,
    batchNumbers: dto.BatchNumbers,
    creationDate: dto.CreationDate,
    creationDateText: dto.CreationDateText,
    depotCode: dto.DepotCode,
    description: dto.Description,
    documentId: dto.DocumentID,
    generatedBy: dto.GeneratedBy,
    iconType: dto.IconType,
    itemCount: dto.ItemCount,
    manifestNumber: dto.ManifestNumber,
    masterBOL: dto.MasterBOL,
    olsBatchNumber: dto.OLSBatchNumber,
    olsCloseoutURL: dto.OLSCloseoutURL,
    olsSite: dto.OLSSite,
    orderCount: dto.OrderCount,
    origin: dto.Origin,
    originLabel: dto.OriginLabel,
    packageCount: dto.PackageCount,
    parsNumber: dto.PARSNumber,
    title: dto.Title,
    type: dto.Type,
    typeId: dto.TypeID,
    url: dto.URL,
  };
}
