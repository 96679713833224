import {Action} from '@ngrx/store';
import {PIFacility} from 'src/app/core/models';

export enum EFacilityActions {
  GetFacilities = '[Facility] Get Facilities',
  GetFacilitiesSuccess = '[Facility] Get Facilities Success',
  SetActiveFacility = '[Facility] Get Active Facility',
  SetActiveFacilitySuccess = '[Facility] Get Active Facility Success',
}

export class GetFacilities implements Action {
  public readonly type = EFacilityActions.GetFacilities;
}

export class GetFacilitiesSuccess implements Action {
  public readonly type = EFacilityActions.GetFacilitiesSuccess;
  constructor(public payload: PIFacility[]) {}
}

export class SetActiveFacility implements Action {
  public readonly type = EFacilityActions.SetActiveFacility;
  constructor(public payload: number) {}
}

export class SetActiveFacilitySuccess implements Action {
  public readonly type = EFacilityActions.SetActiveFacilitySuccess;
  constructor(public payload: PIFacility) {}
}

export type FacilityActions = GetFacilities | GetFacilitiesSuccess | SetActiveFacility | SetActiveFacilitySuccess;
