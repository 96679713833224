export const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'Detail',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: 'Details',
};
