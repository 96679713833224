import {hashObject} from 'src/app/shared/utils/hash-object';
import {BatchInspectorAction, BatchInspectorActionType} from './batch-inspector.actions';
import {BatchInspectorState, initialBatchInspectorState} from './batch-inspector.state';
export function batchInspectorReducer(
  state = initialBatchInspectorState,
  action: BatchInspectorAction
): BatchInspectorState {
  switch (action.type) {
    case BatchInspectorActionType.GET_BATCHES_SUCCESS: {
      const batches = {
        ...state.batches,
        [hashObject(action.payload.params)]: action.payload.batches,
      };
      return {...state, batches, batchesCount: action.payload.batchesCount};
    }
    case BatchInspectorActionType.GET_COMPARISON_BATCHES_SUCCESS: {
      const batchComparison = {
        ...state.batchComparison,
        [hashObject(action.payload.batchNumbers)]: action.payload.batchComparison,
      };
      return {...state, batchComparison};
    }
    case BatchInspectorActionType.GET_COMPARISON_BATCHES_DETAIL_SUCCESS: {
      const pieces = {
        ...state.pieces,
        [hashObject(action.payload.params)]: action.payload.pieces,
      };
      return {...state, pieces, piecesCount: action.payload.piecesCount, piecesParams: action.payload.params};
    }
    case BatchInspectorActionType.UPDATE_COMPARISON_BATCHES_DETAIL_PARAMS:
      return {...state, piecesParams: action.payload.params};
    case BatchInspectorActionType.CLEAR_COMPARISON_BATCHES:
      return {...state, pieces: [], piecesCount: 0};
    default:
      return state;
  }
}
