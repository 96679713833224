import {CommonModule} from '@angular/common';
import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import {ENVIRONMENT, Environment} from '../environment';
import {SentryErrorHandler} from './sentry.error-handler';

@NgModule({
  imports: [CommonModule],
})
export class DraSentryModule {
  public static withEnvironment(environment: Environment): ModuleWithProviders<DraSentryModule> {
    return {
      ngModule: DraSentryModule,
      providers: [
        {provide: ENVIRONMENT, useValue: environment},
        {
          provide: ErrorHandler,
          useClass: SentryErrorHandler,
        },
      ],
    };
  }
}
