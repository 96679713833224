import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingResultsComponent} from './loading-results.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';

@NgModule({
  declarations: [LoadingResultsComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [LoadingResultsComponent],
})
export class LoadingResultsModule {}
