import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Environment, ENVIRONMENT} from '../../environment';
import {TrackingDataDto} from './dto/tracking-data.dto';

@Injectable()
export class TrackingApiService {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: Environment) {}

  public getTrackingData(searchValue: string): Observable<TrackingDataDto> {
    return this.http.post<TrackingDataDto>(this.trackingUrl, {TrackingNumber: searchValue});
  }

  private get trackingUrl(): string {
    return `${this.environment.apiUrl}/tracking`;
  }
}
