/* eslint-disable */

import {createSelector} from '@ngrx/store';
import {hashObject} from 'src/app/shared/utils/hash-object';
import {decodeQueryParam} from 'src/app/shared/utils/query/query-param-encoding';
import {ApplicationId} from '../../models/application';
import {GatewaySummaryParams} from '../../models/gateway-summary-params';
import {AppState} from '../app.state';
import {selectRouterQueryParam, selectRouterParam} from '../router/router.selector';
import {selectCurrentCompany} from '../workspace/workspace.selector';
import {GatewayState} from './gateway.state';
import {MemoizedSelector} from '@ngrx/store/src/selector';
import {PieceDetailParams} from '../../models/piece-detail-params';
import {DEFAULT_GATEWAY_TAB} from 'src/app/shared/constants';
import {FileSearchParams} from '../../models/file-search-params';

export const selectGatewayState = (state: AppState) => state.gateway;

export const selectGatewaySummaryParams: MemoizedSelector<AppState, GatewaySummaryParams> = createSelector(
  selectRouterQueryParam('activeStatus'),
  selectRouterQueryParam('query'),
  selectRouterQueryParam('search'),
  selectRouterQueryParam('page'),
  selectRouterQueryParam('pageSize'),
  selectRouterQueryParam('sort'),
  selectRouterQueryParam('sortDir'),
  (activeStatus, query, search, page, pageSize, sort, sortDir) => ({
    ...(query ? JSON.parse(decodeQueryParam(query) || '{}') : {}),
    search,
    page: page ? Number(page) : 1,
    pageSize: pageSize ? Number(pageSize) : 25,
    sortColumns: sort ? String(sort).split(',') : [],
    sortDirections: sortDir ? String(sortDir).split(',') : [],
    statusType: activeStatus || DEFAULT_GATEWAY_TAB,
  })
);

export const selectGatewayFacilities = createSelector(
  selectCurrentCompany(ApplicationId.GatewayBatchManager),
  company => company?.facilities || []
);

export const selectGatewayAffiliates = createSelector(
  selectCurrentCompany(ApplicationId.GatewayBatchManager),
  company => company?.origins || []
);

const selectGatewayQueryBatchesMap = createSelector(selectGatewayState, (state: GatewayState) => state.queryBatchesMap);

export const selectAllGatewayBatches = createSelector(
  selectGatewayQueryBatchesMap,
  selectGatewaySummaryParams,
  (summariesMap, params) => summariesMap[hashObject(params)] || []
);

export const selectAllGatewayBatchesLoading = createSelector(
  selectGatewayQueryBatchesMap,
  selectGatewaySummaryParams,
  (summariesMap, params) => !summariesMap[hashObject(params)]
);

export const selectGatewayBatches = (batchNumber: string) =>
  createSelector(selectGatewayState, state => state.detailBatches);

export const selectGatewayBatchesCount = createSelector(selectGatewayState, state => state.batchesCount);

export const selectGatewayPieceDetailParams: MemoizedSelector<AppState, PieceDetailParams> = createSelector(
  selectRouterParam('batchNumber'),
  selectRouterQueryParam('page'),
  selectRouterQueryParam('pageSize'),
  selectRouterQueryParam('sort'),
  selectRouterQueryParam('sortDir'),
  (batchNumber, page, pageSize, sort, sortDir) => {
    return {
      batchNumber: batchNumber,
      clearable: null,
      facilityCode: null,
      origin: null,
      pieceType: null,
      statusType: null,
      page: page ? Number(page) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
      sortColumns: sort ? String(sort).split(',') : [],
      sortDirections: sortDir ? String(sortDir).split(',') : [],
    };
  }
);

export const selectGatewayPiecesCount = createSelector(selectGatewayState, state => state.piecesCount);

export const selectGatewayPiecesMap = createSelector(selectGatewayState, (state: GatewayState) => state.piecesMap);
export const selectGatewayPieceDetails = createSelector(
  selectGatewayPiecesMap,
  selectGatewayPieceDetailParams,
  (piecesMap, params) => piecesMap[hashObject(params)]
);

export const selectFileSearchParams: MemoizedSelector<AppState, FileSearchParams> = createSelector(
  selectRouterQueryParam('query'),
  selectRouterQueryParam('page'),
  selectRouterQueryParam('pageSize'),
  (query, page, pageSize) => ({
    ...(query ? JSON.parse(decodeQueryParam(query) || '{}') : {}),
    page: page ? Number(page) : 1,
    pageSize: pageSize ? Number(pageSize) : 25,
  })
);

export const selectFileSearchFilterParams = createSelector(
  selectRouterQueryParam('filter'),
  selectRouterQueryParam('page'),
  selectRouterQueryParam('pageSize'),
  selectRouterQueryParam('sort'),
  selectRouterQueryParam('sortDir'),
  (filter, page, pageSize, sort, sortDir) => ({
    filter,
    page: page ? Number(page) : 0,
    pageSize: pageSize ? Number(pageSize) : 25,
    sortColumns: sort ? String(sort).split(',') : [],
    sortDirections: sortDir ? String(sortDir).split(',') : [],
  })
);

export const selectBatchFileData = createSelector(selectGatewayState, (state: GatewayState) => state.fileData);
