import {
  ClientDashboardErrorResponse,
  ClientDashboardErrorResponseMessage,
  ClientDashboardErrorResponsePiece,
} from '../../models/client-dashboard-error-response';
import {
  ClientDashboardErrorResponseDto,
  ClientDashboardErrorResponseMessageDto,
  ClientDashboardErrorResponsePieceDto,
} from '../dto/closeout-by-pieces-error-response.dto';

export function convertClientDashboardErrorResponseDtoToModel(
  dto: ClientDashboardErrorResponseDto
): ClientDashboardErrorResponse {
  return {
    message: dto.Message,
    messages: dto.Messages?.map(message => convertCloseoutByPiecesErrorResponseMessageDtoToModel(message)) || [],
    invalidPieces: dto.InvalidPieces?.map(piece => convertNewContainerErrorResponsePieceDtoToModel(piece)),
    validPieces: dto.ValidPieces?.map(piece => convertNewContainerErrorResponsePieceDtoToModel(piece)),
    showCloseoutVerifiedButton: dto.ShowCloseoutVerifiedButton,
    showDetailedMesssages: dto.ShowDetailedMessages,
  };
}

function convertCloseoutByPiecesErrorResponseMessageDtoToModel(
  dto: ClientDashboardErrorResponseMessageDto
): ClientDashboardErrorResponseMessage {
  return {
    message: dto.Message,
    pieces: dto.Pieces?.map(piece => convertNewContainerErrorResponsePieceDtoToModel(piece)) || [],
  };
}

function convertNewContainerErrorResponsePieceDtoToModel(
  dto: ClientDashboardErrorResponsePieceDto
): ClientDashboardErrorResponsePiece {
  return {
    pieceId: dto.PieceID,
    pin: dto.PIN,
  };
}
