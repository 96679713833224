import {BatchComparisonIntersection} from './../../models/batch-comparison';
import {BatchComparisonIntersectionDto} from '../dto/batch-comparison.dto';

export function convertBatchComparisonIntersectionDtoToModel(
  dto: BatchComparisonIntersectionDto
): BatchComparisonIntersection {
  return {
    label: dto.Label,
    packageCount: dto.PackageCount,
    batchNumbers: dto.BatchNumbers,
  };
}
