import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {BatchApiService} from '../../api/batch-api.service';
import {convertBatchFilesDtoToModel} from '../../api/converters/convert-batch-files-dto-to-model';
import {convertEmailFormModelToDto} from '../../api/converters/convert-email-form-model-to-dto';
import {convertFileSearchParamsModelToDto} from '../../api/converters/convert-file-search-params-model-to-dto';
import {convertGatewayBatchDtoToModel} from '../../api/converters/convert-gateway-batch-dto-to-model';
import {convertGatewaySummaryParamsModelToDto} from '../../api/converters/convert-gateway-summary-params-model-to-dto';
import {convertPieceDetailDtoToModel} from '../../api/converters/convert-piece-detail-dto-to-model';
import {convertPieceDetailParamsModelToDto} from '../../api/converters/convert-piece-detail-params-model-to-dto';
import {convertPieceRemoveModelToDto} from '../../api/converters/convert-piece-remove-model-to-dto';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  AddPiecesToGatewayBatchAction,
  CreateBatchAction,
  CreateBatchSuccessAction,
  DownloadFileAction,
  DownloadMultipleFilesAction,
  GatewayActionType,
  GetAllGatewayBatchesAction,
  GetAllGatewayBatchesSuccessAction,
  GetFilesAction,
  GetFilesSuccessAction,
  GetGatewayBatchesAction,
  GetGatewayBatchesSuccessAction,
  GetGatewayPieceDetailsAction,
  GetGatewayPieceDetailsSuccessAction,
  PrintMultiplePDFsAction,
  RemovePiecesFromGatewayBatchAction,
  RemovePiecesFromMultipleGatewayBatchesAction,
  ShareFilesAction,
  ShareFilesSuccessAction,
  UpdateBatchStatusAction,
} from './gateway.actions';
import {selectGatewaySummaryParams} from './gateway.selectors';

@Injectable()
export class GatewayEffects {
  public getAllBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllGatewayBatchesAction>(GatewayActionType.GET_ALL_BATCHES),
      mergeMap(action => {
        const {params} = action.payload;
        const paramsDto = convertGatewaySummaryParamsModelToDto(params);

        return this.batchApiService.getAllBatches(paramsDto).pipe(
          map(dto => ({
            batches: dto?.BatchList.map(batch => convertGatewayBatchDtoToModel(batch)),
            batchesCount: dto?.RecordCount,
          })),
          map(({batches, batchesCount}) => new GetAllGatewayBatchesSuccessAction({params, batches, batchesCount})),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetGatewayBatchesAction>(GatewayActionType.GET_BATCHES),
      mergeMap(action => {
        const {batchNumber} = action.payload;

        return this.batchApiService.getBatches(batchNumber.replace(/,/g, ';')).pipe(
          map(dto => dto?.BatchList.map(batch => convertGatewayBatchDtoToModel(batch))),
          map(batches => new GetGatewayBatchesSuccessAction({batches})),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getPieceDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetGatewayPieceDetailsAction>(GatewayActionType.GET_GATEWAY_PIECE_DETAILS),
      mergeMap(action => {
        const {companyId, params} = action.payload;
        const paramsDto = convertPieceDetailParamsModelToDto(params);

        return this.batchApiService.getGatewayPieceDetails(companyId, paramsDto).pipe(
          map(page => {
            const pieces = page.PieceList.map(dto => convertPieceDetailDtoToModel(dto));
            const piecesCount = page.RecordCount;
            return new GetGatewayPieceDetailsSuccessAction({companyId, params, pieces, piecesCount});
          }),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public updateBatchStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateBatchStatusAction>(GatewayActionType.UPDATE_BATCH_STATUS),
      mergeMap(action => {
        const {batchNumbers, transitionType, onSuccess, onFailure} = action.payload;

        return this.batchApiService.updateBatchStatus(batchNumbers, transitionType).pipe(
          withLatestFrom(this.store$.pipe(select(selectGatewaySummaryParams))),
          mergeMap(result => [
            new GetAllGatewayBatchesAction({
              params: result[1],
            }),
            ...createCallbackActions(onSuccess, result[0]),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateBatchAction>(GatewayActionType.CREATE_BATCH),
      mergeMap(action => {
        const {companyId, shipperId, piecesIds, onSuccess, onFailure} = action.payload;

        return this.batchApiService.createBatch(companyId, shipperId, piecesIds).pipe(
          mergeMap(result => [
            new CreateBatchSuccessAction({id: result[0]}),
            ...createCallbackActions(onSuccess, result[0]),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public addPiecesToBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddPiecesToGatewayBatchAction>(GatewayActionType.ADD_PIECES_TO_BATCH),
      mergeMap(action => {
        const {batchNumber, pieceIds, onSuccess, onFailure} = action.payload;

        return this.batchApiService.addPiecesToBatch(batchNumber, pieceIds).pipe(
          mergeMap(() => [new GetGatewayBatchesAction({batchNumber}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public removePiecesFromBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemovePiecesFromGatewayBatchAction>(GatewayActionType.REMOVE_PIECES_FROM_BATCH),
      mergeMap(action => {
        const {batchNumber, pieceIds, onSuccess, onFailure} = action.payload;

        return this.batchApiService.removePiecesFromBatch(batchNumber, pieceIds).pipe(
          mergeMap(() => [new GetGatewayBatchesAction({batchNumber}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public removePiecesFromMultipleBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemovePiecesFromMultipleGatewayBatchesAction>(GatewayActionType.REMOVE_PIECES_FROM_MULTIPLE_BATCHES),
      mergeMap(action => {
        const {batchNumber, pieces, onSuccess, onFailure} = action.payload;
        const piecesDto = pieces.map(piece => convertPieceRemoveModelToDto(piece));

        return this.batchApiService.removePiecesFromMultipleBatches(piecesDto).pipe(
          mergeMap(() => [new GetGatewayBatchesAction({batchNumber}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFilesAction>(GatewayActionType.GET_FILES),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertFileSearchParamsModelToDto(params);

        return this.batchApiService.getFiles(paramsDto).pipe(
          map(dto => convertBatchFilesDtoToModel(dto)),
          mergeMap(fileData => [new GetFilesSuccessAction({params, fileData}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public shareFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShareFilesAction>(GatewayActionType.SHARE_FILES),
      mergeMap(action => {
        const {emailForm, onSuccess, onFailure} = action.payload;
        const emailFormDto = convertEmailFormModelToDto(emailForm);

        return this.batchApiService.shareFiles(emailFormDto).pipe(
          mergeMap(data => [new ShareFilesSuccessAction({emailForm: data}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public downloadFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadFileAction>(GatewayActionType.DOWNLOAD_FILE),
        mergeMap(action => {
          const {url, title} = action.payload;

          return this.batchApiService.downloadFile(url).pipe(
            tap(file => {
              const blob = new Blob([file], {type: file.type});
              saveAs(blob, title.replace(' ', '_'));
            }),
            catchError(error => emitErrorActions(error))
          );
        })
      ),
    {dispatch: false}
  );

  public downloadMultipleFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadMultipleFilesAction>(GatewayActionType.DOWNLOAD_MULTIPLE_FILES),
      mergeMap(action => {
        const {documentIds, type, onSuccess, onFailure} = action.payload;

        return this.batchApiService.downloadMultipleFiles(documentIds).pipe(
          tap(file => {
            file.body.text().then(text => {
              const extension = file.body.type === 'application/x-zip-compressed' ? 'zip' : type;
              const blob = new Blob([file.body], {type: file.body.type});
              if (file.body.type === 'application/json') {
                const data = JSON.parse(text);
                if (typeof data === 'object' && data.message && data.message.toLowerCase().includes('error')) {
                  return;
                } else {
                  saveAs(blob, `ConsolidatedPurolatorDocuments_${moment().format()}.${extension}`);
                }
              } else {
                saveAs(blob, `ConsolidatedPurolatorDocuments_${moment().format('MM-DD-YY_HH.mm.ss')}.${extension}`);
              }
            });
          }),
          mergeMap(data => [...createCallbackActions(onSuccess, data.body)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public printFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadFileAction>(GatewayActionType.PRINT_FILE),
        mergeMap(action => {
          const {url} = action.payload;

          return this.batchApiService.downloadFile(url).pipe(
            tap(file => {
              const blob = new Blob([file], {type: file.type});
              const blobUrl = URL.createObjectURL(blob);
              const iframe = document.createElement('iframe');
              iframe.style.display = 'none';
              iframe.src = blobUrl;
              document.body.appendChild(iframe);
              setTimeout(function () {
                iframe.contentWindow.print();
              }, 500);
            }),
            catchError(error => emitErrorActions(error))
          );
        })
      ),
    {dispatch: false}
  );

  public printMultiplePDFs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PrintMultiplePDFsAction>(GatewayActionType.PRINT_MULTIPLE_PDFS),
      mergeMap(action => {
        const {documentIds, onSuccess, onFailure} = action.payload;

        return this.batchApiService.downloadMultipleFiles(documentIds).pipe(
          tap(file => {
            const blob = new Blob([file.body], {type: 'application/pdf'});
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            setTimeout(function () {
              iframe.contentWindow.print();
            }, 500);
          }),
          mergeMap(data => [...createCallbackActions(onSuccess, data.body.type === 'application/x-zip-compressed')]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(private actions$: Actions, private batchApiService: BatchApiService, private store$: Store<{}>) {}
}
