import {HttpErrorResponse} from '@angular/common/http';
import {Application} from '../../models/application';
import {Configuration} from '../../models/configuration';
import {UserInfo} from '../../models/user-info';

export interface WorkspaceState {
  appSettingsMap: Record<string, Application>;
  configuration: Configuration;
  configError: HttpErrorResponse;
  sideNavOpened: boolean;
  rightDrawerOpened: boolean;
  userInfo: UserInfo;
  paddingColor: string;
}

export const initialWorkspaceState: WorkspaceState = {
  appSettingsMap: {},
  configuration: null,
  configError: null,
  sideNavOpened: false,
  rightDrawerOpened: false,
  userInfo: null,
  paddingColor: '',
};
