import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TimeZoneAbbreviationPipe} from './time-zone-abbreviation.pipe';
import {TimeZoneOffsetPipe} from './time-zone-offset.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TimeZoneOffsetPipe, TimeZoneAbbreviationPipe],
  exports: [TimeZoneAbbreviationPipe, TimeZoneOffsetPipe],
})
export class DraPipesModule {}
