import {PieceDetailParams} from '../../models/piece-detail-params';
import {PieceDetailParamsExcelDto} from '../dto/piece-detail-params-excel.dto';

export function convertPieceDetailParamsModelToSpreadsheetDto(params: PieceDetailParams): PieceDetailParamsExcelDto {
  return {
    facilityCode: params.facilityCode || 'null',
    isClearable: params.clearable !== null ? Number(params.clearable) : null,
    origin: params.origin,
    pieceType: params.pieceType,
    statusType: params.statusType,
    companyId: params.companyId,
  };
}
