import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {saveAs} from 'file-saver';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {BatchApiService} from '../../api/batch-api.service';
import {convertBatchComparisonDetailParamsModelToDto} from '../../api/converters/convert-batch-comparison-detail-params-model-to-dto';
import {convertBatchComparisonDtoToModel} from '../../api/converters/convert-batch-comparison-dto-to-model';
import {convertBatchInspectorCreateBatchParamsModelToDto} from '../../api/converters/convert-batch-inspector-create-batch-params-model-to-dto';
import {convertBatchInspectorSummaryParamsModelToDto} from '../../api/converters/convert-batch-inspector-params-model-to-dto';
import {convertGatewayBatchDtoToModel} from '../../api/converters/convert-gateway-batch-dto-to-model';
import {convertPieceDetailDtoToModel} from '../../api/converters/convert-piece-detail-dto-to-model';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  BatchInspectorActionType,
  CreateBatchInspectorBatchAction,
  CreateComparisonBatchAction,
  CreateComparisonDetailBatchAction,
  DownloadComparisonBatchesDetailAction,
  GetBatchInspectorBatchesAction,
  GetBatchInspectorBatchesSuccessAction,
  GetComparisonBatchesAction,
  GetComparisonBatchesDetailAction,
  GetComparisonBatchesDetailSuccessAction,
  GetComparisonBatchesSuccessAction,
} from './batch-inspector.actions';

@Injectable()
export class BatchInspectorEffects {
  public getBatchInspectorBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetBatchInspectorBatchesAction>(BatchInspectorActionType.GET_BATCHES),
      mergeMap(action => {
        const {params} = action.payload;
        const paramsDto = convertBatchInspectorSummaryParamsModelToDto(params);

        return this.batchApiService.getAllBatchInspectorBatches(paramsDto).pipe(
          map(dto => ({
            batches: dto?.BatchList.map(batch => convertGatewayBatchDtoToModel(batch)),
            batchesCount: dto?.RecordCount,
          })),
          map(({batches, batchesCount}) => new GetBatchInspectorBatchesSuccessAction({params, batches, batchesCount})),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getComparisonBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetComparisonBatchesAction>(BatchInspectorActionType.GET_COMPARISON_BATCHES),
      mergeMap(action => {
        const {batchNumbers} = action.payload;

        return this.batchApiService.getComparisonBatches(batchNumbers).pipe(
          map(dto => convertBatchComparisonDtoToModel(dto)),
          map(batchComparison => new GetComparisonBatchesSuccessAction({batchNumbers, batchComparison})),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getComparisonBatchesDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetComparisonBatchesDetailAction>(BatchInspectorActionType.GET_COMPARISON_BATCHES_DETAIL),
      mergeMap(action => {
        const {params} = action.payload;
        const paramsDto = convertBatchComparisonDetailParamsModelToDto(params);

        return this.batchApiService.getComparisonBatchesDetail(paramsDto).pipe(
          map(page => {
            const pieces = page?.PieceList?.map(dto => convertPieceDetailDtoToModel(dto)) || [];
            const piecesCount = page.RecordCount;
            return new GetComparisonBatchesDetailSuccessAction({params, pieces, piecesCount});
          }),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public createBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateBatchInspectorBatchAction>(BatchInspectorActionType.CREATE_BATCH),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertBatchInspectorCreateBatchParamsModelToDto(params);

        return this.batchApiService.createBatchInspectorBatch(paramsDto).pipe(
          mergeMap(response => [...createCallbackActions(onSuccess, response)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createComparisonBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateComparisonBatchAction>(BatchInspectorActionType.CREATE_COMPARISON_BATCH),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertBatchInspectorCreateBatchParamsModelToDto(params);

        return this.batchApiService.createComparisonBatch(paramsDto).pipe(
          mergeMap(response => [...createCallbackActions(onSuccess, response)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createComparisonDetailBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateComparisonDetailBatchAction>(BatchInspectorActionType.CREATE_COMPARISON_DETAIL_BATCH),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertBatchInspectorCreateBatchParamsModelToDto(params);

        return this.batchApiService.createComparisonDetailBatch(paramsDto).pipe(
          mergeMap(response => [...createCallbackActions(onSuccess, response)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public downloadComparisonBatchesDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DownloadComparisonBatchesDetailAction>(BatchInspectorActionType.DOWNLOAD_COMPARISON_BATCHES_DETAIL),
        mergeMap(action => {
          const {params} = action.payload;
          const paramsDto = convertBatchComparisonDetailParamsModelToDto(params);

          return this.batchApiService.downloadComparisonBatchesDetail(paramsDto).pipe(
            tap(file => {
              const today = new Date();
              const blob = new Blob([file], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              saveAs(blob, `BatchInspectR_${today.toISOString()}.xlsx`);
            }),
            catchError(error => emitErrorActions(error))
          );
        })
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions, private batchApiService: BatchApiService) {}
}
