import {PieceInspectorEffects} from './piece-inspector/piece-inspector.effect';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../../environments/environment';
import {appReducer} from './app.reducer';
import {AppState, initialAppState} from './app.state';
import {AuthEffects} from './auth/auth.effects';
import {BatchTrackingEffects} from './batch-tracking/batch-tracking.effects';
import {CommonEffects} from './common/common.effects';
import {commonMetaReducer} from './common/common.meta-reducer';
import {FacilityEffects} from './facility/facility.effects';
import {CustomRouterStateSerializer} from './router/router.state-serializer';
import {WorkspaceEffects} from './workspace/workspace.effects';
import {GatewayEffects} from './gateway/gateway.effects';
import {BatchInspectorEffects} from './batch-inspector/batch-inspector.effects';
import {ClearanceInspectorEffects} from './clearance-inspector/clearance-inspector.effects';
import {ClientDashboardEffects} from './client-dashboard/client-dashboard.effects';
import {AuthTrackingEffects} from './auth-tracking/auth-tracking.effects';

const metaReducers: MetaReducer<AppState>[] = [commonMetaReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(appReducer, {
      initialState: initialAppState,
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: false, // TODO change to true once this is fixed: https://github.com/ngrx/platform/issues/2690
        strictStateImmutability: false, // TODO change to true once this is fixed: https://github.com/ngrx/platform/issues/2690
      },
    }),
    EffectsModule.forRoot([
      AuthEffects,
      BatchTrackingEffects,
      CommonEffects,
      FacilityEffects,
      WorkspaceEffects,
      GatewayEffects,
      BatchInspectorEffects,
      PieceInspectorEffects,
      ClearanceInspectorEffects,
      ClientDashboardEffects,
      AuthTrackingEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50, name: `DRA NgRx Store`}) : [],
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
})
export class DraStoreModule {}
