/* eslint-disable */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Carrier} from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class CarrierService {
  carriersUrl = `${environment.apiUrl}/carriers`;

  constructor(private http: HttpClient) {}

  /**
   * Handle any Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      /* TODO: send errors to remote logging infrastructure */
      console.error(error);
      console.log(`Something went wrong while trying to ${operation}. Please try again.`);
      /* Let the app keep running by returning an empty result. */
      return of(result as T);
    };
  }

  /**
   * GET array of Carriers
   */
  public getCarriers(): Observable<Array<Carrier>> {
    const url = this.carriersUrl;
    return this.http.get<Array<Carrier>>(url).pipe(
      // tap(facilities => this.log(`Successfully fetched Carriers`, true)),
      catchError(this.handleError('get Carriers', []))
    );
  }

  /**
   * GET a single Carrier by ID
   * @param id - CarrierID
   */
  public getCarrier(id: number): Observable<Carrier> {
    const url = `${this.carriersUrl}/${id}`;
    return this.http
      .get<Carrier>(url)
      .pipe
      // tap(facilities => this.log(`Successfully fetched Carriers`, true)),
      /* TODO: Figure out how to instantiate empty Carrier observable here */
      // catchError(this.handleError('get Carriers'))
      ();
  }
}
