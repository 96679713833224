import {Action} from '@ngrx/store';
import {Piece} from '../model/piece';

export enum TrackingActionType {
  GET_PIECES = '[Tracking] Get Pieces',
  GET_PIECES_SUCCESS = '[Tracking] Get Pieces Success',
}

export class GetPiecesAction implements Action {
  public readonly type = TrackingActionType.GET_PIECES;

  constructor(public payload: {searchValue: string}) {}
}

export class GetPiecesSuccessAction implements Action {
  public readonly type = TrackingActionType.GET_PIECES_SUCCESS;

  constructor(public payload: {pieces: Piece[]; searchValue: string}) {}
}

export type TrackingAction = GetPiecesAction | GetPiecesSuccessAction;
