import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {BatchApiService} from '../../api/batch-api.service';
import {convertClearanceInspectorDataDtoToModel} from '../../api/converters/convert-clearance-inspector-data-dto-to-model';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  ClearanceInspectorActionType,
  ClearanceInspectorCloseoutAction,
  DownloadClearanceInspectorSpreadsheetAction,
  GetClearanceInspectorPiecesAction,
  GetClearanceInspectorPiecesSuccessAction,
  UpdateClearanceInspectorLoadingAction,
} from './clearance-inspector.actions';
import {saveAs} from 'file-saver';
import {convertClearanceInspectorCloseoutReturnDtoToModel} from '../../api/converters/convert-clearance-inspector-closeout-return-dto-to-model';

@Injectable()
export class ClearanceInspectorEffects {
  public getPieces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetClearanceInspectorPiecesAction>(ClearanceInspectorActionType.GET_PIECES),
      mergeMap(action => {
        const {pieceIds} = action.payload;

        return this.batchApiService.getClearanceInspectorPieces(pieceIds).pipe(
          map(dto => convertClearanceInspectorDataDtoToModel(dto)),
          mergeMap(clearanceData => [
            new GetClearanceInspectorPiecesSuccessAction({pieceIds, clearanceData}),
            new UpdateClearanceInspectorLoadingAction({loadingAfterCloseout: false}),
          ]),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public download$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DownloadClearanceInspectorSpreadsheetAction>(ClearanceInspectorActionType.DOWNLOAD),
      mergeMap(action => {
        const {pieceIds, onSuccess, onFailure} = action.payload;

        return this.batchApiService.downloadClearnaceInspectorSpreadsheet(pieceIds).pipe(
          tap(file => {
            const today = new Date();
            const title = `ClearanceReport-${today.toLocaleDateString()}.xlsx`;
            const blob = new Blob([file], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, title);
          }),
          mergeMap(response => [...createCallbackActions(onSuccess, response)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public closeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ClearanceInspectorCloseoutAction>(ClearanceInspectorActionType.CLOSEOUT),
      mergeMap(action => {
        const {pieceIds, onSuccess, onFailure} = action.payload;

        return this.batchApiService.clearanceInspectorCloseout(pieceIds).pipe(
          map(dto => convertClearanceInspectorCloseoutReturnDtoToModel(dto)),
          mergeMap(data => [...createCallbackActions(onSuccess, data)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(private actions$: Actions, private batchApiService: BatchApiService) {}
}
