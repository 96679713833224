import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {ENVIRONMENT, Environment} from '../environment';
import {ActivePieceStatusIndexPipe} from './active-piece-status-index.pipe';
import {TrackingApiService} from './api/tracking-api.service';
import {TrackingEffects} from './store/tracking.effects';
import {trackingReducer} from './store/tracking.reducer';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature('tracking', trackingReducer),
    EffectsModule.forFeature([TrackingEffects]),
  ],
  providers: [],
  declarations: [ActivePieceStatusIndexPipe],
  exports: [ActivePieceStatusIndexPipe],
})
export class DraTrackingModule {
  public static withEnvironment(environment: Environment): ModuleWithProviders<DraTrackingModule> {
    return {
      ngModule: DraTrackingModule,
      providers: [{provide: ENVIRONMENT, useValue: environment}, TrackingApiService],
    };
  }
}
