import {ClearanceInspectorState, initialClearanceInspectorState} from './clearance-inspector/clearance-inspector.state';
import {PieceInspectorState, initialPieceInspectorState} from './piece-inspector/piece-inspector.state';
import {BatchInspectorState, initialBatchInspectorState} from './batch-inspector/batch-inspector.state';
import {AuthState, initialAuthState} from './auth/auth.state';
import {BatchTrackingState, initialBatchTrackingState} from './batch-tracking/batch-tracking.state';
import {CarrierState, initialCarrierState} from './carrier/carrier.state';
import {FacilityState, initialFacilityState} from './facility/facility.state';
import {initialParsState, ParsState} from './pars/pars.state';
import {CustomRouterReducerState} from './router/router.state';
import {initialWorkspaceState, WorkspaceState} from './workspace/workspace.state';
import {GatewayState, initialGatewayState} from './gateway/gateway.state';
import {ClientDashboardState, initialClientDashboardState} from './client-dashboard/client-dashboard.state';
import {AuthTrackingState, initialAuthTrackingState} from './auth-tracking/auth-tracking.state';

export interface AppState {
  auth: AuthState;
  batchTracking: BatchTrackingState;
  carriers: CarrierState;
  facilities: FacilityState;
  pars: ParsState;
  router: CustomRouterReducerState;
  workspace: WorkspaceState;
  gateway: GatewayState;
  batchInspector: BatchInspectorState;
  pieceInspector: PieceInspectorState;
  clearanceInspector: ClearanceInspectorState;
  clientDashboard: ClientDashboardState;
  authTracking: AuthTrackingState;
}

export const initialAppState: AppState = {
  auth: initialAuthState,
  batchTracking: initialBatchTrackingState,
  carriers: initialCarrierState,
  facilities: initialFacilityState,
  pars: initialParsState,
  router: null,
  workspace: initialWorkspaceState,
  gateway: initialGatewayState,
  batchInspector: initialBatchInspectorState,
  pieceInspector: initialPieceInspectorState,
  clearanceInspector: initialClearanceInspectorState,
  clientDashboard: initialClientDashboardState,
  authTracking: initialAuthTrackingState,
};
