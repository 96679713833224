import {BatchFileData} from '../../models/batch-file-data';
import {GatewayBatch} from '../../models/gateway-batch';
import {PieceDetail} from '../../models/piece-detail';

export interface GatewayState {
  batchesCount: number;
  detailBatches: GatewayBatch[];
  queryBatchesMap: Record<string, GatewayBatch[]>;
  piecesMap: Record<string, PieceDetail[]>;
  piecesCount: number;
  fileData: BatchFileData;
}

export const initialGatewayState: GatewayState = {
  batchesCount: 0,
  detailBatches: [],
  queryBatchesMap: {},
  piecesMap: {},
  piecesCount: 0,
  fileData: {},
};
