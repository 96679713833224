/* eslint-disable */

import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import {EnvironmentName} from 'dra-lib';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevelopmentGuard implements CanActivate, CanLoad {
  constructor(private router: Router) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.redirectToHomeOnProduction();
  }

  public canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.redirectToHomeOnProduction();
  }

  private redirectToHomeOnProduction(): boolean {
    if (environment.envName !== EnvironmentName.Production) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
