import {Action} from '@ngrx/store';
import {Piece} from 'projects/dra-lib/src/public-api';

export enum AuthTrackingActionType {
  GET_PIECES = '[Auth Tracking] Get Pieces',
  GET_PIECES_SUCCESS = '[Auth Tracking] Get Pieces :: Success',
}

export class GetAuthPiecesAction implements Action {
  public readonly type = AuthTrackingActionType.GET_PIECES;

  constructor(public payload: {searchValue: string}) {}
}

export class GetAuthPiecesSuccessAction implements Action {
  public readonly type = AuthTrackingActionType.GET_PIECES_SUCCESS;

  constructor(public payload: {pieces: Piece[]; searchValue: string}) {}
}

export type AuthTrackingAction = GetAuthPiecesAction | GetAuthPiecesSuccessAction;
