import {createSelector} from '@ngrx/store';
import * as moment from 'moment';
import {AppState} from '../app.state';

export const selectAuthState = (state: AppState) => state.auth;

export const selectAuthToken = createSelector(selectAuthState, state => state.token);

export const selectAuthenticated = createSelector(selectAuthToken, authToken =>
  Boolean(authToken?.expirationDate && moment().isBefore(authToken?.expirationDate))
);
