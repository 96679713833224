<page-wrapper>
  <ng-container *ngIf="{configuration: configuration$ | async} as state" slot="content">
    <ng-container *ngIf="!state.configuration; else loaded">
      <loading-results [text]="'Loading Applications...'"></loading-results>
    </ng-container>
    <ng-template #loaded>
      <div *ngFor="let category of applicationCategories$ | async" class="category-container">
        <div class="title">{{ category.description }}</div>
        <div class="app-container">
          <login-landing-app
            *ngFor="let application of category.applications"
            [application]="application"
            (navigate)="onNavigate($event)"
          ></login-landing-app>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="{errorMessage: errorMessage$ | async} as state">
      <div *ngIf="state.errorMessage" class="error-container">
        <div class="error-text">{{ state.errorMessage }}</div>
      </div>
    </ng-container>
  </ng-container>
</page-wrapper>
