import {Injectable} from '@angular/core';
import {createEffect, ofType, Actions} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
import {of} from 'rxjs';
import {switchMap, map, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../app.state';
import {
  EFacilityActions,
  GetFacilities,
  GetFacilitiesSuccess,
  SetActiveFacility,
  SetActiveFacilitySuccess,
} from './facility.actions';
import {FacilityService} from 'src/app/core/services';
import {selectFacilityList} from './facility.selectors';

@Injectable()
export class FacilityEffects {
  getFacilities$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetFacilities>(EFacilityActions.GetFacilities),
      switchMap(() => this.facilityService.getPIFacilities()),
      switchMap(facilityHttp => of(new GetFacilitiesSuccess(facilityHttp)))
    )
  );

  getFacility$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetActiveFacility>(EFacilityActions.SetActiveFacility),
      map(action => action.payload),
      withLatestFrom(this.store.pipe(select(selectFacilityList))),
      switchMap(([id, facilities]) => {
        const activeFacility = facilities.filter(f => f.PIFacilityID === +id)[0];
        return of(new SetActiveFacilitySuccess(activeFacility));
      })
    )
  );

  constructor(private facilityService: FacilityService, private actions$: Actions, private store: Store<AppState>) {}
}
