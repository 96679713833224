<page-wrapper *ngIf="{config: configuration$ | async} as state">
  <ng-container *ngIf="state.config" slot="content">
    <div class="item">
      <span class="title">DRA Database Name:</span>
      <span class="name">{{ state.config.draDatabaseName }}</span>
    </div>
    <div class="item">
      <span class="title">Instance:</span>
      <span class="name">{{ state.config.instance }}</span>
    </div>
    <div class="item">
      <span class="title">PARS Database Name:</span>
      <span class="name">{{ state.config.parsDatabaseName }}</span>
    </div>
    <div class="item">
      <span class="title">Security Database Name:</span>
      <span class="name">{{ state.config.securityDatabaseName }}</span>
    </div>
  </ng-container>
</page-wrapper>
