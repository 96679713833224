import {Component, EventEmitter, Output} from '@angular/core';
import {QuickAccessItems} from 'src/app/core/models/quick-access-items';
import {Constants} from 'src/app/shared/constants';

@Component({
  selector: 'app-menu-drawer',
  templateUrl: './menu-drawer.component.html',
  styleUrls: ['./menu-drawer.component.scss'],
})
export class MenuDrawerComponent {
  collapsed: boolean = false;
  public menuItems: QuickAccessItems[];
  public filteredMenuItems: QuickAccessItems[];
  public categories: {text: string; color: string; activeIcon: string; inactiveIcon: string}[] = [
    {
      text: 'All',
      color: '#1400a1',
      activeIcon: 'assets/all-small-v-1.svg',
      inactiveIcon: 'assets/all-small-v-1-grey.svg',
    },
    {
      text: 'PARS',
      color: '#FE6BBB',
      activeIcon: 'assets/pars-small-v-1.svg',
      inactiveIcon: 'assets/pars-small-v-1-grey.svg',
    },
    {
      text: 'Carriers',
      color: '#b200ff',
      activeIcon: 'assets/carriers-small-v-1.svg',
      inactiveIcon: 'assets/carriers-small-v-1-grey.svg',
    },
    {
      text: 'Clients',
      color: '#0fb64b',
      activeIcon: 'assets/clients-small-v-1.svg',
      inactiveIcon: 'assets/clients-small-v-1-grey.svg',
    },
    {
      text: 'Facilities',
      color: '#00aed6',
      activeIcon: 'assets/facilities-small-v-1.svg',
      inactiveIcon: 'assets/facilities-small-v-1-grey.svg',
    },
    {
      text: 'Legs',
      color: '#ff7900',
      activeIcon: 'assets/legs-small-v-1.svg',
      inactiveIcon: 'assets/legs-small-v-1-grey.svg',
    },
  ];
  public activeCategory: string = 'All';
  @Output() closeMenu = new EventEmitter<boolean>();

  constructor(private constants: Constants) {
    this.menuItems = this.constants.QUICK_ACCESS_ITEMS;
    this.filteredMenuItems = this.menuItems;
  }

  toggleMenu(): void {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      this.closeMenu.emit(true);
    }
  }

  setActiveCategory(category: string): void {
    this.activeCategory = category;
    this.filterMenuItems();
  }

  filterMenuItems(): void {
    if (this.activeCategory === 'All') {
      this.filteredMenuItems = this.menuItems;
      return;
    }
    this.filteredMenuItems = this.menuItems.filter(m => m.tags.includes(this.activeCategory));
  }
}
