import {ApplicationCategoryDto, ApplicationCategoryAppDto} from './../dto/application-category.dto';
import {ApplicationCategory, ApplicationCategoryApp} from './../../models/application-category';

export function convertApplicationCategoryDtoToModel(dto: ApplicationCategoryDto): ApplicationCategory {
  return {
    applications: dto.Applications?.map(app => convertApplicationAppDtoToModel(app)) || [],
    description: dto.Description,
  };
}

function convertApplicationAppDtoToModel(app: ApplicationCategoryAppDto): ApplicationCategoryApp {
  return {
    appGuid: app.AppGuid,
    appId: app.AppID,
    caption: app.Caption,
    categories: app.Categories,
    categoryList: app.CategoryList,
    description: app.Description,
  };
}
