import {selectRouterUrl} from 'src/app/core/store/router/router.selector';
import {Observable} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

const pageTitles = [
  {url: '/batch-tracking', value: 'Batch Tracking'},
  {url: '/gateway', value: 'Gateway Batch Manager'},
  {url: '/file-search', value: 'Document Repository'},
  {url: '/batch-inspectr', value: 'Batch InspectR'},
  {url: '/piece-inspectr-game', value: 'Gateway Games'},
  {url: '/piece-inspectr', value: 'Piece InspectR'},
  {url: '/tracking', value: 'Piece Tracking'},
  {url: '/home', value: 'Home'},
  {url: '/clearance', value: 'Clearance InspectR'},
  {url: '/user', value: 'User'},
  {url: '/barcode-games', value: 'Barcode games'},
  {url: '/dashboard', value: 'Client Dashboard'},
  {url: '/shipment-manager-trainer', value: 'Shipment ManageR'},
  {url: '/configuratr', value: 'ConfiguratR'},
];

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  constructor(private store$: Store) {}

  public getPageTitle(): Observable<string> {
    return this.store$.pipe(select(selectRouterUrl)).pipe(
      map(url => {
        return pageTitles.find(title => url?.startsWith(title.url))?.value ?? '';
      })
    );
  }
}
