import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DebuggingPageComponent} from './debugging-page.component';
import {TrackingLayoutModule} from '../tracking-layout/tracking-layout.module';

@NgModule({
  declarations: [DebuggingPageComponent],
  imports: [CommonModule, TrackingLayoutModule],
  exports: [DebuggingPageComponent],
})
export class DebuggingPageModule {}
