<div class="container" [class.desktop-container]="!mobile" [class.mobile-container]="mobile">
  <header [class.desktop-header]="!mobile" [class.mobile-header]="mobile">
    <div class="top-container">
      <div class="avatar">
        <span>{{ username | firstLetter }}</span>
      </div>
      <button mat-icon-button (click)="onCollapse()">
        <img src="assets/caret-left.svg" class="collapse-icon" />
      </button>
    </div>

    <span class="username">{{ username }}</span>
  </header>

  <nav class="do-not-print">
    <ng-container *ngFor="let m of menuItems">
      <a
        *ngIf="!m.applicationId || applicationsMap[m.applicationId]"
        [routerLink]="m.address"
        routerLinkActive="active"
      >
        <button mat-flat-button class="nav-item" [class.active]="activeRoute === m.address" (click)="onNavItem(m.name)">
          <img [src]="m.iconSrc" />
          <img [src]="m.activeIcon" style="display: none" />
          <span class="name" [class.bold]="activeRoute === m.address">{{ m.name }}</span>
        </button>
      </a>
    </ng-container>
  </nav>

  <footer>
    <button mat-flat-button class="logout-button" (click)="onLogoutClick()">
      <img src="assets/icons/logout.svg" />
      <span>Logout</span>
    </button>
  </footer>
</div>
