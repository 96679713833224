import {PieceInspectorNewBatchParams} from './../../models/piece-inspector-new-batch-params';
import {PieceInspectorNewBatchParamsDto} from './../dto/piece-inspector-new-batch-params.dto';

export function convertPieceInspectorNewBatchParamsModelToDto(
  params: PieceInspectorNewBatchParams
): PieceInspectorNewBatchParamsDto {
  return {
    BatchNumber: params.batchNumber,
    CompanyID: params.companyId,
    Origin: params.shipper,
    DepotCode: params.facility,
    PinList: params.pinList,
    TransitionType: params.transitionType,
    AppID: params.appId,
  };
}
