import {Piece} from '../model/piece';

export interface TrackingState {
  loaded: boolean;
  piecesMap: Record<string, Piece[]>;
}

export const initialTrackingState: TrackingState = {
  loaded: false,
  piecesMap: {},
};
