import {UserInfo} from '../../models/user-info';
import {UserInfoDto} from '../dto/user-info.dto';

export function convertUserInfoDtoToModel(dto: UserInfoDto): UserInfo {
  return {
    id: dto.Id,
    userGuid: dto.UserGUID,
    username: dto.Username,
    displayName: dto.DisplayName,
    email: dto.Email,
    gameHandle: dto.GameHandle,
    companyId: dto.CompanyID,
    filterSetId: dto.FilterSetID,
    appSetId: dto.AppSetID,
    batchOperationsSetId: dto.BatchOperationsSetID,
    facility: dto.Facility,
    endpointUrlDra: dto.EndpointUrlDra,
  };
}
