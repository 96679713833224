import * as moment from 'moment';
import {
  GatewayGameLap,
  GatewayGameStatistics,
  PieceInspectorBatch,
  PieceInspectorBatchCounts,
  PieceInspectorBatchCountsSubtotal,
  PieceInspectorMessage,
} from './../../models/piece-inspector-batch';
import {
  GatewayGameLapDto,
  GatewayGameStatisticsDto,
  PieceInspectorBatchCountsDto,
  PieceInspectorBatchCountsSubtotalDto,
  PieceInspectorBatchDto,
  PieceInspectorMessageDto,
} from './../dto/piece-inspector-batch.dto';
import {convertBatchFileDtoToModel} from './convert-batch-file-dto-to-model';
import {convertPieceInspectorPieceDtoToModel} from './convert-piece-inspector-piece-dto-to-model';
import {convertPieceInspectorScanPieceDtoToModel} from './convert-piece-inspector-scan-piece-dto-to-model';

export function convertPieceInspectorBatchDtoToModel(dto: PieceInspectorBatchDto): PieceInspectorBatch {
  return {
    batchDescription: dto.BatchDescription,
    batchId: dto.BatchID,
    batchHeader: dto.BatchHeader,
    batchLabel: dto.BatchLabel,
    batchIdentifier: dto.BatchIdentifier,
    batchNumber: dto.BatchNumber,
    batchType: dto.BatchType,
    batchTypeLabel: dto.BatchTypeLabel,
    cargoControlNumber: dto.CargoControlNumber || null,
    carrierTrackingNumber: dto.CarrierTrackingNumber || null,
    clearanceType: dto.ClearanceType,
    clearanceTypeLabel: dto.ClearanceTypeLabel,
    companyId: dto.CompanyID,
    containerCount: dto.ContainerCount,
    createdBy: dto.CreatedBy,
    dateCaption: dto.DateCaption,
    dateValue: dto.DateValue ? moment.utc(dto.DateValue) : null,
    depotCode: dto.DepotCode,
    documents: dto.Documents?.map(d => convertBatchFileDtoToModel(d)) || null,
    messagePieceCount: dto.MessagePieceCount,
    origin: dto.Origin,
    originLabel: dto.OriginLabel,
    packageCount: dto.PackageCount,
    processingComplete: dto.ProcessingComplete,
    scanLog: dto.ScanLog?.map(piece => convertPieceInspectorScanPieceDtoToModel(piece)),
    shipDate: dto.ShipDate ? moment.utc(dto.ShipDate) : null,
    statusCategory: dto.StatusCategory,
    statusDate: dto.StatusDate,
    statusType: dto.StatusType,
    statusTypeLabel: dto.StatusTypeLabel,
    totalWeight: dto.TotalWeight,
    pieces: dto.Pieces?.map(piece => convertPieceInspectorPieceDtoToModel(piece)),
    pieceCountMessage: dto.MessagePieceCount,
    reference1: dto.Reference1 || null,
    reference2: dto.Reference2 || null,
    reference3: dto.Reference3 || null,
    reference4: dto.Reference4 || null,
    reference5: dto.Reference5 || null,
    parentBatchNumber: dto.ParentBatchNumber,
    location: dto.Location,
    originFacilityCode: dto.OriginFacilityCode,
    originDepotCode: dto.OriginDepotCode,
    facilityCode: dto.FacilityCode,
    gatewayArrivalDate: dto.GatewayArrivalDate,
    totalPieces: dto.TotalPieces,
    totalPiecesOriginal: dto.TotalPiecesOriginal,
    totalPiecesUnexpected: dto.TotalPiecesUnexpected,
    totalWeightOriginal: dto.TotalWeightOriginal,
    totalPiecesVerified: dto.TotalPiecesVerified,
    totalWeightVerified: dto.TotalWeightVerified,
    statistics: dto.Statistics ? convertGatewayGameStatisticDtoToModel(dto.Statistics) : null,
    notes: dto.Notes,
    counts: dto.Counts ? convertPieceInspectorCountsDtoToModel(dto.Counts) : ({} as PieceInspectorBatchCounts),
    messages: dto.Messages?.map(message => convertPieceInspectorMessagesDtoToModel(message)),
  };
}

function convertPieceInspectorMessagesDtoToModel(message: PieceInspectorMessageDto): PieceInspectorMessage {
  return {
    message: message.Message,
    type: message.Type,
    pieces: message.Pieces.map(piece => {
      return {
        pieceId: piece.PieceID,
        pin: piece.Pin,
      };
    }),
  };
}

function convertPieceInspectorCountsDtoToModel(dto: PieceInspectorBatchCountsDto): PieceInspectorBatchCounts {
  return {
    packageCountCpc: dto.PackageCountCPC,
    packageCountPurolator: dto.PackageCountPurolator,
    palletCount: dto.PalletCount,
    totalCount: dto.PalletCount,
    subtotals: dto.Subtotals.map(s => convertSubtotalDtoToModel(s)),
  };
}

function convertSubtotalDtoToModel(dto: PieceInspectorBatchCountsSubtotalDto): PieceInspectorBatchCountsSubtotal {
  return {
    accountId: dto.AccountID,
    accountLabel: dto.AccountLabel,
    packageCountCpc: dto.PackageCountCPC,
    packageCountPurolator: dto.PackageCountPurolator,
    palletCount: dto.PalletCount,
    totalCount: dto.TotalCount,
  };
}

function convertGatewayGameStatisticDtoToModel(dto: GatewayGameStatisticsDto): GatewayGameStatistics {
  return {
    scanCount: dto.ScanCount,
    remainingCount: dto.RemainingCount,
    totalCount: dto.TotalCount,
    currentLap: dto.CurrentLap,
    totalLaps: dto.TotalLaps,
    avgScanIntervalOverall: dto.AvgScanIntervalOverall,
    avgScanIntervalCurrentLap: dto.AvgScanIntervalCurrentLap,
    lastScanInterval: dto.LastScanInterval,
    totalTime: dto.TotalTime,
    laps: dto.Laps?.map(lap => convertGatewayGameLapDtoToModel(lap)),
  };
}

function convertGatewayGameLapDtoToModel(dto: GatewayGameLapDto): GatewayGameLap {
  return {
    index: dto.Index,
    scanCount: dto.ScanCount,
    remainingCount: dto.RemainingCount,
    totalCount: dto.TotalCount,
    avgScanInterval: dto.AvgScanInterval,
    totalTime: dto.TotalTime,
  };
}
