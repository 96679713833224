<div class="main-container" [class.list-item]="!fromDetail">
  <div class="header">
    <div class="header-top-container">
      <div class="lesson-number">{{ lesson.lessonNumber }}</div>
      <div class="image-container">
        <img *ngIf="showIcon" src="assets/icons/check-green.svg" />
      </div>
    </div>
    <span class="lesson-title" [class.blue-text]="fromDetail">{{ lesson.moduleName }}</span>
  </div>

  <span class="lesson-info">{{ lesson.tagline }}</span>
</div>
