/* eslint-disable */

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {PIFacility, Projection} from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  // TODO: Update apiUrl once old routes are moved over.
  // private facilityUrl = `${environment.apiUrl}/pi-facilities`
  private facilityUrl = 'https://api-dra-dev.purolatorshipping.com/api/Facility';

  constructor(private http: HttpClient) {}

  /**
   * Handle any Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      /* TODO: send errors to remote logging infrastructure */
      console.error(error);
      console.log(`Something went wrong while trying to ${operation}. Please try again.`);
      /* Let the app keep running by returning an empty result. */
      return of(result as T);
    };
  }

  /**
   * GET array of PI Facilities
   */
  getPIFacilities(): Observable<Array<PIFacility>> {
    const url = this.facilityUrl;
    // TODO: Remove random statuses/tasks
    const statuses = ['success', 'warning', 'error'];
    return this.http.get<Array<PIFacility>>(url).pipe(
      // tap(facilities => this.log(`Successfully fetched PI Facilities`, true)),
      map((facilities: PIFacility[]) =>
        facilities.map(f => {
          const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];
          f.Status = randomStatus;
          // TODO: Remove. Had to shorten so these fit on cards.
          if (f.FacilityType === 'Canadian Dispatch Point') {
            f.FacilityType = 'Canadian Dispatch';
          }
          if (f.FacilityType === 'Canadian Induction Point') {
            f.FacilityType = 'Canadian Induction';
          }
          return f;
        })
      ),
      catchError(this.handleError('get PI Facilities', []))
    );
  }

  /**
   * GET a single PI Facility by ID
   * @param id - PIFacilityID
   */
  getPIFacility(id: number): Observable<PIFacility> {
    const url = `${this.facilityUrl}/${id}`;
    return this.http
      .get<PIFacility>(url)
      .pipe
      // tap(facilities => this.log(`Successfully fetched PI Facilities`, true)),
      /* TODO: Figure out how to instantiate empty PI Facility observable here */
      // catchError(this.handleError('get PI Facilities'))
      ();
  }

  /**
   * GET projections for a PI Facility by ID
   * returns a list of projections by date and client
   * @param id - PIFacilityID
   */
  getProjectionsForPIFacility(id: number): Observable<Array<Projection>> {
    const url = `${this.facilityUrl}/${id}/projections`;
    return this.http
      .get<Array<Projection>>(url)
      .pipe
      // tap(projections => this.log(`Successfully fetched PI Facility Projections`, true)),
      /* TODO: Figure out how to instantiate empty Projection observable here */
      // catchError(this.handleError('get Projections for PI Facility'))
      ();
  }

  /**
   * Accepts facility data and submits the appropriate request to either
   * create a new facility, or update an existing one
   * @param newFacilityData - a new PIFacility object with updated fields
   * @param facilityToEdit - the PIFacility object to be updated with newFacilityData
   */
  createOrUpdatePIFacility(newFacilityData: PIFacility, facilityToEdit: PIFacility): Observable<any> {
    if (facilityToEdit) {
      console.log('Editing facility');
      // Update the facilityToEdit obj with the new values
      // TODO: Async issue here? Test.
      for (const key in newFacilityData) {
        if (newFacilityData.hasOwnProperty(key)) {
          facilityToEdit[key] = newFacilityData[key];
        }
      }
      facilityToEdit.SupportedTasks = this.stringifySupportedTasks(facilityToEdit.SupportedTasks);
      console.log('Facility to Edit:', facilityToEdit);
      // Submit to backend
      return this.updatePIFacility(facilityToEdit);
    } else {
      console.log('Creating new facility');
      newFacilityData.SupportedTasks = this.stringifySupportedTasks(newFacilityData.SupportedTasks);
      console.log('Facility to create:', newFacilityData);
      return this.createPIFacility(newFacilityData);
    }
  }

  /**
   * Translates the SupportedTasks to the format expected by Worldpak
   */
  stringifySupportedTasks(tasks: string[]): string {
    // TODO: Talk to Worldpak about JSON format. The SupportedTasks field should
    // be represented as a JSON array of strings.
    let taskString = '{["';
    taskString += tasks.join('", "');
    taskString += '"]}';
    console.log('Task String is', taskString);
    return taskString;
  }

  /**
   * POST PI Facility
   * Submits a new PIFacility to the backend, returns an observable response
   * @param facility - PIFacility to create
   */
  createPIFacility(facility: PIFacility): Observable<any> {
    const url = this.facilityUrl;
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post(url, facility, httpOptions).pipe(
      tap((r: any) => console.log(`Success! Your facility has been saved.`)),
      catchError(this.handleError<any>('add Facility'))
    );
  }

  /**
   * PUT PI Facility
   * Submits a PIFacility to the backend, returns an observable response
   * @param facility - PIFacility with updated fields
   */
  updatePIFacility(facility: PIFacility): Observable<any> {
    const url = this.facilityUrl;
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.put(url, facility, httpOptions).pipe(
      tap((r: any) => console.log(`Success! Your facility has been saved.`)),
      catchError(this.handleError<any>('update Facility'))
    );
  }

  /**
   * DELETE PI Facility
   * Submits a PIFacility to the backend, returns an observable response
   * @param facility - PIFacility to delete
   */
  deletePIFacility(facility: PIFacility): Observable<any> {
    console.log('Called deletePIFacility', facility);
    const url = `${this.facilityUrl}/${facility.PIFacilityID}`;
    return this.http.delete(url).pipe(
      tap(() => console.log('successfully deleted facility')),
      catchError(this.handleError('delete facility', []))
    );
  }
}
