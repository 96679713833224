<ng-container *ngIf="{trainingModeSidebarOpen: trainingModeSidebarOpen$ | async} as state">
  <mat-drawer-container hasBackdrop="false" class="flex-grow">
    <mat-drawer mode="side" position="end" [opened]="state.trainingModeSidebarOpen" class="training-drawer">
      <training-mode
        [lessons]="lessons"
        (toggle)="onTrainingModeToggle()"
        (activeLesson)="onLessonSelect($event)"
      ></training-mode>
    </mat-drawer>
    <mat-drawer-content>
      <ng-content></ng-content>
    </mat-drawer-content>
  </mat-drawer-container>
  <training-mode-drawer-collapsed
    *ngIf="!state.trainingModeSidebarOpen"
    class="training-mode-drawer-closed"
    [lessons]="lessons"
    [activeLessonNumber]="activeLessonNumber$ | async"
    (toggle)="onTrainingModeToggle()"
  ></training-mode-drawer-collapsed>
</ng-container>
