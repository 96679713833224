import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Lesson} from 'src/app/core/models/lesson';

@Component({
  selector: 'training-mode-test-packages',
  templateUrl: './training-mode-test-packages.component.html',
  styleUrls: ['./training-mode-test-packages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingModeTestPackagesComponent {
  @Input()
  public lesson: Lesson;

  @Output()
  public copyPackages = new EventEmitter<string>();

  public onCopy() {
    this.copyPackages.emit(this.lesson.testPackages.join(','));
  }
}
