import {FileSearchParams} from '../../models/file-search-params';
import {FileSearchParamsDto} from '../dto/file-search-params-dto';

export function convertFileSearchParamsModelToDto(params: FileSearchParams): FileSearchParamsDto {
  return {
    facilityCode: params.facility || 'ALL',
    origin: params.shipper,
    dateSearchType: formatDateSearchType(params.dateSearchType),
    startDate: params.startDate,
    endDate: params.endDate,
    rowsPerPage: params.pageSize,
    pageNumber: params.page,
    searchText: params.search ? encodeURIComponent(params.search) : '',
    companyId: params.companyId,
  };
}

function formatDateSearchType(type: string) {
  if (['WITHIN 1 WEEK', 'WITHIN 1 MONTH'].includes(type)) {
    return 'BETWEEN';
  }
  if (type === 'TODAY') {
    return 'ON';
  }
  return type;
}
