import {PieceInspectorPieceParamsDto} from './../dto/piece-inspector-piece-params.dto';
import {PieceInspectorPieceParams} from './../../models/piece-inspector-piece-params';
export function convertPieceInspectorPieceParamsModelToDto(
  params: PieceInspectorPieceParams
): PieceInspectorPieceParamsDto {
  return {
    BatchNumber: params.batchNumber,
    ScanCode: params.code,
    Facility: params.facility,
    LastScanLogID: params.lastScanLogId ?? undefined,
  };
}
