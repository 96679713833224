import {BreakpointObserver} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  public observeMobile(maxWidth = 840): Observable<boolean> {
    return this.breakpointObserver.observe(`(max-width: ${maxWidth}px)`).pipe(
      debounceTime(500),
      map(breakpointState => breakpointState.matches),
      startWith(this.breakpointObserver.isMatched(`(max-width: ${maxWidth}px)`))
    );
  }
}
