<nav class="do-not-print">
  <div *ngIf="!mobile" class="top-container">
    <button mat-icon-button (click)="onOpen()">
      <img src="assets/caret-left.svg" class="open-icon" />
    </button>
  </div>
  <div class="application-container">
    <ul>
      <li *ngFor="let m of menuItems">
        <a
          *ngIf="!m.applicationId || applicationsMap[m.applicationId]"
          [routerLink]="m.address"
          routerLinkActive="active"
          (mouseover)="m.iconSrc = m.activeIcon"
          (mouseout)="m.iconSrc = m.address === activeRoute ? m.activeIcon : m.inactiveIcon"
          [matTooltip]="m.tooltip"
          matTooltipPosition="after"
          (click)="onNavItem(m.name)"
        >
          <img [src]="m.iconSrc" class="icon" />
          <!-- Preload hover images to avoid lag -->
          <img [src]="m.activeIcon" style="display: none" />
        </a>
      </li>
    </ul>
  </div>

  <div class="logout-button-container">
    <button mat-icon-button class="logout-button" (click)="onLogoutClick()">
      <div class="inner-button">
        <img src="assets/icons/logout.svg" />
      </div>
    </button>
  </div>
</nav>
