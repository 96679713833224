import {ChangeDetectionStrategy, Component, Input, OnChanges, Output, SimpleChanges, EventEmitter} from '@angular/core';
import {ApplicationCategoryApp} from './../../../../core/models/application-category';

@Component({
  selector: 'login-landing-app',
  templateUrl: './login-landing-app.component.html',
  styleUrls: ['./login-landing-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginLandingAppComponent implements OnChanges {
  @Input()
  public application: ApplicationCategoryApp;

  @Output()
  public navigate = new EventEmitter<ApplicationCategoryApp>();

  public iconSrc: string;
  public applicationNameArray: string[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.application && this.application) {
      this.applicationNameArray = this.application.caption.split(' ');
      this.iconSrc = `assets/icons/tracking-icons/landing-page/${this.application.appId.toLowerCase()}.svg`;
    }
  }

  onApplication() {
    this.navigate.emit(this.application);
  }
}
