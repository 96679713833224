import {LoadingResultsModule} from 'src/app/shared/loading-results/loading-results.module';

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {LoginCredentialsComponent} from './credentials/login-credentials.component';
import {LoginComponent} from './login.component';
import {LoginWorkspaceComponent} from './workspace/login-workspace.component';
import {LoginLandingPageComponent} from './landing/login-landing-page.component';
import {LoginLandingAppComponent} from './landing/landing-app/login-landing-app.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedModule, RouterModule, LoadingResultsModule],
  declarations: [
    LoginComponent,
    LoginCredentialsComponent,
    LoginWorkspaceComponent,
    LoginLandingPageComponent,
    LoginLandingAppComponent,
  ],
})
export class LoginModule {}
