import {PieceInspectorPiece} from './../../models/piece-inspector-piece';
import {PieceInspectorPieceDto} from '../dto/piece-inspector-piece.dto';

export function convertPieceInspectorPieceDtoToModel(dto: PieceInspectorPieceDto): PieceInspectorPiece {
  return {
    addedBy: dto.AddedBy,
    pieceId: dto.PieceID,
    orderNumber: dto.OrderNumber,
    pin: dto.Pin,
    proNumber: dto.ProNumber,
    shipDate: dto.ShipDate,
    statusType: dto.StatusType,
    statusDate: dto.StatusDate,
    pieceTypeLabel: dto.PieceTypeLabel,
    pieceType: dto.PieceType,
    weight: dto.Weight,
    pieceCount: dto.PieceCount,
    depotCode: dto.DepotCode,
    facilityCode: dto.FacilityCode,
    origin: dto.Origin,
    originId: dto.OriginID,
    originLabel: dto.OriginLabel,
    batchId: dto.BatchID,
    accountId: dto.AccountID,
    shipMethod: dto.ShipMethod,
    shipMethodDisplay: dto.ShipMethodDisplay,
    consolePin: dto.ConsolePin,
    color: dto.Color,
    statusTypeLabel: dto.StatusTypeLabel,
    destinationPostalCode: dto.DestinationPostalCode,
  };
}
