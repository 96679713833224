import {EFacilityActions, FacilityActions} from './facility.actions';
import {FacilityState, initialFacilityState} from './facility.state';

export function facilityReducers(state = initialFacilityState, action: FacilityActions): FacilityState {
  switch (action.type) {
    case EFacilityActions.GetFacilitiesSuccess: {
      return {
        ...state,
        facilities: action.payload,
      };
    }
    case EFacilityActions.SetActiveFacilitySuccess: {
      return {
        ...state,
        activeFacility: action.payload,
      };
    }
    default:
      return state;
  }
}
