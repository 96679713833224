import {AuthTrackingAction, AuthTrackingActionType} from './auth-tracking.action';
import {AuthTrackingState, initialAuthTrackingState} from './auth-tracking.state';

export function authTrackingReducer(state = initialAuthTrackingState, action: AuthTrackingAction): AuthTrackingState {
  switch (action.type) {
    case AuthTrackingActionType.GET_PIECES:
      return {...state, loaded: false};
    case AuthTrackingActionType.GET_PIECES_SUCCESS: {
      const piecesMap = {...state.piecesMap, [action.payload.searchValue]: action.payload.pieces};
      return {...state, loaded: true, piecesMap};
    }
    default:
      return state;
  }
}
