import {PieceInspectorBatchSearchParamsDto} from './../dto/piece-inspector-batch-search-params.dto';
import {PieceInspectorBatchSearchParams} from './../../models/piece-inspector-batch-search-params';

export function convertPieceInspectorBatchParamsModelToDto(
  params: PieceInspectorBatchSearchParams
): PieceInspectorBatchSearchParamsDto {
  return {
    facilityCode: params.facility || 'null',
    origin: params.shipper,
    pageNumber: params.page,
    rowsPerPage: params.pageSize,
    sortByFields: createSortByFields(params.sortColumns, params.sortDirections),
    companyId: params.companyId,
    batchType: params.batchType,
  };
}

function createSortByFields(sortColumns: string[], sortDirections: string[]): string {
  return sortColumns
    .map((column, index) => `${convertPieceDetailColumnModelToDto(column)} ${sortDirections[index]}`)
    .join(',');
}

function convertPieceDetailColumnModelToDto(column: string): string {
  switch (column) {
    case 'batchNumber':
      return 'BatchNumber';
    case 'affiliate':
      return 'BatchHeader';
    case 'clearanceType':
      return 'ClearanceType';
    case 'shipDate':
      return 'ShipDate';
    case 'packageCount':
      return 'PackageCount';
    case 'totalWeight':
      return 'TotalWeight';
    case 'statusType':
      return 'StatusType';
    case 'verifiedCount':
      return 'TotalPiecesVerified';
    case 'originalCount':
      return 'TotalPiecesOriginal';
  }
}
