import {BatchComparisonDetailParams} from './../../models/batch-comparison-detail-params';
import {BatchComparisonDetailParamsDto} from './../dto/batch-comparison-detail-params.dto';

export function convertBatchComparisonDetailParamsModelToDto(
  params: BatchComparisonDetailParams
): BatchComparisonDetailParamsDto {
  return {
    batchNumbersAll: encodeURIComponent(params.batchNumbersAll) || 'null',
    batchNumbersIntersect: encodeURIComponent(params.batchNumbersIntersect),
    pageNumber: params.page || 1,
    search: params.search,
    rowsPerPage: params.pageSize || 25,
    sortByFields: createSortByFields(params.sortColumns, params.sortDirections) || '',
  };
}

function createSortByFields(sortColumns: string[], sortDirections: string[]): string {
  return sortColumns?.map((column, index) => `${column} ${sortDirections[index]}`).join(',');
}
