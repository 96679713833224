import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CommonActionType, ExecuteCallbackAction, HandleErrorAction} from './common.action';

@Injectable()
export class CommonEffects {
  public executeCallback$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ExecuteCallbackAction>(CommonActionType.EXECUTE_CALLBACK),
        tap((action: ExecuteCallbackAction) => action.payload.callback())
      ),
    {dispatch: false}
  );

  public handleError$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<HandleErrorAction>(CommonActionType.HANDLE_ERROR),
        tap((action: HandleErrorAction) => console.error(action.payload.error))
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions) {}
}
