import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../core/guards/auth.guard';
import {LoginCredentialsComponent} from './login/credentials/login-credentials.component';
import {LoginLandingPageComponent} from './login/landing/login-landing-page.component';
import {LoginComponent} from './login/login.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: [{path: '', pathMatch: 'full', component: LoginCredentialsComponent}],
  },
  {path: 'home', component: LoginLandingPageComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
