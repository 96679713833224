import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {selectAuthToken} from '../store/auth/auth.selector';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private store$: Store<{}>) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectToLoginOnInvalidToken(state.url);
  }

  private redirectToLoginOnInvalidToken(returnUrl?: string): Observable<boolean> {
    return this.store$.pipe(
      select(selectAuthToken),
      take(1),
      map(token => moment(token?.expirationDate).isAfter(moment.now())),
      tap(valid => {
        if (!valid) {
          this.router.navigate(['/login'], {queryParams: {returnUrl}});
        }
      })
    );
  }
}
