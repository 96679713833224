import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Configuration} from 'src/app/core/models/configuration';
import {selectConfiguration} from 'src/app/core/store/workspace/workspace.selector';

@Component({
  selector: 'debugging-page',
  templateUrl: './debugging-page.component.html',
  styleUrls: ['./debugging-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebuggingPageComponent implements OnInit {
  public configuration$: Observable<Configuration>;

  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.configuration$ = this.store$.pipe(select(selectConfiguration));
    this.configuration$.subscribe(e => console.log(e));
  }
}
