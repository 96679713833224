<main>
  <img src="assets/purolator-logo.svg" />
  <div class="error">
    <span
      >The Purolator Automation InspectR is either being updated or is experiencing an issue. Please check back in a few
      minutes.</span
    >
  </div>
  <button mat-button (click)="onRefreshPage()" class="refresh-button">Refresh page</button>
</main>
