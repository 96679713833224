import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShareFilesDialogComponent} from './share-files-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {EmailFormComponent} from './email-form/email-form.component';
import {FileDisplayComponent} from './file-display/file-display.component';
import {PipesModule} from '../../pipes/pipes.module';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatIconModule} from '@angular/material/icon';
import {InvalidEmailDialogComponent} from './invalid-email-dialog/invalid-email-dialog.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [ShareFilesDialogComponent, EmailFormComponent, FileDisplayComponent, InvalidEmailDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    PipesModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [ShareFilesDialogComponent],
})
export class ShareFilesDialogModule {}
