import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthTokenDto} from './dto/auth-token.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly url = `${environment.loginUrl}/Authenticate`;

  constructor(private http: HttpClient) {}

  public login(username: string, password: string, url: string): Observable<AuthTokenDto> {
    return this.http.post<AuthTokenDto>(`${this.url}/login`, {username, password, url});
  }
}
