import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DraSortDirective} from './dra-sort.directive';
import {DraSortComparisonDetailDirective} from './dra-sort-comparison-detail.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DraSortDirective, DraSortComparisonDetailDirective],
  exports: [DraSortDirective, DraSortComparisonDetailDirective],
})
export class DraSortModule {}
