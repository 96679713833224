import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {MessageService} from 'src/app/core/services';
import {selectClientDashboardTrainerPackages} from 'src/app/core/store/client-dashboard/client-dashboard.selectors';
import {GetClientDashboardTrainerTestPackagesAction} from './../../../../core/store/client-dashboard/client-dashboard.actions';

@Component({
  selector: 'trainer-mode-add-packages-dialog',
  templateUrl: './trainer-mode-add-packages-dialog.component.html',
  styleUrls: ['./trainer-mode-add-packages-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainerModeAddPackagesDialogComponent implements OnInit {
  public packages$: Observable<string[]>;

  public loading$ = new BehaviorSubject<boolean>(false);
  public onMainSelection$ = new BehaviorSubject<boolean>(true);

  public maxPackageCount: number = 1000;

  public form = this.fb.group({
    valid: [true],
    invalid: [true],
    previouslyUsed: [true],
    packageCount: [null, Validators.required],
  });

  constructor(
    private dialogRef: MatDialogRef<TrainerModeAddPackagesDialogComponent>,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private store$: Store
  ) {}

  ngOnInit(): void {
    this.packages$ = this.store$.pipe(select(selectClientDashboardTrainerPackages));
  }

  public onClose() {
    this.dialogRef.close();
  }

  public onInput() {
    this.form.markAllAsTouched();
  }

  public onAdd() {
    this.loading$.next(true);
    this.store$.dispatch(
      new GetClientDashboardTrainerTestPackagesAction({
        params: this.form.value,
        onSuccess: () => this.onGetPackagesSuccess(),
        onFailure: err => this.onGetPackagesFailure(err),
      })
    );
  }

  private onGetPackagesSuccess() {
    this.loading$.next(false);
    this.onMainSelection$.next(false);
  }

  private onGetPackagesFailure(err: HttpErrorResponse) {
    this.messageService.add(err.error);
  }

  public onBack() {
    this.onMainSelection$.next(true);
  }
}
