/* eslint-disable */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {TrackingDataDto} from 'projects/dra-lib/src/lib/tracking/api/dto/tracking-data.dto';
import {Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {BatchTransitionType} from '../models/batch-transition-type';
import {Configuration} from '../models/configuration';
import {PieceInspectorRecentBatchesParams} from '../models/piece-inspector-recent-batches-params';
import {PieceSearchModel} from '../models/piece-search.model';
import {selectConfiguration} from '../store/workspace/workspace.selector';
import {BatchComparisonDetailParams} from './../models/batch-comparison-detail-params';
import {BatchInspectorSummaryParams} from './../models/batch-inspector-summary-params';
import {BatchFileDataDto} from './dto/batch-file-data.dto';
import {BatchInspectorCreateBatchParamsDto} from './dto/batch-inspector-create-batch-params.dto';
import {BatchInspectorSummaryParamsDto} from './dto/batch-inspector-summary-params.dto';
import {ClearanceInspectorCloseoutReturnDto} from './dto/clearance-inspector-closeout-return.dto';
import {ClearanceInspectorDataDto} from './dto/clearance-inspector-data.dto';
import {EmailFormDto} from './dto/email-form.dto';
import {FileSearchParamsDto} from './dto/file-search-params-dto';
import {GameCreationParamsDto} from './dto/game-creation-params.dto';
import {GatewayBatchDto} from './dto/gateway-batch.dto';
import {GatewayBatchesDto} from './dto/gateway-batches.dto';
import {GatewaySummaryParamsDto} from './dto/gateway-summary-params.dto';
import {PieceDetailParamsDto} from './dto/piece-detail-params.dto';
import {PieceDetailsPageDto} from './dto/piece-detail.dto';
import {PieceInspectorBatchSearchParamsDto} from './dto/piece-inspector-batch-search-params.dto';
import {PieceInspectorBatchDto} from './dto/piece-inspector-batch.dto';
import {PieceInspectorBatchesDto} from './dto/piece-inspector-batches.dto';
import {PieceInspectorNewBatchParamsDto} from './dto/piece-inspector-new-batch-params.dto';
import {PieceInspectorPieceParamsDto} from './dto/piece-inspector-piece-params.dto';
import {PieceInspectorScanPieceDto} from './dto/piece-inspector-scan-piece.dto';
import {PieceRemoveDto} from './dto/piece-remove';

@Injectable({
  providedIn: 'root',
})
export class BatchApiService {
  private configuration$: Observable<Configuration>;
  private url: string;

  constructor(private http: HttpClient, private store$: Store) {
    this.configuration$ = this.store$.pipe(select(selectConfiguration));
    this.configuration$
      .pipe(
        filter(config => !!config),
        take(1)
      )
      .subscribe(config => {
        this.url = `${config.userInformation.endpointUrlDra}/api`;
      });
  }

  public getAllBatches(params?: GatewaySummaryParamsDto): Observable<GatewayBatchesDto> {
    return this.http.get<GatewayBatchesDto>(`${this.url}/Batches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getBatches(batchNumber: string): Observable<GatewayBatchesDto> {
    return this.http.get<GatewayBatchesDto>(`${this.url}/Batches`, {
      params: JSON.parse(JSON.stringify({batchNumber})),
    });
  }

  public getSingleBatch(batchNumber: string): Observable<GatewayBatchDto> {
    return this.http.get<GatewayBatchDto>(`${this.url}/Batch/${batchNumber}`);
  }

  public getGatewayPieceDetails(companyId: number, params: PieceDetailParamsDto): Observable<PieceDetailsPageDto> {
    return this.http.get<PieceDetailsPageDto>(`${this.url}/BatchPieces`, {
      params: JSON.parse(JSON.stringify({...params, companyId})),
    });
  }

  public updateBatchStatus(batchNumbers: string[], transitionType: BatchTransitionType): Observable<any> {
    return this.http.put(`${this.url}/BatchStatus`, {
      BatchNumbers: batchNumbers,
      TransitionType: transitionType,
    });
  }

  public updateBatchPieces(batchNumber: string, pieceIds: number[]): Observable<any> {
    return this.http.put(`${this.url}/Batch`, {
      BatchNumber: batchNumber,
      Pieces: pieceIds.map(pieceID => ({PieceID: pieceID})),
    });
  }

  public createBatch(companyId: string, shipperId: string, pieceIds: {PieceID: number; Pin: string}[] = null) {
    return this.http.post(`${this.url}/Batch`, {
      BatchType: 1005,
      CompanyID: companyId,
      Origin: shipperId,
      Pieces: pieceIds,
    });
  }

  public addPiecesToBatch(batchNumber: string, pieceIds: number[]): Observable<any> {
    return this.http.post(`${this.url}/Batch/${batchNumber}`, pieceIds);
  }

  public removePiecesFromBatch(batchNumber: string, pieceIds: number[]): Observable<any> {
    return this.http.request('DELETE', `${this.url}/Batch/${batchNumber}`, {body: pieceIds});
  }

  public removePiecesFromMultipleBatches(piecesDto: PieceRemoveDto[]): Observable<any> {
    return this.http.request('DELETE', `${this.url}/Batches`, {body: piecesDto});
  }

  public searchPieceIds(companyId: string, shipperId: string, query: string): Observable<PieceSearchModel[]> {
    return this.http.get<PieceSearchModel[]>(`${this.url}/PieceSearch`, {
      params: {
        CompanyID: companyId,
        Origin: shipperId,
        maxResults: '10',
        pinSearch: query,
      },
    });
  }

  public getFiles(params?: FileSearchParamsDto): Observable<BatchFileDataDto> {
    return this.http.get<BatchFileDataDto>(`${this.url}/Documents`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public downloadFile(url?: string) {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  public downloadMultipleFiles(ids: string): Observable<any> {
    return this.http.get(`${this.url}/DocumentTransfer`, {
      params: {documentIDs: ids},
      responseType: 'blob',
      observe: 'response',
    });
  }

  public shareFiles(params: EmailFormDto): Observable<any> {
    return this.http.post(`${this.url}/DocumentEmail`, params);
  }

  public getAllBatchInspectorBatches(params: BatchInspectorSummaryParams): Observable<any> {
    return this.http.get<BatchInspectorSummaryParamsDto>(`${this.url}/BatchInspectR`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public createBatchInspectorBatch(params: BatchInspectorCreateBatchParamsDto): Observable<any> {
    return this.http.post<BatchInspectorCreateBatchParamsDto>(`${this.url}/Batches`, {
      ...params,
    });
  }

  public getComparisonBatches(batchNumbers: string): Observable<any> {
    return this.http.get<string>(`${this.url}/VennDiagram`, {
      params: JSON.parse(JSON.stringify({batchNumbers})),
    });
  }

  public getComparisonBatchesDetail(params: BatchComparisonDetailParams): Observable<any> {
    return this.http.get<BatchComparisonDetailParams>(`${this.url}/VennDiagramDetails`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public createComparisonBatch(params: BatchInspectorCreateBatchParamsDto): Observable<any> {
    return this.http.post<BatchInspectorCreateBatchParamsDto>(`${this.url}/VennDiagramDetails`, {
      ...params,
    });
  }

  public createComparisonDetailBatch(params: BatchInspectorCreateBatchParamsDto) {
    return this.http.post(`${this.url}/Batch`, {
      ...params,
    });
  }

  public downloadComparisonFile() {
    return this.http.get(`${this.url}/VennDiagramDownload`, {
      responseType: 'blob',
    });
  }

  public downloadComparisonBatchesDetail(params: BatchComparisonDetailParams) {
    return this.http.get(`${this.url}/VennDiagramDownload`, {
      params: JSON.parse(JSON.stringify({...params})),
      responseType: 'blob',
    });
  }

  public getPieceInspectorBatches(params?: PieceInspectorBatchSearchParamsDto): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.url}/ScanBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceInspectorRecentBatches(
    params: PieceInspectorRecentBatchesParams
  ): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.url}/ScanBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceInspectorBatchFromScan(barCode: string, facilityCode: string): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.url}/ScanBatches`, {
      params: {
        barCode,
        facilityCode,
      },
    });
  }

  public getPieceInspectorBatch(batchId: string): Observable<PieceInspectorBatchDto> {
    return this.http.get<PieceInspectorBatchDto>(`${this.url}/ScanBatch/${batchId}`);
  }

  public scanPieceInspectorPiece(params: PieceInspectorPieceParamsDto): Observable<PieceInspectorScanPieceDto> {
    return this.http.post<PieceInspectorScanPieceDto>(`${this.url}/ScanPiece`, {
      ...params,
    });
  }

  public pieceInspectorNewBatch(params: PieceInspectorNewBatchParamsDto): Observable<PieceInspectorBatchDto> {
    return this.http.post<PieceInspectorBatchDto>(`${this.url}/ScanBatch`, {
      ...params,
    });
  }

  public getPieceInspectorCloseoutBatches(
    params?: PieceInspectorBatchSearchParamsDto
  ): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.url}/ScanBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getClearanceInspectorPieces(pieceIds: string[]): Observable<ClearanceInspectorDataDto> {
    return this.http.post<ClearanceInspectorDataDto>(`${this.url}/Clearance`, {
      PinList: pieceIds,
    });
  }

  public downloadClearnaceInspectorSpreadsheet(pieceIds: string[]): Observable<any> {
    const payload = {PinList: pieceIds};
    return this.http.post(`${this.url}/ClearanceDownload`, payload, {
      responseType: 'blob',
      // observe: 'response'
    });
  }

  public clearanceInspectorCloseout(pieceIds: string[]): Observable<ClearanceInspectorCloseoutReturnDto> {
    return this.http.post<ClearanceInspectorCloseoutReturnDto>(`${this.url}/Clearance`, {
      Action: 'Closeout',
      PinList: pieceIds,
    });
  }

  //GAME

  public getPieceInspectorRecentGames(): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.url}/GameBatches?showRecent=true`);
  }

  public getPieceInspectorGameTemplates(
    params?: PieceInspectorBatchSearchParamsDto
  ): Observable<PieceInspectorBatchesDto> {
    return this.http.get<PieceInspectorBatchesDto>(`${this.url}/GameBatches`, {
      params: JSON.parse(JSON.stringify({...params})),
    });
  }

  public getPieceInspectorGameDetails(batchId: string): Observable<PieceInspectorBatchDto> {
    return this.http.get<PieceInspectorBatchDto>(`${this.url}/GameBatch/${batchId}`);
  }

  public createGameFromTemplate(params: GameCreationParamsDto): Observable<PieceInspectorBatchDto> {
    return this.http.post<PieceInspectorBatchDto>(`${this.url}/GameBatch`, {
      ...params,
    });
  }

  public updateGame(status: string, batchNumbers: string[]) {
    return this.http.put(`${this.url}/GameStatus`, {
      TransitionType: status[0].toUpperCase() + status.slice(1),
      BatchNumbers: batchNumbers,
    });
  }

  public scanPieceInspectorGamePiece(params: PieceInspectorPieceParamsDto): Observable<PieceInspectorScanPieceDto> {
    return this.http.post<PieceInspectorScanPieceDto>(`${this.url}/GamePiece`, {
      ...params,
    });
  }

  public getAuthTrackingData(searchValue: string): Observable<TrackingDataDto> {
    return this.http.post<TrackingDataDto>(`${this.url}/TrackingAuth`, {TrackingNumber: searchValue});
  }
}
