import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {convertPieceDtoToModel} from '../api/converter/convert-piece-dto-to-model';
import {TrackingApiService} from '../api/tracking-api.service';
import {GetPiecesAction, GetPiecesSuccessAction, TrackingActionType} from './tracking.action';

@Injectable()
export class TrackingEffects {
  public getTrackingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPiecesAction>(TrackingActionType.GET_PIECES),
      mergeMap(action => {
        const {searchValue} = action.payload;

        return this.trackingApiService.getTrackingData(searchValue).pipe(
          map(data => data?.Pieces?.map(dto => convertPieceDtoToModel(dto)) || []),
          map(pieces => new GetPiecesSuccessAction({pieces, searchValue}))
        );
      })
    )
  );

  constructor(private actions$: Actions, private trackingApiService: TrackingApiService) {}
}
