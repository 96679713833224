import {ECarrierActions, CarrierActions} from './carrier.actions';
import {CarrierState, initialCarrierState} from './carrier.state';

export function carrierReducers(state = initialCarrierState, action: CarrierActions): CarrierState {
  switch (action.type) {
    case ECarrierActions.GetCarriersSuccess: {
      return {
        ...state,
        carriers: action.payload,
      };
    }
    case ECarrierActions.GetCarrierSuccess: {
      return {
        ...state,
        activeCarrier: action.payload,
      };
    }
    default:
      return state;
  }
}
