import {Action} from '@ngrx/store';
import {Carrier} from 'src/app/core/models';

export enum ECarrierActions {
  GetCarriers = '[Carrier] Get Carriers',
  GetCarriersSuccess = '[Carrier] Get Carriers Success',
  GetCarrier = '[Carrier] Get Carrier',
  GetCarrierSuccess = '[Carrier] Get Carrier Success',
}

export class GetCarriers implements Action {
  public readonly type = ECarrierActions.GetCarriers;
}

export class GetCarriersSuccess implements Action {
  public readonly type = ECarrierActions.GetCarriersSuccess;
  constructor(public payload: Carrier[]) {}
}

export class GetCarrier implements Action {
  public readonly type = ECarrierActions.GetCarrier;
}

export class GetCarrierSuccess implements Action {
  public readonly type = ECarrierActions.GetCarrierSuccess;
  constructor(public payload: Carrier) {}
}

export type CarrierActions = GetCarriers | GetCarriersSuccess | GetCarrier | GetCarrierSuccess;
