import {BatchSummary} from '../../models/batch-summary';
import {PieceDetail} from '../../models/piece-detail';

export interface BatchTrackingState {
  piecesMap: Record<string, PieceDetail[]>;
  piecesCount: number;
  summariesMap: Record<string, BatchSummary[]>;
  detailDrawerStatus: boolean;
}

export const initialBatchTrackingState: BatchTrackingState = {
  piecesMap: {},
  piecesCount: 0,
  summariesMap: {},
  detailDrawerStatus: false,
};
