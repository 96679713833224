import {ClientDashboardBatch} from '../../models/client-dashboard-batch';
import {PieceDetail} from '../../models/piece-detail';

export interface ClientDashboardState {
  recentBatchesMap: Record<string, ClientDashboardBatch[]>;
  recentBatchesCount: number;
  batchMap: Record<string, ClientDashboardBatch>;
  piecesMap: Record<string, PieceDetail[]>;
  piecesCount: number;
  associatedBatchPiecesMap: Record<string, PieceDetail[]>;
  associatedBatchPiecesCount: number;
  createdBatch: ClientDashboardBatch;
  trainerPackages: string[];
}

export const initialClientDashboardState: ClientDashboardState = {
  recentBatchesMap: {},
  recentBatchesCount: null,
  batchMap: {},
  piecesMap: {},
  piecesCount: null,
  associatedBatchPiecesMap: {},
  associatedBatchPiecesCount: null,
  createdBatch: null,
  trainerPackages: null,
};
