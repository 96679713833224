import {ClientDashboardParams} from '../../models/client-dashboard-summary-params';
import {ClientDashboardParamsDto} from '../dto/client-dashboard-params.dto';

export function convertClientDashboardParamsModelToDto(params: ClientDashboardParams): ClientDashboardParamsDto {
  return {
    pageNumber: params.page,
    rowsPerPage: params.pageSize,
    sortByFields: createSortByFields(params.sortColumns, params.sortDirections),
    search: encodeURIComponent(params.search),
    batchID: params.batchId,
    clientBatchPieces: params.virtualBatchType,
    showRecent: params.showRecent,
    showClosed: params.showClosed,
  };
}

function createSortByFields(sortColumns: string[], sortDirections: string[]): string {
  return sortColumns?.map((column, index) => `${convertColumnModelToDto(column)} ${sortDirections[index]}`).join(',');
}

function convertColumnModelToDto(column: string): string {
  switch (column) {
    case 'batchNumber':
      return 'BatchNumber';
    case 'shipDate':
      return 'ShipDate';
    case 'origin':
      return 'Origin';
    case 'originLabel':
      return 'OriginLabel';
    case 'inductionLocation':
      return 'InductionLocation';
    case 'totalPieces':
      return 'TotalPieces';
    case 'percentClosed':
      return 'PercentClosed';

    case 'pin':
      return 'Pin';
    case 'orderNumber':
      return 'OrderNumber';
    case 'postalCode':
      return 'PostalCode';
    case 'weight':
      return 'Weight';
    case 'statusTypeDisplay':
      return 'StatusTypeDisplay';
  }
}
