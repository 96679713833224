import {hashObject} from '../../../shared/utils/hash-object';
import {BatchTrackingAction, BatchTrackingActionType} from './batch-tracking.actions';
import {BatchTrackingState, initialBatchTrackingState} from './batch-tracking.state';

export function batchTrackingReducer(
  state = initialBatchTrackingState,
  action: BatchTrackingAction
): BatchTrackingState {
  switch (action.type) {
    case BatchTrackingActionType.GET_PIECE_DETAILS_SUCCESS: {
      const piecesMap = {
        ...state.piecesMap,
        [hashObject(action.payload.params)]: action.payload.pieces,
      };
      return {...state, piecesMap, piecesCount: action.payload.piecesCount};
    }
    case BatchTrackingActionType.GET_SUMMARY_SUCCESS: {
      const summariesMap = {
        ...state.summariesMap,
        [hashObject(action.payload.params)]: action.payload.summaries,
      };
      return {...state, summariesMap};
    }
    case BatchTrackingActionType.UPDATE_DETAIL_DRAWER_STATUS:
      return {...state, detailDrawerStatus: action.payload.open};
    default:
      return state;
  }
}
