import {InjectionToken} from '@angular/core';
import {EnvironmentName} from './environment-name';

export interface Environment {
  apiUrl: string;
  envName: EnvironmentName;
  sentryDsn: string;
}

export const ENVIRONMENT = new InjectionToken<Environment>('environment');
