/* eslint-disable */
import {AuthToken} from '../../../core/models/auth-token';

const AUTH_TOKEN_KEY = 'authToken';

export function readAuthTokenFromLocalStorage(): AuthToken {
  const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);
  return authToken ? JSON.parse(authToken) : null;
}

export function writeAuthTokenToLocalStorage(authToken: AuthToken) {
  window.localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(authToken));
}

export function removeAuthTokenFromLocalStorage() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}
