<nav>
  <div class="ui-inputgroup">
    <span class="ui-inputgroup-addon">
      <img src="assets/search-v-1-grey.svg" alt="search icon" />
    </span>
    <p-autoComplete
      [(ngModel)]="brand"
      [suggestions]="filteredBrands"
      (completeMethod)="filterBrands($event)"
      [size]="30"
      [minLength]="1"
      placeholder="Search for facilities, carriers, legs, or clients"
      inputStyleClass="search"
    >
      <ng-template let-brand pTemplate="item">
        <div class="ui-helper-clearfix" style="border-bottom: 1px solid #d5d5d5">
          <img src="assets/purolator-logo-01.svg" style="width: 32px; display: inline-block; margin: 5px 0 2px 5px" />
          <div style="font-size: 18px; float: right; margin: 10px 10px 0 0">{{ brand }}</div>
        </div>
      </ng-template>
    </p-autoComplete>
  </div>
  <ul>
    <li>
      <a (click)="openQuickAccessMenu()" [ngClass]="{active: !drawerIsCollapsed}">
        <img src="assets/quick-access-v-1.svg" alt="quick access menu icon" />
      </a>
    </li>
    <li>
      <a>
        <img src="assets/help-v-1.svg" alt="help icon" />
      </a>
    </li>
    <li>
      <a>
        <img src="assets/settings-v-1.svg" alt="settings icon" />
      </a>
    </li>
    <!-- TODO: User avatar goes here -->
    <!-- <li>
      <a>
        <img src="assets/new-v1.svg" alt="icon">
      </a>
    </li> -->
  </ul>
</nav>
