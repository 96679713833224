import {BatchFileData} from '../../models/batch-file-data';
import {BatchFileDataDto} from '../dto/batch-file-data.dto';
import {convertBatchFileDtoToModel} from './convert-batch-file-dto-to-model';

export function convertBatchFilesDtoToModel(dto: BatchFileDataDto): BatchFileData {
  return {
    files: dto.Documents.map(document => convertBatchFileDtoToModel(document)),
    pageCount: dto.PageCount,
    pageNumber: 0,
    recordCount: dto.RecordCount,
    recordsPerPage: dto.RecordsPerPage,
  };
}
