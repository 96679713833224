import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {convertPieceDtoToModel} from 'projects/dra-lib/src/lib/tracking/api/converter/convert-piece-dto-to-model';
import {map, mergeMap} from 'rxjs/operators';
import {BatchApiService} from '../../api/batch-api.service';
import {AuthTrackingActionType, GetAuthPiecesAction, GetAuthPiecesSuccessAction} from './auth-tracking.action';

@Injectable()
export class AuthTrackingEffects {
  public getTrackingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAuthPiecesAction>(AuthTrackingActionType.GET_PIECES),
      mergeMap(action => {
        const {searchValue} = action.payload;

        return this.batchApiService.getAuthTrackingData(searchValue).pipe(
          map(data => data?.Pieces?.map(dto => convertPieceDtoToModel(dto)) || []),
          map(pieces => new GetAuthPiecesSuccessAction({pieces, searchValue}))
        );
      })
    )
  );

  constructor(private actions$: Actions, private batchApiService: BatchApiService) {}
}
