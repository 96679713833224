<button *ngIf="!loading; else loadingButton" mat-flat-button [disabled]="disabled" [class.disabled]="disabled">
  <ng-content></ng-content>
</button>
<ng-template #loadingButton>
  <button mat-flat-button class="loading-button" color="primary">
    <div class="dra-white-spinner">
      <mat-spinner class="white-color" [diameter]="24"></mat-spinner>
    </div>
  </button>
</ng-template>
