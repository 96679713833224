import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {BatchSummary} from '../../models/batch-summary';
import {BatchSummaryParams} from '../../models/batch-summary-params';
import {PieceDetail} from '../../models/piece-detail';
import {PieceDetailParams} from '../../models/piece-detail-params';

export enum BatchTrackingActionType {
  GET_SUMMARY = '[Batch Tracking] Get Summary',
  GET_SUMMARY_SUCCESS = '[Batch Tracking] Get Summary :: Success',

  GET_PIECE_DETAILS = '[Batch Tracking] Get Piece Details',
  GET_PIECE_DETAILS_SUCCESS = '[Batch Tracking] Get Piece Details :: Success',

  GET_PIECE_DETAILS_EXCEL_FILE = '[Batch Tracking] Get Piece Details Excel File',
  GET_PIECE_DETAILS_CSV_FILE = '[Batch Tracking] Get Piece Details CSV File',

  UPDATE_DETAIL_DRAWER_STATUS = '[Batch Tracking] Update Detail Drawer Status',
}

export class GetBatchSummaryAction implements Action {
  public readonly type = BatchTrackingActionType.GET_SUMMARY;

  constructor(
    public payload: {params: BatchSummaryParams; onSuccess?: () => void; onFailure?: (error: HttpErrorResponse) => void}
  ) {}
}

export class GetBatchSummarySuccessAction implements Action {
  public readonly type = BatchTrackingActionType.GET_SUMMARY_SUCCESS;

  constructor(public payload: {params: BatchSummaryParams; summaries: BatchSummary[]}) {}
}

export class GetPieceDetailsAction implements Action {
  public readonly type = BatchTrackingActionType.GET_PIECE_DETAILS;

  constructor(public payload: {params: PieceDetailParams}) {}
}

export class GetPieceDetailsSuccessAction implements Action {
  public readonly type = BatchTrackingActionType.GET_PIECE_DETAILS_SUCCESS;

  constructor(public payload: {params: PieceDetailParams; pieces: PieceDetail[]; piecesCount: number}) {}
}

export class GetPieceDetailsExcelFileAction implements Action {
  public readonly type = BatchTrackingActionType.GET_PIECE_DETAILS_EXCEL_FILE;

  constructor(public payload: {params: PieceDetailParams}) {}
}

export class GetPieceDetailsCSVFileAction implements Action {
  public readonly type = BatchTrackingActionType.GET_PIECE_DETAILS_CSV_FILE;

  constructor(public payload: {params: PieceDetailParams}) {}
}

export class UpdateDetailDrawerStatusAction implements Action {
  public readonly type = BatchTrackingActionType.UPDATE_DETAIL_DRAWER_STATUS;

  constructor(public payload: {open: boolean}) {}
}

export type BatchTrackingAction =
  | GetBatchSummaryAction
  | GetBatchSummarySuccessAction
  | GetPieceDetailsAction
  | GetPieceDetailsSuccessAction
  | UpdateDetailDrawerStatusAction;
