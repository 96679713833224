import {Injectable} from '@angular/core';
import {RouterStateSnapshot} from '@angular/router';
import {RouterStateSerializer} from '@ngrx/router-store';
import {CustomRouterState} from './router.state';
import {collectCustomRouterState} from './utils/collect-custom-router-state';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<CustomRouterState> {
  public serialize(routerState: RouterStateSnapshot): CustomRouterState {
    return collectCustomRouterState(routerState.root, routerState.url);
  }
}
