import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ApplicationDto} from './dto/application.dto';
import {ConfigurationDto} from './dto/configuration.dto';
import {UserInfoDto} from './dto/user-info.dto';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationApiService {
  constructor(private http: HttpClient) {}

  public getAppSettings(applicationId: string): Observable<ApplicationDto> {
    return this.http.get<ApplicationDto>(`${environment.apiUrl}/AppSettings/${applicationId}`);
  }

  public getConfiguration(): Observable<ConfigurationDto> {
    return this.http.get<ConfigurationDto>(`${environment.apiUrl}/Configuration`);
  }

  public sendLogInfo(applicationGuid: string) {
    const payload = {AppGuid: applicationGuid};
    return this.http.post(`${environment.apiUrl}/Log`, payload);
  }

  public getUserInfo(): Observable<UserInfoDto> {
    return this.http.get<UserInfoDto>(`${environment.apiUrl}/UserInfo`);
  }

  public updateUserInfo(userDto: Partial<UserInfoDto>): Observable<UserInfoDto> {
    return this.http.put<UserInfoDto>(`${environment.apiUrl}/UserInfo`, userDto);
  }

  public resetTestData() {
    return this.http.post(`${environment.apiUrl}/ResetTestAccount`, {});
  }
}
