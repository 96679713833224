import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ClientDashboardApiService} from '../../api/client-dashboard-api.service';
import {convertClientDashboardBatchDtoToModel} from '../../api/converters/convert-client-dashboard-batch-dto-to-model';
import {convertClientDashboardErrorResponseDtoToModel} from '../../api/converters/convert-client-dashboard-error-response';
import {convertClientDashboardParamsModelToDto} from '../../api/converters/convert-client-dashboard-params-model-to-dto';
import {convertClientDashboardTrainerPackageParamsModelToDto} from '../../api/converters/convert-client-dashboard-trainer-package-params-model-to-dto';
import {convertNewContainerParamsModelToDto} from '../../api/converters/convert-new-container-params-model-to-dto';
import {convertPieceDetailDtoToModel} from '../../api/converters/convert-piece-detail-dto-to-model';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  ClientDashboardActionType,
  CloseoutByContainerAction,
  CloseoutByPiecesAction,
  CreateClientDashboardContainerAction,
  CreateClientDashboardContainerSuccessAction,
  GetClientDashboardBatchAction,
  GetClientDashboardBatchSuccessAction,
  GetClientDashboardPieceDetailsAction,
  GetClientDashboardPieceDetailsSuccessAction,
  GetClientDashboardRecentBatchesAction,
  GetClientDashboardRecentBatchesSuccessAction,
  GetClientDashboardTrainerTestPackagesAction,
  GetClientDashboardTrainerTestPackagesSuccessAction,
} from './client-dashboard.actions';

@Injectable()
export class ClientDashboardEffects {
  public getRecentBatches$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetClientDashboardRecentBatchesAction>(ClientDashboardActionType.GET_RECENT_BATCHES),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertClientDashboardParamsModelToDto(params);

        return this.clientDashboardApiService.getRecentBatches(paramsDto).pipe(
          map(dto => ({
            recentBatches: dto?.BatchList?.map(batch => convertClientDashboardBatchDtoToModel(batch)) || [],
            recentBatchesCount: dto?.RecordCount,
          })),
          mergeMap(({recentBatches, recentBatchesCount}) => [
            new GetClientDashboardRecentBatchesSuccessAction({recentBatches, recentBatchesCount, params}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetClientDashboardBatchAction>(ClientDashboardActionType.GET_BATCH),
      mergeMap(action => {
        const {batchId, onSuccess, onFailure} = action.payload;

        return this.clientDashboardApiService.getBatch(batchId).pipe(
          map(dto => convertClientDashboardBatchDtoToModel(dto)),
          mergeMap(batch => [new GetClientDashboardBatchSuccessAction({batch}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getPieceDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetClientDashboardPieceDetailsAction>(ClientDashboardActionType.GET_PIECE_DETAILS),
      mergeMap(action => {
        const {params, associatedBatch} = action.payload;
        const paramsDto = convertClientDashboardParamsModelToDto(params);

        return this.clientDashboardApiService.getPieceDetails(paramsDto).pipe(
          map(page => {
            const pieces = page.PieceList?.map(dto => convertPieceDetailDtoToModel(dto)) || [];
            const piecesCount = page.RecordCount;
            return new GetClientDashboardPieceDetailsSuccessAction({params, pieces, piecesCount, associatedBatch});
          }),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public closeoutByPieces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CloseoutByPiecesAction>(ClientDashboardActionType.CLOSEOUT_BY_PIECES),
      mergeMap(action => {
        const {appId, companyId, pinList, transitionType, origin, onSuccess, onFailure} = action.payload;

        return this.clientDashboardApiService.closeoutByPieces(appId, companyId, pinList, transitionType, origin).pipe(
          map(batch => convertClientDashboardBatchDtoToModel(batch)),
          mergeMap(createdBatch => [
            new CreateClientDashboardContainerSuccessAction({createdBatch}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error =>
            emitErrorActions(convertClientDashboardErrorResponseDtoToModel(error.error || error), onFailure)
          )
        );
      })
    )
  );

  public closeoutByContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CloseoutByContainerAction>(ClientDashboardActionType.CLOSEOUT_BY_CONTAINER),
      mergeMap(action => {
        const {batchNumber, transitionType, excludedPieceIds, onSuccess, onFailure} = action.payload;

        return this.clientDashboardApiService.closeoutByContainer(batchNumber, transitionType, excludedPieceIds).pipe(
          mergeMap(() => [...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createContainer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateClientDashboardContainerAction>(ClientDashboardActionType.CREATE_CONTAINER),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertNewContainerParamsModelToDto(params);

        return this.clientDashboardApiService.createContainer(paramsDto).pipe(
          map(batch => convertClientDashboardBatchDtoToModel(batch)),
          mergeMap(createdBatch => [
            new CreateClientDashboardContainerSuccessAction({createdBatch}),
            new GetClientDashboardRecentBatchesAction({params: {}}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(convertClientDashboardErrorResponseDtoToModel(error.error), onFailure))
        );
      })
    )
  );

  // ----------Trainer Mode----------
  public getTrainerPackages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetClientDashboardTrainerTestPackagesAction>(ClientDashboardActionType.GET_TRAINER_PACKAGES),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertClientDashboardTrainerPackageParamsModelToDto(params);

        return this.clientDashboardApiService.getTrainerPackages(paramsDto).pipe(
          mergeMap(packages => [
            new GetClientDashboardTrainerTestPackagesSuccessAction({packages}),
            new GetClientDashboardRecentBatchesAction({params: {}}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(private actions$: Actions, private clientDashboardApiService: ClientDashboardApiService) {}
}
