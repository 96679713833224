import {createSelector} from '@ngrx/store';
import {MemoizedSelector} from '@ngrx/store/src/selector';
import {hashObject} from '../../../shared/utils/hash-object';
import {decodeQueryParam} from '../../../shared/utils/query/query-param-encoding';
import {BatchSummaryParams} from '../../models/batch-summary-params';
import {PieceDetailParams} from '../../models/piece-detail-params';
import {AppState} from '../app.state';
import {selectRouterParam, selectRouterQueryParam} from '../router/router.selector';
import {selectAppSettings, selectCurrentCompany} from '../workspace/workspace.selector';
import {BatchTrackingState} from './batch-tracking.state';
import {ApplicationId} from '../../models/application';

export const selectBatchTrackingState = (state: AppState) => state.batchTracking;

export const selectBatchSummaryParams = createSelector(
  selectRouterQueryParam('query'),
  query => (query ? JSON.parse(decodeQueryParam(query) || '{}') : {}) as BatchSummaryParams
);

export const selectBatchStatusParam = createSelector(
  selectRouterParam('batchStatus'),
  batchStatus =>
    (batchStatus ? JSON.parse(decodeQueryParam(batchStatus) || '{}') : {}) as {clearable: boolean; statusType: number}
);

export const selectPieceDetailParams: MemoizedSelector<AppState, PieceDetailParams> = createSelector(
  selectBatchStatusParam,
  selectBatchSummaryParams,
  selectRouterQueryParam('page'),
  selectRouterQueryParam('pageSize'),
  selectRouterQueryParam('sort'),
  selectRouterQueryParam('sortDir'),
  (batchStatus, batchSummaryParams, page, pageSize, sort, sortDir) => {
    return {
      clearable: batchStatus.clearable,
      facilityCode: batchSummaryParams.facility,
      origin: batchSummaryParams.shipper,
      pieceType: batchSummaryParams.pieceType,
      statusType: batchStatus.statusType,
      page: page ? Number(page) : 1,
      pageSize: pageSize ? Number(pageSize) : 50,
      sortColumns: sort ? String(sort).split(',') : [],
      sortDirections: sortDir ? String(sortDir).split(',') : [],
      companyId: batchSummaryParams.companyId,
    };
  }
);

const selectBatchSummariesMap = createSelector(
  selectBatchTrackingState,
  (state: BatchTrackingState) => state.summariesMap
);
export const selectBatchSummaries = createSelector(
  selectBatchSummariesMap,
  selectBatchSummaryParams,
  (summariesMap, params) => summariesMap[hashObject(params)] || []
);

export const selectAllBatchTrackingBatchesLoading = createSelector(
  selectBatchSummariesMap,
  selectBatchSummaryParams,
  (summariesMap, params) => !summariesMap[hashObject(params)]
);

export const selectBatchTrackingFacilities = createSelector(
  selectCurrentCompany(ApplicationId.BatchTracking),
  company => company?.facilities || []
);

export const selectBatchTrackingOrigins = createSelector(
  selectCurrentCompany(ApplicationId.BatchTracking),
  company => company?.origins || []
);

export const selectBatchTrackingPieceTypes = createSelector(
  selectAppSettings(ApplicationId.BatchTracking),
  company => company?.pieceTypes || []
);

export const selectBatchTrackingFilters = createSelector(
  selectCurrentCompany(ApplicationId.BatchTracking),
  company => company?.filters || []
);

export const selectBatchTrackingFilterGroups = createSelector(
  selectCurrentCompany(ApplicationId.BatchTracking),
  company => company?.filterGroups || []
);

export const selectSelectedStatus = createSelector(selectRouterQueryParam('statusLabel'), (status: string) => status);

const selectPiecesMap = createSelector(selectBatchTrackingState, (state: BatchTrackingState) => state.piecesMap);
export const selectPieceDetails = createSelector(
  selectPiecesMap,
  selectPieceDetailParams,
  (piecesMap, params) => piecesMap[hashObject(params)]
);

export const selectBatchPiecesCount = createSelector(selectBatchTrackingState, state => state.piecesCount);

export const selectBatchTrackingDetailDrawerStatus = createSelector(
  selectBatchTrackingState,
  state => state.detailDrawerStatus
);
