import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-navbar-secondary',
  templateUrl: './navbar-secondary.component.html',
  styleUrls: ['./navbar-secondary.component.scss'],
})
export class NavbarSecondaryComponent {
  // TODO: Replace w/ real data
  // brands: string[] = ['Audi','BMW','Fiat','Ford','Honda','Jaguar','Mercedes','Renault','Volvo','VW'];
  brands: string[] = [];
  // eslint-disable-next-line
  filteredBrands: any[];
  brand: string;

  @Input() drawerIsCollapsed: boolean;
  @Output() quickAccessMenuToggle = new EventEmitter<boolean>();

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.brands.length; i++) {
      const brand = this.brands[i];
      if (brand.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
        this.filteredBrands.push(brand);
      }
    }
  }

  openQuickAccessMenu(): void {
    this.quickAccessMenuToggle.emit(true);
  }
}
