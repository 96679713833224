import {CSVDetail} from '../../models/csv-detail';
import {PieceDetailDto} from '../dto/piece-detail.dto';

export function convertPieceDetailDtoToCSV(dto: PieceDetailDto): CSVDetail {
  return {
    'Shipment #': dto.OrderNumber,
    PIN: dto.Pin,
    'Piece Type': dto.PieceType === 1 ? 'Package' : 'Pallet',
    'Pro #': dto.ProNumber ? dto.ProNumber : '',
    'Date Shipped': dto.ShipDate ? new Date(dto.ShipDate).toDateString() : '',
    'Last Scanned': dto.StatusDate ? new Date(dto.StatusDate).toDateString() : '',
    Weight: dto.Weight,
    'Piece Count': dto.PieceCount,
  };
}
