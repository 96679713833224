import {Lesson} from 'src/app/core/models/lesson';
import {TrainingModeStatus} from './training-mode-status';

function createTestPackages(count: number): string[] {
  const arr = [];
  for (let i = 0; i < count; i++) {
    arr.push(`TST00000000${i}`);
  }
  return arr;
}

export const MOCK_LESSONS: Lesson[] = [
  {
    lessonNumber: '1-1',
    page: 'view containers',
    moduleName: 'View New Master Container',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
    status: TrainingModeStatus.Finished,
    testPackages: createTestPackages(20),
  },
  {
    lessonNumber: '1-2',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance File',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
    status: TrainingModeStatus.Finished,
  },
  {
    lessonNumber: '1-3',
    page: 'view containers',
    moduleName: 'View Existing Master Container that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
    status: TrainingModeStatus.Finished,
  },
  {
    lessonNumber: '1-4',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
    status: TrainingModeStatus.Started,
  },
  {
    lessonNumber: '1-5',
    page: 'view containers',
    moduleName: 'View New Master Container',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
    status: TrainingModeStatus.Started,
  },
  {
    lessonNumber: '1-6',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance File',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '1-7',
    page: 'view containers',
    moduleName: 'View Existing Master Container that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '1-8',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '1-9',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance File',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '1-10',
    page: 'view containers',
    moduleName: 'View Existing Master Container that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '1-12',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '1-13',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '1-14',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '1-15',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '2-1',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '2-2',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
  {
    lessonNumber: '2-3',
    page: 'view containers',
    moduleName: 'View Existing Master Container with a Clearance Batch that has been completely closed out.',
    tagline: 'See what a Master Container looks like when it is first created.',
    instructions: 'PLease click on the tile to see the Batches related to this Master Container...',
  },
];
