<nav>
  <img src="assets/purolator-logo-01.svg" class="purolator-logo" routerLink="/" />
  <ul>
    <li *ngFor="let m of menuItems">
      <a
        [routerLink]="m.address"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}"
        (mouseover)="m.iconSrc = m.activeIcon"
        (mouseout)="m.iconSrc = m.address === activeRoute ? m.activeIcon : m.inactiveIcon"
        matTooltip="{{ m.tooltip }}"
        matTooltipPosition="after"
      >
        <img [src]="m.iconSrc" class="icon" />
        <!-- Preload hover images to avoid lag -->
        <img [src]="m.activeIcon" style="display: none" />
      </a>
    </li>
  </ul>
</nav>
