import {hashObject} from '../../../shared/utils/hash-object';
import {GatewayAction, GatewayActionType} from './gateway.actions';
import {GatewayState, initialGatewayState} from './gateway.state';

export function gatewayReducer(state = initialGatewayState, action: GatewayAction): GatewayState {
  switch (action.type) {
    case GatewayActionType.GET_ALL_BATCHES_SUCCESS: {
      const queryBatchesMap = {
        ...state.queryBatchesMap,
        [hashObject(action.payload.params)]: action.payload.batches,
      };
      return {...state, queryBatchesMap, batchesCount: action.payload.batchesCount};
    }
    case GatewayActionType.GET_BATCHES_SUCCESS:
      return {...state, detailBatches: action.payload.batches};
    case GatewayActionType.GET_GATEWAY_PIECE_DETAILS_SUCCESS: {
      const piecesMap = {
        ...state.piecesMap,
        [hashObject(action.payload.params)]: action.payload.pieces,
      };
      return {...state, piecesMap, piecesCount: action.payload.piecesCount};
    }
    case GatewayActionType.GET_FILES_SUCCESS:
      return {...state, fileData: action.payload.fileData};
    default:
      return state;
  }
}
