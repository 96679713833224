import {BatchInspectorCreateBatchParamsDto} from './../dto/batch-inspector-create-batch-params.dto';
import {BatchInspectorCreateBatchParams} from './../../models/batch-inspector-create-batch-params';

export function convertBatchInspectorCreateBatchParamsModelToDto(
  params: BatchInspectorCreateBatchParams
): BatchInspectorCreateBatchParamsDto {
  return {
    BatchNumber: params.batchNumber || undefined,
    BatchType: params.batchType,
    Location: params.location || null,
    DepotCode: params.depotCode || null,
    BatchNumbersAll: params.batchNumbersAll || undefined,
    BatchNumbersIntersect: params.batchNumbersIntersect || undefined,
    SelectAll: params.selectAll || undefined,
    Pieces: params.pieces?.map(piece => convertPieceToPieceDto(piece)) || undefined,
  };
}

function convertPieceToPieceDto(piece: {pieceId: number; pin: string}): {PieceID: number; Pin: string} {
  return {
    PieceID: piece.pieceId,
    Pin: piece.pin,
  };
}
