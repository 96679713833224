import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'configuration-loading-error',
  templateUrl: './configuration-loading-error.component.html',
  styleUrls: ['./configuration-loading-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationLoadingErrorComponent {
  public onRefreshPage() {
    window.location.reload();
  }
}
