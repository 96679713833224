import {Component, OnDestroy, OnInit} from '@angular/core';
import {IconService} from './core/page/icon.service';
import {Store} from '@ngrx/store';
import {AppState} from './core/store/app.state';
import {environment} from '../environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'dra-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  constructor(private iconService: IconService, private store: Store) {}

  public ngOnInit() {
    this.iconService.loadIcons();
    this.subscription = this.store
      .select((state: AppState) => state.auth.token)
      .subscribe(authToken => {
        if (authToken) {
          environment.apiUrl = `${authToken.endpointUrl}/api`;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
