import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Lesson} from 'src/app/core/models/lesson';

@Component({
  selector: 'training-mode-drawer-collapsed',
  templateUrl: './training-mode-drawer-collapsed.component.html',
  styleUrls: ['./training-mode-drawer-collapsed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingModeDrawerCollapsedComponent {
  @Input()
  public lessons: Lesson[];

  @Input()
  public activeLessonNumber: string;

  @Output()
  public toggle = new EventEmitter();

  public onToggle() {
    this.toggle.emit();
  }
}
