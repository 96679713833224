import {createSelector} from '@ngrx/store';
import {initialTrackingState, TrackingState} from './tracking.state';

export const selectTrackingState = (state: any) => (state.tracking || initialTrackingState) as TrackingState;

export const selectTrackingPiecesMap = createSelector(selectTrackingState, (state: TrackingState) => state.piecesMap);

export const selectTrackingPiecesBySearchValue = (searchValue: string) =>
  createSelector(selectTrackingPiecesMap, piecesMap => piecesMap[searchValue]);

export const selectTrackingPieceByTrackingNumber = (searchValue: string, trackingNumber: string) =>
  createSelector(selectTrackingPiecesBySearchValue(searchValue), pieces =>
    pieces?.find(piece => piece.trackingNumber === trackingNumber)
  );

export const selectTrackingLoaded = createSelector(selectTrackingState, state => state.loaded);
