<div class="modal-bg" (click)="checkForClickOut($event)">
  <div class="modal-content">
    <header>
      <h2>{{ title }}</h2>
      <img src="assets/close-v-1.svg" alt="close" (click)="closeModal.emit()" [style.cursor]="'pointer'" />
    </header>

    <ng-content select="modal-body"></ng-content>
    <ng-content select="modal-footer"></ng-content>
  </div>
</div>
