<header [class.training-mode]="trainingMode">
  <div class="mobile-tracking-search-bar">
    <div class="left-container">
      <img src="assets/purolator-logo-01.svg" alt="Purolator" class="purolator-logo" />
      <span class="page-title">{{ pageTitle }}</span>
    </div>

    <div class="right-container">
      <button
        *ngIf="authenticated && !showLogout"
        mat-icon-button
        (click)="onMenuClick()"
        aria-label="Menu"
        class="menu-button"
      >
        <div class="inner-button-container">
          <img src="assets/icons/tracking-icons/mobile-menu.svg" alt="menu" class="menu-icon" />
        </div>
      </button>

      <button *ngIf="trainingMode" mat-icon-button (click)="onToggle()" class="training-button">
        <img src="assets/icons/plus-square.svg" />
      </button>

      <div *ngIf="authenticated && showLogout">
        <button mat-flat-button (click)="onLogoutClick()" class="logout-button">
          Logout
          <img src="assets/icons/tracking-icons/logout-white.svg" class="logout-icon" />
        </button>
      </div>

      <a *ngIf="!authenticated" [routerLink]="['/login']" class="login-link">
        Login
        <img src="assets/icons/tracking-icons/login.svg" class="login-icon" />
      </a>
    </div>
  </div>
</header>
