import {readAuthTokenFromLocalStorage} from '../../../shared/utils/auth/auth-token.utils';
import {AuthToken} from '../../models/auth-token';

export interface AuthState {
  token: AuthToken;
}

export const initialAuthState: AuthState = {
  token: readAuthTokenFromLocalStorage(),
};
