import {PieceDetailParams} from '../../models/piece-detail-params';
import {PieceDetailParamsDto} from '../dto/piece-detail-params.dto';

export function convertPieceDetailParamsModelToDto(params: PieceDetailParams): PieceDetailParamsDto {
  return {
    facilityCode: params.facilityCode || 'null',
    isClearable: params.clearable !== null ? Number(params.clearable) : null,
    origin: params.origin,
    pageNumber: params.page,
    pieceType: params.pieceType,
    rowsPerPage: params.pageSize,
    sortByFields: createSortByFields(params.sortColumns, params.sortDirections),
    statusType: params.statusType,
    batchNumber: params.batchNumber || 'null',
    companyId: params.companyId,
    search: params.search,
    batchID: params.batchId,
  };
}

function createSortByFields(sortColumns: string[], sortDirections: string[]): string {
  return sortColumns
    .map((column, index) => `${convertPieceDetailColumnModelToDto(column)} ${sortDirections[index]}`)
    .join(',');
}

function convertPieceDetailColumnModelToDto(column: string): string {
  switch (column) {
    case 'id':
      return 'PieceID';
    case 'depotCode':
      return 'DepotCode';
    case 'orderNumber':
      return 'OrderNumber';
    case 'origin':
      return 'Origin';
    case 'originLabel':
      return 'OriginLabel';
    case 'pieceCount':
      return 'PieceCount';
    case 'pieceType':
      return 'PieceType';
    case 'pieceTypeLabel':
      return 'PieceTypeLabel';
    case 'pin':
      return 'Pin';
    case 'proNumber':
      return 'ProNumber';
    case 'shipDate':
      return 'ShipDate';
    case 'statusDate':
      return 'StatusDate';
    case 'weight':
      return 'Weight';
    case 'destinationPostalCode':
      return 'DestinationPostalCode';
    case 'statusTypeLabel':
      return 'StatusTypeLabel';
  }
}
