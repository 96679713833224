import {hashObject} from 'src/app/shared/utils/hash-object';
import {ClientDashboardAction, ClientDashboardActionType} from './client-dashboard.actions';
import {ClientDashboardState, initialClientDashboardState} from './client-dashboard.state';

export function clientDashboardReducer(
  state = initialClientDashboardState,
  action: ClientDashboardAction
): ClientDashboardState {
  switch (action.type) {
    case ClientDashboardActionType.GET_RECENT_BATCHES_SUCCESS: {
      const recentBatchesMap = {
        ...state.recentBatchesMap,
        [hashObject(action.payload.params)]: action.payload.recentBatches,
      };
      return {...state, recentBatchesMap, recentBatchesCount: action.payload.recentBatchesCount};
    }
    case ClientDashboardActionType.GET_BATCH_SUCCESS: {
      const batchMap = {
        ...state.batchMap,
        [hashObject({batchId: String(action.payload.batch.batchId)})]: action.payload.batch,
      };
      return {...state, batchMap};
    }
    case ClientDashboardActionType.GET_PIECE_DETAILS_SUCCESS: {
      if (action.payload.associatedBatch) {
        const associatedBatchPiecesMap = {
          ...state.associatedBatchPiecesMap,
          [hashObject(action.payload.params)]: action.payload.pieces,
        };
        return {...state, associatedBatchPiecesMap, associatedBatchPiecesCount: action.payload.piecesCount};
      } else {
        const piecesMap = {
          ...state.piecesMap,
          [hashObject(action.payload.params)]: action.payload.pieces,
        };
        return {...state, piecesMap, piecesCount: action.payload.piecesCount};
      }
    }
    case ClientDashboardActionType.CREATE_CONTAINER_SUCCESS:
      return {...state, createdBatch: action.payload.createdBatch};
    case ClientDashboardActionType.GET_TRAINER_PACKAGES_SUCCESS:
      return {...state, trainerPackages: action.payload.packages};
    default:
      return state;
  }
}
