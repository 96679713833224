import {Pipe, PipeTransform} from '@angular/core';
import {getTimeZoneAbbreviation} from '../utils/date/get-time-zone-abbreviation';

@Pipe({
  name: 'timeZoneAbbreviation',
})
export class TimeZoneAbbreviationPipe implements PipeTransform {
  public transform(timeZone: string): string {
    return timeZone && getTimeZoneAbbreviation(timeZone);
  }
}
