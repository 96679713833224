/* eslint-disable */
import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {BatchComparison} from '../../models/batch-comparison';
import {BatchComparisonDetailParams} from './../../models/batch-comparison-detail-params';
import {BatchInspectorCreateBatchParams} from './../../models/batch-inspector-create-batch-params';
import {BatchInspectorSummaryParams} from './../../models/batch-inspector-summary-params';
import {BatchManagerBatch} from './../../models/batch-manager-batch';
import {PieceDetail} from './../../models/piece-detail';

export enum BatchInspectorActionType {
  GET_BATCHES = '[Batch Inpsector] Get Batches',
  GET_BATCHES_SUCCESS = '[Batch Inspector] Get Batches :: Success',

  GET_COMPARISON_BATCHES = '[Batch Inspector] Get Comparison Batches',
  GET_COMPARISON_BATCHES_SUCCESS = '[Batch Inspector] Get Comparison Batches :: Success',

  GET_COMPARISON_BATCHES_DETAIL = '[Batch Inspector] Get Comparison Batches Detail',
  GET_COMPARISON_BATCHES_DETAIL_SUCCESS = '[Batch Inspector] Get Comparison Batches Detail :: Success',
  UPDATE_COMPARISON_BATCHES_DETAIL_PARAMS = '[Batch Inspector] Update Comparison Batches Detail Params',
  CLEAR_COMPARISON_BATCHES = '[Batch Inpsector] Clear Comparison Batches',

  CREATE_BATCH = '[Batch Inspector] Create Batch',
  CREATE_BATCH_SUCCESS = '[Batch Inspector] Create Batch :: Success',

  CREATE_COMPARISON_BATCH = '[Batch Inspector] Create Comparison Batch',
  CREATE_COMPARISON_BATCH_SUCCESS = '[Batch Inspector] Create Comparison Batch :: Success',

  CREATE_COMPARISON_DETAIL_BATCH = '[Batch Inspector] Create Comparison Detail Batch',
  CREATE_COMPARISON_DETAIL_BATCH_SUCCESS = '[Batch Inspector] Create Comparison Detail Batch :: Success',

  DOWNLOAD_COMPARISON_BATCHES_DETAIL = '[Batch Inspector] Download Comparison Batches Detail',
}

export class GetBatchInspectorBatchesAction implements Action {
  public readonly type = BatchInspectorActionType.GET_BATCHES;

  constructor(public payload: {params: BatchInspectorSummaryParams}) {}
}

export class GetBatchInspectorBatchesSuccessAction implements Action {
  public readonly type = BatchInspectorActionType.GET_BATCHES_SUCCESS;

  constructor(
    public payload: {params: BatchInspectorSummaryParams; batches: BatchManagerBatch[]; batchesCount: number}
  ) {}
}

export class GetComparisonBatchesAction implements Action {
  public readonly type = BatchInspectorActionType.GET_COMPARISON_BATCHES;

  constructor(public payload: {batchNumbers: string}) {}
}

export class GetComparisonBatchesSuccessAction implements Action {
  public readonly type = BatchInspectorActionType.GET_COMPARISON_BATCHES_SUCCESS;

  constructor(public payload: {batchNumbers: string; batchComparison: BatchComparison}) {}
}

export class GetComparisonBatchesDetailAction implements Action {
  public readonly type = BatchInspectorActionType.GET_COMPARISON_BATCHES_DETAIL;

  constructor(public payload: {params: BatchComparisonDetailParams}) {}
}

export class GetComparisonBatchesDetailSuccessAction implements Action {
  public readonly type = BatchInspectorActionType.GET_COMPARISON_BATCHES_DETAIL_SUCCESS;

  constructor(public payload: {params: BatchComparisonDetailParams; pieces: PieceDetail[]; piecesCount: number}) {}
}

export class UpdateComparisonBatchesDetailParamsAction implements Action {
  public readonly type = BatchInspectorActionType.UPDATE_COMPARISON_BATCHES_DETAIL_PARAMS;

  constructor(public payload: {params: BatchComparisonDetailParams}) {}
}

export class ClearComparisonBatchesAction implements Action {
  public readonly type = BatchInspectorActionType.CLEAR_COMPARISON_BATCHES;

  constructor(public payload: {clear: boolean}) {}
}

export class CreateBatchInspectorBatchAction implements Action {
  public readonly type = BatchInspectorActionType.CREATE_BATCH;

  constructor(
    public payload: {
      params: BatchInspectorCreateBatchParams;
      onSuccess: () => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateComparisonBatchAction implements Action {
  public readonly type = BatchInspectorActionType.CREATE_COMPARISON_BATCH;

  constructor(
    public payload: {
      params: BatchInspectorCreateBatchParams;
      onSuccess: () => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateComparisonDetailBatchAction implements Action {
  public readonly type = BatchInspectorActionType.CREATE_COMPARISON_DETAIL_BATCH;

  constructor(
    public payload: {
      params: BatchInspectorCreateBatchParams;
      onSuccess: () => void;
      onFailure: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DownloadComparisonBatchesDetailAction implements Action {
  public readonly type = BatchInspectorActionType.DOWNLOAD_COMPARISON_BATCHES_DETAIL;

  constructor(public payload: {params: BatchComparisonDetailParams}) {}
}

export type BatchInspectorAction =
  | GetBatchInspectorBatchesAction
  | GetBatchInspectorBatchesSuccessAction
  | GetComparisonBatchesAction
  | GetComparisonBatchesSuccessAction
  | GetComparisonBatchesDetailAction
  | GetComparisonBatchesDetailSuccessAction
  | UpdateComparisonBatchesDetailParamsAction
  | ClearComparisonBatchesAction
  | CreateBatchInspectorBatchAction
  | CreateComparisonBatchAction
  | CreateComparisonDetailBatchAction
  | DownloadComparisonBatchesDetailAction;
