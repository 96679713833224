import {NgModule} from '@angular/core';
import {Route, Router, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {DevelopmentGuard} from './core/guards/development.guard';
import {DebuggingPageComponent} from './shared/debugging-page/debugging-page.component';
import {DRALayoutComponent} from './shared/dra-layout/dra-layout.component';

const appRoutes: Routes = [
  {
    path: 'batch-tracking',
    canActivate: [AuthGuard],
    loadChildren: () => import('./batch-tracking/batch-tracking.module').then(m => m.BatchTrackingModule),
  },
  {
    path: 'gateway',
    canActivate: [AuthGuard],
    loadChildren: () => import('./gateway/gateway.module').then(m => m.GatewayModule),
  },
  {
    path: 'tracking',
    loadChildren: () => import('./tracking/tracking.module').then(m => m.TrackingModule),
  },
  {
    path: 'file-search',
    canActivate: [AuthGuard],
    loadChildren: () => import('./file-search/file-search.module').then(m => m.FileSearchModule),
  },
  {
    path: 'batch-inspectr',
    canActivate: [AuthGuard],
    loadChildren: () => import('./batch-inspector/batch-inspector.module').then(m => m.BatchInspectorModule),
  },
  {
    path: 'piece-inspectr',
    canActivate: [AuthGuard],
    loadChildren: () => import('./piece-inspector/piece-inspector.module').then(m => m.PieceInspectorModule),
  },
  {
    path: 'clearance-inspectr',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./clearance-inspector/clearance-inspector.module').then(m => m.ClearanceInspectorModule),
  },
  {
    path: 'piece-inspectr-game',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./piece-inspector-game/piece-inspector-game.module').then(m => m.PieceInspectorGameModule),
  },
  {
    path: 'barcode-games',
    canActivate: [AuthGuard],
    loadChildren: () => import('./barcode-games/barcode-games.module').then(m => m.BarcodeGamesModule),
  },
  // {
  //   path: 'user',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  // },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./client-dashboard/client-dashboard.module').then(m => m.ClientDashboardModule),
  },
  {
    path: 'debugging',
    canActivate: [AuthGuard],
    component: DebuggingPageComponent,
  },
  {
    path: 'shipment-manager-trainer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./client-dashboard/client-dashboard.module').then(m => m.ClientDashboardModule),
  },
  {
    path: 'configuratr',
    canActivate: [AuthGuard],
    loadChildren: () => import('./configuratr/configuratr.module').then(m => m.ConfiguratrModule),
  },
  {
    path: '',
    component: DRALayoutComponent,
    canActivate: [DevelopmentGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'facility',
        loadChildren: () => import('./facility/facility.module').then(m => m.FacilityModule),
      },
      {
        path: 'pars',
        loadChildren: () => import('./pars/pars.module').then(m => m.ParsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    const redirectTo = window.location.hostname.includes('tracking') ? 'tracking' : 'login';
    const defaultRoute: Route = {path: '', pathMatch: 'full', redirectTo};
    const wildcardRoute: Route = {path: '**', redirectTo};
    const config: Routes = [defaultRoute, ...this.router.config, wildcardRoute];
    this.router.resetConfig(config);
  }
}
