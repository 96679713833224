import {AuthToken} from '../../../core/models/auth-token';

export function createAuthHeaders(authToken: AuthToken): Record<string, string> {
  if (!authToken) {
    return {};
  }

  return {
    Authorization: `${authToken.tokenType} ${authToken.accessToken}`,
  };
}
