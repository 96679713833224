import {Component, Directive, EventEmitter, HostBinding, Input, Output} from '@angular/core';

/**
 * Content of a modal, needed as it's used as a selector in the API.
 */
@Directive({
  selector: 'modal-body',
})
export class ModalBodyDirective {
  @HostBinding('class.modal-body')
  public rootClass = true;
}

/**
 * Footer of a modal, needed as it's used as a selector in the API.
 */
@Directive({
  selector: 'modal-footer',
})
export class ModalFooterDirective {
  @HostBinding('class.modal-footer')
  public rootClass = true;
}

/**
 * A basic modal for DRA
 *
 * While this component can be used alone, it also provides a number
 * preset styles for sections, including:
 * - modal-body
 * - modal-footer
 */
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() title: string;
  /** Inform the parent component that the modal should close */
  @Output() closeModal = new EventEmitter<void>();
  /** Inform the parent component that the modal should close, and some data was updated */
  @Output() closeAndUpdate = new EventEmitter<void>();

  /** Checks if a user's click is outside the modal bounds
   * @param event The click event. Its target determines the modal behavior.
   */
  checkForClickOut(event) {
    console.log('Emitting close...');
    if (event.target.className === 'modal-bg') {
      this.closeModal.emit();
    }
  }
}
