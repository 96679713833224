import {PieceInspectorState, initialPieceInspectorState} from './piece-inspector.state';
import {hashObject} from '../../../shared/utils/hash-object';
import {PieceInspectorAction, PieceInspectorActionType} from './piece-inspector.actions';

export function pieceInspectorReducer(
  state = initialPieceInspectorState,
  action: PieceInspectorAction
): PieceInspectorState {
  switch (action.type) {
    case PieceInspectorActionType.GET_BATCHES_SUCCESS: {
      const queryBatchesMap = {
        ...state.queryBatchesMap,
        [hashObject(action.payload.params)]: action.payload.batches,
      };
      return {...state, queryBatchesMap, batchesCount: action.payload.batchesCount};
    }
    case PieceInspectorActionType.GET_RECENT_BATCHES_SUCCESS:
      return {...state, recentBatches: action.payload.batches};
    case PieceInspectorActionType.GET_BATCH_SUCCESS:
      return {...state, singleBatch: action.payload.batch};
    case PieceInspectorActionType.CLEAR_BATCH:
      return {...state, singleBatch: null};
    case PieceInspectorActionType.GET_BATCH_FROM_SCAN_SUCCESS:
      return {...state, batchesFromScan: action.payload.batches};
    case PieceInspectorActionType.CLEAR_BATCH_FROM_SCAN:
      return {...state, batchesFromScan: null};
    case PieceInspectorActionType.SCAN_PIECE_SUCCESS:
      return {...state, scanPiece: action.payload.piece};
    case PieceInspectorActionType.CLEAR_SCAN_PIECE:
      return {...state, scanPiece: null};
    case PieceInspectorActionType.NEW_BATCH_SUCCESS:
      return {...state, createdBatch: action.payload.batch};
    case PieceInspectorActionType.GET_CLOSEOUT_BATCHES_SUCCESS: {
      const queryCloseoutBatchesMap = {
        ...state.queryCloseoutBatchesMap,
        [hashObject(action.payload.params)]: action.payload.batches,
      };
      return {...state, queryCloseoutBatchesMap, batchesCount: action.payload.batchesCount};
    }
    case PieceInspectorActionType.UPDATE_GAME_SUCCESS:
      return {...state, gameState: action.payload.status};
    case PieceInspectorActionType.CLEAR_GAME_STATE:
      return {...state, gameState: ''};
    case PieceInspectorActionType.CLEAR_GAME_DETAILS:
      return {...state, singleBatch: null};
    default:
      return state;
  }
}
