/* eslint-disable */

import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';

mapboxgl.accessToken = environment.mapboxToken;

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  getMarkers() {
    const geoJson = [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: ['-92.1005', '46.7867'],
        },
        properties: {
          message: 'Duluth',
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: ['-93.13778', '44.838842'],
        },
        properties: {
          message: 'Minneapolis',
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: ['-87.875189', '42.951227'],
        },
        properties: {
          message: 'Milwaukee',
        },
      },
    ];
    return geoJson;
  }

  getIPMarkers() {
    const geoJson = [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: ['-97.1384', '49.8951'],
        },
        properties: {
          message: 'Induction Point - Winnipeg',
        },
      },
    ];
    return geoJson;
  }

  getPOEMarkers() {
    const geoJson = [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: ['-96.8015', '49.0002'],
        },
        properties: {
          message: 'Lancaster',
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: ['-95.9782', '48.9999'],
        },
        properties: {
          message: 'Pinecreek',
        },
      },
    ];
    return geoJson;
  }

  getLeg() {
    const geoJson = [
      {
        type: 'Feature',
        properties: {
          carrier: 'Becker Trucking',
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            ['-92.1005', '46.7867'], // Origin
            ['-96.8015', '49.0002'], // POE
            ['-97.1384', '49.8951'], // IP
          ],
        },
      },
      {
        type: 'Feature',
        properties: {
          carrier: 'Becker Trucking',
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            ['-93.13778', '44.838842'], // Origin
            ['-96.8015', '49.0002'], // POE
            ['-97.1384', '49.8951'], // IP
          ],
        },
      },
      {
        type: 'Feature',
        properties: {
          carrier: 'Becker Trucking',
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            ['-87.875189', '42.951227'], // Origin
            ['-95.9782', '48.9999'], // POE
            ['-97.1384', '49.8951'], // IP
          ],
        },
      },
    ];
    return geoJson;
  }

  getBestwayLegs() {
    const geoJson = [
      {
        type: 'Feature',
        properties: {
          carrier: 'Bestway Systems',
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            ['-92.1005', '46.7867'], // Origin
            ['-96.8015', '49.0002'], // POE
            ['-97.1384', '49.8951'], // IP
          ],
        },
      },
      {
        type: 'Feature',
        properties: {
          carrier: 'Bestway Systems',
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            ['-92.1005', '46.7867'], // Origin
            ['-95.9782', '48.9999'], // POE
            ['-97.1384', '49.8951'], // IP
          ],
        },
      },
    ];
    return geoJson;
  }

  getMarkerIcon(color: string) {
    const size = 150;

    const pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      render: function () {
        // var duration = 1000;
        // var t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        // var outerRadius = size / 2 * 0.7 * t + radius;
        const outerRadius = (size / 2) * 0.7 * 1 + radius;
        const context = this.context;

        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
        // context.fillStyle = 'rgba(43, 52, 135,' + (1 - t) + ')';
        context.fillStyle = 'rgba(43, 52, 135,' + 0 + ')';
        context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = color;
        context.strokeStyle = 'white';
        // context.lineWidth = 2 + 4 * (1 - t);
        context.lineWidth = 2 + 4 * 0;
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // keep the map repainting
        // map.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      },
    };
    return pulsingDot;
  }
}
