import {Injectable} from '@angular/core';
import {IconOptions, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  public loadIcons() {
    this.addIcon('apps-small', 'assets/icons/apps-small.svg');
    this.addIcon('arrow-left', 'assets/arrow-left.svg', {viewBox: '-2 -4 24 24'});
    this.addIcon('arrow-right', 'assets/arrow-right.svg');
    this.addIcon('caret-down', 'assets/icons/caret-down.svg');
    this.addIcon('client', 'assets/icons/client.svg');
    this.addIcon('group', 'assets/icons/group.svg', {viewBox: '9 10 24 24'});
    this.addIcon('login', 'assets/icons/login.svg');
    this.addIcon('mobile-nav-batch-active', 'assets/icons/tracking-icons/mobile-navbar/mobile-nav-batch-active.svg');
    this.addIcon(
      'mobile-nav-batch-inactive',
      'assets/icons/tracking-icons/mobile-navbar/mobile-nav-batch-inactive.svg'
    );
    this.addIcon(
      'mobile-nav-pieces-inactive',
      'assets/icons/tracking-icons/mobile-navbar/mobile-nav-pieces-inactive.svg'
    );
    this.addIcon('mobile-nav-pieces-active', 'assets/icons/tracking-icons/mobile-navbar/mobile-nav-pieces-active.svg');
    this.addIcon('search-white', 'assets/search-white.svg');
    this.addIcon('success', 'assets/icons/success.svg');
    this.addIcon('switch', 'assets/icons/switch.svg');
  }

  private addIcon(name: string, path: string, options?: IconOptions) {
    this.iconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path), options);
  }
}
