import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ArrayIncludesPipe} from './array-includes.pipe';
import {EncodeUrlParamPipe} from './encode-url-param.pipe';
import {JoinPipe} from './join.pipe';
import {LogPipe} from './log.pipe';
import {MomentPipe} from './moment.pipe';
import {TimeZonePipe} from './time-zone.pipe';
import {TrackingInformationEmailPipe} from './tracking-information-email.pipe';
import {SplitPipe} from './split.pipe';
import {FileCardIconPipe} from './file-card-icon.pipe';
import {SpaceAfterCommaPipe} from './space-after-comma.pipe';
import {FirstLetterPipe} from './first-letter.pipe';
import {CountryIconPipe} from './country-icon.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    EncodeUrlParamPipe,
    ArrayIncludesPipe,
    TimeZonePipe,
    MomentPipe,
    TrackingInformationEmailPipe,
    JoinPipe,
    LogPipe,
    SplitPipe,
    FileCardIconPipe,
    SpaceAfterCommaPipe,
    FirstLetterPipe,
    CountryIconPipe,
  ],
  exports: [
    EncodeUrlParamPipe,
    ArrayIncludesPipe,
    TimeZonePipe,
    MomentPipe,
    TrackingInformationEmailPipe,
    JoinPipe,
    LogPipe,
    SplitPipe,
    FileCardIconPipe,
    SpaceAfterCommaPipe,
    FirstLetterPipe,
    CountryIconPipe,
  ],
})
export class PipesModule {}
