import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {Constants} from 'src/app/shared/constants';
import {filter} from 'rxjs/operators';
import {Router, NavigationEnd} from '@angular/router';
import {MenuItems} from 'src/app/core/models/menu-items';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  // Close quick access menu if open on certain routes
  @Output() closeQuickAccessMenuIfOpen = new EventEmitter<boolean>();

  menuItems: MenuItems[];
  activeRoute: string;

  constructor(private constants: Constants, private router: Router) {}

  ngOnInit() {
    // Update activeRoute property every time user navigates to new route.
    // eslint-disable-next-line
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(event => {
      /* TODO: This code closes the quick access menu (if it's open)
         when certain routes are activated. This logic should likely be captured
         elsewhere in some config settings */
      this.activeRoute = event.urlAfterRedirects;
      if (this.activeRoute === '/facility') {
        console.log('hit facilities');
        this.closeQuickAccessMenuIfOpen.emit(true);
      }
      // Update menu items to show activeIcon for activeRoute
      this.menuItems.forEach(m => {
        m.iconSrc = m.address === this.activeRoute ? m.activeIcon : m.inactiveIcon;
      });
    });
    this.menuItems = this.findMenuItems();
  }

  private findMenuItems() {
    return this.constants.MENU_ITEMS.map(m => {
      // Set initial icon img src
      // to be toggled later on hover & activeRoute change
      m.iconSrc = m.inactiveIcon;
      return m;
    });
  }
}
