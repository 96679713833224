import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {DraSentryModule} from 'dra-lib';
import {environment} from '../../environments/environment';
import {draHttpInterceptorProviders} from './api/interceptors';
import {
  CarrierService,
  ClientService,
  FacilityService,
  HttpErrorHandlerService,
  MapboxService,
  MessageService,
} from './services';
import {DraStoreModule} from './store/dra-store.module';

@NgModule({
  imports: [CommonModule, DraSentryModule.withEnvironment(environment), DraStoreModule, HttpClientModule],
  providers: [
    CarrierService,
    ClientService,
    FacilityService,
    HttpErrorHandlerService,
    MapboxService,
    MessageService,
    draHttpInterceptorProviders,
  ],
  declarations: [],
})
export class CoreModule {}
