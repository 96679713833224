import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LogoutAction} from 'src/app/core/store/auth/auth.action';
import {selectRouterUrl} from 'src/app/core/store/router/router.selector';

@Component({
  selector: 'mobile-tracking-header',
  templateUrl: './mobile-tracking-header.component.html',
  styleUrls: ['./mobile-tracking-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileTrackingHeaderComponent implements OnInit {
  @Input()
  public authenticated: boolean;

  @Input()
  pageTitle: string;

  @Input()
  public showLogout: boolean;

  @Input()
  public trainingMode: boolean;

  @Output()
  public menu = new EventEmitter();

  @Output()
  public toggleTrainingMode = new EventEmitter();

  public showSearch$: Observable<boolean>;
  private url$: Observable<string>;

  constructor(private router: Router, private store$: Store<{}>) {}

  ngOnInit() {
    this.url$ = this.store$.pipe(select(selectRouterUrl));
    this.showSearch$ = this.observeUrl();
  }

  private observeUrl(): Observable<boolean> {
    return this.url$.pipe(map(url => url?.startsWith('/tracking') || url?.startsWith('/batch-tracking')));
  }

  public onSearchClick() {
    // TODO navigate to mobile search page
  }

  public onSearchValueSubmit(searchValue: string) {
    if (searchValue) {
      this.router.navigate(['/tracking', searchValue]);
    }
  }

  public onMenuClick() {
    this.menu.emit();
  }

  public onLogoutClick() {
    this.store$.dispatch(new LogoutAction());
  }

  public onToggle() {
    this.toggleTrainingMode.emit();
  }
}
