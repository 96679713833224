/* eslint-disable */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Client} from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private clientUrl = `${environment.apiUrl}/clients`;

  constructor(private http: HttpClient) {}

  /**
   * Handle any Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      /* TODO: send errors to remote logging infrastructure */
      console.error(error);
      console.log(`Something went wrong while trying to ${operation}. Please try again.`);
      /* Let the app keep running by returning an empty result. */
      return of(result as T);
    };
  }

  /**
   * GET array of Clients
   */
  public getClients(): Observable<Array<Client>> {
    const url = this.clientUrl;
    return this.http.get<Array<Client>>(url).pipe(
      // tap(clients => this.log(`Successfully fetched Clients`, true)),
      catchError(this.handleError('get Clients', []))
    );
  }

  /**
   * GET a single Client by ID
   * @param id - ClientID
   */
  public getClient(id: number): Observable<Client> {
    const url = `${this.clientUrl}/${id}`;
    return this.http
      .get<Client>(url)
      .pipe
      // tap(client => this.log(`Successfully fetched Clients`, true)),
      /* TODO: Figure out how to instantiate empty Client observable here */
      // catchError(this.handleError('get Clients'))
      ();
  }
}
