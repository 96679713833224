import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {captureException, captureMessage, Severity} from '@sentry/browser';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Environment, ENVIRONMENT} from '../environment';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {
  constructor(@Inject(ENVIRONMENT) private environment: Environment) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (this.environment.sentryDsn && ![401, 500].includes(error.status)) {
          this.sendErrorToSentry(error);
        }

        return throwError(error);
      })
    );
  }

  private sendErrorToSentry(error: any): void {
    if (error instanceof Error || error instanceof ErrorEvent) {
      captureException(error);
      return;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        captureException(error.error);
      } else {
        captureMessage(error.message, Severity.Error);
      }
      return;
    }

    captureMessage(error);
  }
}
