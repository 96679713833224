import * as moment from 'moment';
import {GatewayPiece} from '../../models/gateway-piece';
import {GatewayPieceDto} from '../dto/gateway-piece.dto';

export function convertGatewayPieceDtoToModel(dto: GatewayPieceDto): GatewayPiece {
  return {
    id: dto.PieceID,
    depotCode: dto.DepotCode,
    orderNumber: dto.OrderNumber,
    origin: dto.Origin,
    originLabel: dto.OriginLabel,
    pieceCount: dto.PieceCount,
    pieceType: dto.PieceType,
    pieceTypeLabel: dto.PieceTypeLabel,
    pin: dto.Pin,
    proNumber: dto.ProNumber,
    shipDate: dto.ShipDate && moment.utc(dto.ShipDate),
    statusDate: dto.StatusDate && moment.utc(dto.StatusDate),
    weight: dto.Weight,
  };
}
