import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DraLabelDirective} from './dra-label.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DraLabelDirective],
  exports: [DraLabelDirective],
})
export class DraDesignModule {}
