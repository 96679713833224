import {ApplicationId} from 'src/app/core/models/application';

export function findWorkspaceUrl(name: string) {
  switch (name) {
    case ApplicationId.ClearanceInspectR:
      return '/clearance-inspectr';
    case ApplicationId.BatchInspectR:
      return '/batch-inspectr';
    case ApplicationId.Documents:
      return '/file-search';
    case ApplicationId.GatewayBatchManager:
      return '/gateway';
    case ApplicationId.PieceInspectR:
      return '/piece-inspectr';
    case ApplicationId.BatchTracking:
      return '/batch-tracking';
    case ApplicationId.Games:
      return '/piece-inspectr-game';
    case ApplicationId.ClientDashboard:
      return '/dashboard';
    default:
      return '/home';
  }
}
