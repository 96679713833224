import {BatchComparison} from './../../models/batch-comparison';
import {BatchComparisonDto} from './../dto/batch-comparison.dto';
import {convertBatchComparisonIntersectionDtoToModel} from './convert-batch-comparison-intersection-dto-to-model';
import {convertBatchComparisonKeyDtoToModel} from './convert-batch-comparison-key-dto-to-model';
import {convertGatewayBatchDtoToModel} from './convert-gateway-batch-dto-to-model';

export function convertBatchComparisonDtoToModel(dto: BatchComparisonDto): BatchComparison {
  return {
    batchList: dto.BatchList.map(batch => convertGatewayBatchDtoToModel(batch)),
    intersections: dto.Intersections.map(intersection => convertBatchComparisonIntersectionDtoToModel(intersection)),
    keys: dto.Keys.map(key => convertBatchComparisonKeyDtoToModel(key)),
  };
}
