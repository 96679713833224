import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';
import {Lesson} from 'src/app/core/models/lesson';

@Component({
  selector: 'training-mode-drawer',
  templateUrl: './training-mode-drawer.component.html',
  styleUrls: ['./training-mode-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingModeDrawerComponent {
  @Input()
  public lessons: Lesson[];

  public trainingModeSidebarOpen$ = new BehaviorSubject<boolean>(true);
  public activeLessonNumber$ = new BehaviorSubject<string>('');

  public onTrainingModeToggle() {
    this.trainingModeSidebarOpen$.pipe(take(1)).subscribe(open => {
      this.trainingModeSidebarOpen$.next(!open);
    });
  }

  public onLessonSelect(lessonNumber: string) {
    this.activeLessonNumber$.next(lessonNumber);
  }
}
