import {hashObject} from 'src/app/shared/utils/hash-object';
import {ClearanceInspectorAction, ClearanceInspectorActionType} from './clearance-inspector.actions';
import {ClearanceInspectorState, initialClearanceInspectorState} from './clearance-inspector.state';

export function clearanceInspectorReducer(
  state = initialClearanceInspectorState,
  action: ClearanceInspectorAction
): ClearanceInspectorState {
  switch (action.type) {
    case ClearanceInspectorActionType.GET_PIECES_SUCCESS: {
      const clearanceDataMap = {
        ...state.clearanceDataMap,
        [hashObject(action.payload.pieceIds)]: action.payload.clearanceData,
      };
      return {...state, clearanceDataMap};
    }
    case ClearanceInspectorActionType.UPDATE_LOADING:
      return {...state, loadingAfterCloseout: action.payload.loadingAfterCloseout};
    case ClearanceInspectorActionType.UPDATE_CHECKED_MAP:
      return {...state, checkedMap: action.payload.checkedMap};
    default:
      return state;
  }
}
