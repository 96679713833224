import {PieceDetail} from '../../models/piece-detail';
import {PieceDetailDto} from '../dto/piece-detail.dto';

export function convertPieceDetailDtoToModel(dto: PieceDetailDto): PieceDetail {
  return {
    id: dto.PieceID,
    batchId: dto.BatchID || null,
    depotCode: dto.DepotCode,
    orderNumber: dto.OrderNumber,
    origin: dto.Origin,
    originLabel: dto.OriginLabel,
    pieceCount: dto.PieceCount,
    pieceType: dto.PieceType,
    pieceTypeLabel: dto.PieceTypeLabel,
    pin: dto.Pin,
    proNumber: dto.ProNumber,
    shipDate: dto.ShipDate ? new Date(dto.ShipDate) : null,
    statusDate: dto.StatusDate ? new Date(dto.StatusDate) : null,
    weight: dto.Weight,
    postalCode: dto.PostalCode,
    city: dto.City,
    province: dto.Province,
    statusTypeDisplay: dto.StatusTypeDisplay,
  };
}
