<header
  *ngIf="{
    currentCompanyId: currentCompanyId$ | async,
    showSearch: showSearch$ | async
  } as state"
  class="do-not-print"
  [class.training-background]="trainingMode"
>
  <div class="header-inner-container" [class.header-training-mode-width]="trainingMode">
    <div class="flex-div">
      <div class="logo-container flex-div">
        <img src="assets/purolator-logo-01.svg" class="logo" />
      </div>
      <span class="page-title">{{ pageTitle === 'Client Dashboard' ? 'Shipment ManageR' : pageTitle }}</span>
    </div>

    <div *ngIf="trainingMode" class="training-mode-info flex-div">
      <img src="assets/icons/info-training.svg" class="info-icon" />
      <span>Training Mode</span>
    </div>

    <div class="flex-div">
      <!-- <div class="button-container flex-div">
        <button mat-button (click)="onResetPackages()">Reset packages</button>
        <button mat-button (click)="onHideBatches()">Hide batches</button>
        <button *ngIf="showGetPackages$ | async" mat-button (click)="onAddPackages()">Get test packages</button>
      </div> -->

      <div *ngIf="resetTestDataButtonShouldBeVisible(authToken$ | async)" data-testid="reset-test-data-button">
        <button mat-flat-button (click)="onResetTestData()" class="logout-button">Reset Test Data</button>
      </div>

      <div *ngIf="logoutButtonShouldBeVisible()">
        <button mat-flat-button (click)="onLogoutClick()" class="logout-button">
          Logout
          <img src="assets/icons/tracking-icons/logout-white.svg" class="logout-icon" />
        </button>
      </div>

      <!-- <div *ngIf="!authenticated">
        <a [routerLink]="['/login']" (click)="onLoginClick()" class="login-link">
          Login
          <img src="assets/icons/tracking-icons/login.svg" class="login-icon" />
        </a>
      </div> -->
    </div>
  </div>
</header>
