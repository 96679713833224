import {ClearanceInspectorCloseoutReturn, CloseoutListItem} from './../../models/clearance-inspector-closeout-return';
import {
  ClearanceInspectorCloseoutReturnDto,
  CloseoutListItemDto,
} from './../dto/clearance-inspector-closeout-return.dto';

export function convertClearanceInspectorCloseoutReturnDtoToModel(
  dto: ClearanceInspectorCloseoutReturnDto
): ClearanceInspectorCloseoutReturn {
  return {
    pinsFailed: dto.PinsFailed,
    pinsPreviouslyClosed: dto.PinsPreviouslyClosed,
    closeoutList: dto.CloseoutList.map(item => convertCloseoutListItemDtoToModel(item)),
  };
}

function convertCloseoutListItemDtoToModel(dto: CloseoutListItemDto): CloseoutListItem {
  return {
    clientId: dto.ClientID,
    clientName: dto.ClientName,
    pieceCount: dto.PieceCount,
    closeoutUrl: dto.CloseoutURL,
    pinList: dto.PinList,
  };
}
