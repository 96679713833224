import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {BatchTransitionType} from '../../models/batch-transition-type';
import {ClientDashboardBatch} from '../../models/client-dashboard-batch';
import {ClientDashboardErrorResponse} from '../../models/client-dashboard-error-response';
import {ClientDashboardParams} from '../../models/client-dashboard-summary-params';
import {ClientDashboardTrainerPackageParams} from '../../models/client-dashboard-trainer-package-params';
import {NewContainerParams} from '../../models/new-container-form';
import {PieceDetail} from '../../models/piece-detail';

export enum ClientDashboardActionType {
  GET_RECENT_BATCHES = '[Client Dashboard] Get Recent Batches',
  GET_RECENT_BATCHES_SUCCESS = '[Client Dashboard] Get Recent Batches :: Success',

  GET_BATCH = '[Client Dashboard] Get Batch',
  GET_BATCH_SUCCESS = '[Client Dashboard] Get Batch :: Success',

  GET_PIECE_DETAILS = '[Client Dashboard] Get Piece Details',
  GET_PIECE_DETAILS_SUCCESS = '[Client Dashboard] Get Piece Details :: Success',

  CLOSEOUT_BY_PIECES = '[Client Dashboard] Closeout by Pieces',
  CLOSEOUT_BY_CONTAINER = '[Client Dashboard] Closeout by Container',

  CREATE_CONTAINER = '[Client Dashboard] Create Container',
  CREATE_CONTAINER_SUCCESS = '[Client Dashboard] Create Container :: Success',

  // ----------Trainer Mode----------

  GET_TRAINER_PACKAGES = '[Client Dashboard] Get Trainer Packages',
  GET_TRAINER_PACKAGES_SUCCESS = '[Client Dashboard] Get Trainer Packages :: Success',
}

export class GetClientDashboardRecentBatchesAction implements Action {
  public readonly type = ClientDashboardActionType.GET_RECENT_BATCHES;

  constructor(
    public payload: {
      params: ClientDashboardParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetClientDashboardRecentBatchesSuccessAction implements Action {
  public readonly type = ClientDashboardActionType.GET_RECENT_BATCHES_SUCCESS;

  constructor(
    public payload: {recentBatches: ClientDashboardBatch[]; recentBatchesCount: number; params: ClientDashboardParams}
  ) {}
}

export class GetClientDashboardBatchAction implements Action {
  public readonly type = ClientDashboardActionType.GET_BATCH;

  constructor(
    public payload: {
      batchId: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetClientDashboardBatchSuccessAction implements Action {
  public readonly type = ClientDashboardActionType.GET_BATCH_SUCCESS;

  constructor(public payload: {batch: ClientDashboardBatch}) {}
}

export class GetClientDashboardPieceDetailsAction implements Action {
  public readonly type = ClientDashboardActionType.GET_PIECE_DETAILS;

  constructor(public payload: {params: ClientDashboardParams; associatedBatch?: boolean}) {}
}

export class GetClientDashboardPieceDetailsSuccessAction implements Action {
  public readonly type = ClientDashboardActionType.GET_PIECE_DETAILS_SUCCESS;

  constructor(
    public payload: {
      params: ClientDashboardParams;
      pieces: PieceDetail[];
      piecesCount: number;
      associatedBatch?: boolean;
    }
  ) {}
}

export class CloseoutByPiecesAction implements Action {
  public readonly type = ClientDashboardActionType.CLOSEOUT_BY_PIECES;

  constructor(
    public payload: {
      appId: string;
      companyId: number;
      pinList: string[];
      transitionType: string;
      origin?: string;
      onSuccess?: () => void;
      onFailure?: (error: ClientDashboardErrorResponse) => void;
    }
  ) {}
}

export class CloseoutByContainerAction implements Action {
  public readonly type = ClientDashboardActionType.CLOSEOUT_BY_CONTAINER;

  constructor(
    public payload: {
      batchNumber: string;
      transitionType: BatchTransitionType;
      excludedPieceIds: string[];
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateClientDashboardContainerAction implements Action {
  public readonly type = ClientDashboardActionType.CREATE_CONTAINER;

  constructor(
    public payload: {
      params: NewContainerParams;
      onSuccess?: () => void;
      onFailure?: (error: ClientDashboardErrorResponse) => void;
    }
  ) {}
}

export class CreateClientDashboardContainerSuccessAction implements Action {
  public readonly type = ClientDashboardActionType.CREATE_CONTAINER_SUCCESS;

  constructor(public payload: {createdBatch: ClientDashboardBatch}) {}
}

// ----------Trainer Mode----------
export class GetClientDashboardTrainerTestPackagesAction implements Action {
  public readonly type = ClientDashboardActionType.GET_TRAINER_PACKAGES;

  constructor(
    public payload: {
      params: ClientDashboardTrainerPackageParams;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetClientDashboardTrainerTestPackagesSuccessAction implements Action {
  public readonly type = ClientDashboardActionType.GET_TRAINER_PACKAGES_SUCCESS;

  constructor(public payload: {packages: string[]}) {}
}

export type ClientDashboardAction =
  | GetClientDashboardRecentBatchesAction
  | GetClientDashboardRecentBatchesSuccessAction
  | GetClientDashboardBatchAction
  | GetClientDashboardBatchSuccessAction
  | GetClientDashboardPieceDetailsAction
  | GetClientDashboardPieceDetailsSuccessAction
  | CloseoutByPiecesAction
  | CloseoutByContainerAction
  | CreateClientDashboardContainerAction
  | CreateClientDashboardContainerSuccessAction
  | GetClientDashboardTrainerTestPackagesAction
  | GetClientDashboardTrainerTestPackagesSuccessAction;
