import {ClientDashboardTrainerPackageParamsDto} from './../dto/client-dashboard-trainer-package-params.dto';
import {ClientDashboardTrainerPackageParams} from './../../models/client-dashboard-trainer-package-params';

export function convertClientDashboardTrainerPackageParamsModelToDto(
  params: ClientDashboardTrainerPackageParams
): ClientDashboardTrainerPackageParamsDto {
  return {
    Valid: params.valid,
    Invalid: params.invalid,
    PreviouslyUsed: params.previouslyUsed,
    PackageCount: params.packageCount,
  };
}
