import {Clipboard} from '@angular/cdk/clipboard';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Lesson} from 'src/app/core/models/lesson';

@Component({
  selector: 'training-mode',
  templateUrl: './training-mode.component.html',
  styleUrls: ['./training-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingModeComponent {
  @Input()
  public lessons: Lesson[];

  @Input()
  public mobile: boolean;

  @Output()
  public toggle = new EventEmitter();

  @Output()
  public activeLesson = new EventEmitter<string>();

  constructor(private clipboard: Clipboard) {}

  public activeLesson$ = new BehaviorSubject<Lesson>(null);

  public onToggle() {
    this.toggle.emit();
  }

  public onLesson(lesson: Lesson) {
    this.activeLesson$.next(lesson);
    this.activeLesson.emit(lesson.lessonNumber);
  }

  public onBack() {
    this.activeLesson$.next(null);
    this.activeLesson.emit('');
  }

  public onCopy(value: string) {
    const copySuccess = this.clipboard.copy(value);
    if (copySuccess) {
      console.log('copy success');
    } else {
      console.log('copy failure');
    }
  }
}
