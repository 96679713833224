import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'dra-button',
  templateUrl: './dra-button.component.html',
  styleUrls: ['./dra-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraButtonComponent {
  @Input()
  public text: string;

  @Input()
  public disabled: boolean;

  @Input()
  public loading: boolean;
}
