<div *ngIf="!unsupportedBrowser; else unsupported" class="login-container">
  <img src="assets/purolator-logo.svg" />
  <router-outlet></router-outlet>
</div>

<ng-template #unsupported>
  <div class="no-support-container">
    <img src="assets/purolator-logo.svg" />
    <strong>This app does not support Internet Explorer</strong>
    <span class="med-text">Please use another browser*</span>
    <span class="small-text">*Supported Browsers are Chrome, Firefox, Microsoft Edge and Safari</span>
  </div>
</ng-template>
