<ng-container *ngIf="{packages: packages$ | async, onMainSelection: onMainSelection$ | async} as state">
  <header>
    <div class="header-left-container">
      <back-button *ngIf="!state.onMainSelection" (click)="onBack()"></back-button>
      <h1>Get Test Packages</h1>
    </div>
    <button mat-icon-button (click)="onClose()" matTooltip="Close" class="close-button">
      <img src="assets/close-v-1.svg" />
    </button>
  </header>

  <ng-container *ngIf="state.onMainSelection; else packagesLoaded">
    <form [formGroup]="form">
      <mat-checkbox formControlName="valid" color="primary">Valid</mat-checkbox>
      <mat-checkbox formControlName="invalid" color="primary">Invalid</mat-checkbox>
      <mat-checkbox formControlName="previouslyUsed" color="primary">Previously Used</mat-checkbox>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Package Count</mat-label>
        <input
          matInput
          type="number"
          min="1"
          [max]="maxPackageCount"
          formControlName="packageCount"
          (input)="onInput()"
          autocomplete="off"
        />
        <mat-hint>Total number of packages added</mat-hint>
        <mat-error *ngIf="form.value.packageCount > maxPackageCount">
          Maximum is {{ maxPackageCount }} packages
        </mat-error>
      </mat-form-field>
    </form>

    <section class="button-container">
      <button mat-flat-button (click)="onClose()" class="cancel-button">Cancel</button>
      <dra-button mat-flat-button [disabled]="!form.valid" [loading]="loading$ | async" (click)="onAdd()">
        Generate
      </dra-button>
    </section>
  </ng-container>

  <ng-template #packagesLoaded>
    <section *ngIf="!state.onMainSelection" class="package-container">
      <div *ngFor="let package of state.packages" class="package-chip">
        {{ package }}
      </div>
    </section>
  </ng-template>
</ng-container>
