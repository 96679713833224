import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Lesson} from 'src/app/core/models/lesson';
import {TrainingModeStatus} from '../training-mode-status';

@Component({
  selector: 'training-mode-list-item',
  templateUrl: './training-mode-list-item.component.html',
  styleUrls: ['./training-mode-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrainingModeListItemComponent implements OnChanges {
  @Input()
  public lesson: Lesson;

  @Input()
  public fromDetail: boolean;

  public showIcon: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lesson && this.lesson) {
      this.showIcon = !this.fromDetail && this.lesson.status === TrainingModeStatus.Finished;
    }
  }
}
