import {
  ClearanceInspectorData,
  ClearanceInspectorDataItem,
  ClearanceInspectorActionItem,
  ClearanceInspectorPiece,
} from './../../models/clearance-inspector-data';
import {
  ClearanceInspectorActionItemDto,
  ClearanceInspectorDataDto,
  ClearanceInspectorDataItemDto,
  ClearanceInspectorPieceDto,
} from './../dto/clearance-inspector-data.dto';

export function convertClearanceInspectorDataDtoToModel(dto: ClearanceInspectorDataDto): ClearanceInspectorData {
  return {
    label: dto.Label,
    responseTs: dto.ResponseTS,
    transmitted: dto.Transmitted?.map(t => convertClearanceInspectorDataItemDtoToModel(t)) || null,
    untransmitted: dto.Untransmitted?.map(t => convertClearanceInspectorDataItemDtoToModel(t)) || null,
  };
}

function convertClearanceInspectorDataItemDtoToModel(item: ClearanceInspectorDataItemDto): ClearanceInspectorDataItem {
  return {
    label: item.Label,
    actions: item.Actions?.map(action => convertClearanceInspectorActionDtoToModel(action)) || null,
    pieces: item.Pieces?.map(piece => convertClearanceInspectorPieceDtoToModel(piece)) || null,
  };
}

function convertClearanceInspectorActionDtoToModel(
  item: ClearanceInspectorActionItemDto
): ClearanceInspectorActionItem {
  return {
    buttonLabel: item.ButtonLabel,
    helpText: item.HelpText,
    endpoint: item.Endpoint,
    action: item.Action,
  };
}

function convertClearanceInspectorPieceDtoToModel(piece: ClearanceInspectorPieceDto): ClearanceInspectorPiece {
  return {
    pin: piece.Pin,
    shipper: piece.Shipper,
    clearanceDocument: piece.ClearanceDocument,
    clearanceType: piece.ClearanceType,
    dateSent: piece.DateSent,
  };
}
