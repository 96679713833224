import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ExportToCsv} from 'export-to-csv';
import {saveAs} from 'file-saver';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {StatsApiService} from '../../api/stats-api.service';
import {convertBatchSummaryDtosToModels} from '../../api/converters/convert-batch-summary-dtos-to-models';
import {convertBatchSummaryParamsModelToDto} from '../../api/converters/convert-batch-summary-params-model-to-dto';
import {convertPieceDetailDtoToCSV} from '../../api/converters/convert-piece-detail-dto-to-csv';
import {convertPieceDetailDtoToModel} from '../../api/converters/convert-piece-detail-dto-to-model';
import {convertPieceDetailParamsModelToDto} from '../../api/converters/convert-piece-detail-params-model-to-dto';
import {convertPieceDetailParamsModelToSpreadsheetDto} from '../../api/converters/convert-piece-detail-params-model-to-spreadsheet-dto';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  BatchTrackingActionType,
  GetBatchSummaryAction,
  GetBatchSummarySuccessAction,
  GetPieceDetailsAction,
  GetPieceDetailsCSVFileAction,
  GetPieceDetailsExcelFileAction,
  GetPieceDetailsSuccessAction,
} from './batch-tracking.actions';
import {csvOptions} from './utils/csvOptions';

@Injectable()
export class BatchTrackingEffects {
  public getSummaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetBatchSummaryAction>(BatchTrackingActionType.GET_SUMMARY),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertBatchSummaryParamsModelToDto(params);

        return this.statsApiService.getBatchSummaries(paramsDto).pipe(
          map(dtos => convertBatchSummaryDtosToModels(dtos)),
          mergeMap(summaries => [
            new GetBatchSummarySuccessAction({params, summaries}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getPieces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetPieceDetailsAction>(BatchTrackingActionType.GET_PIECE_DETAILS),
      mergeMap(action => {
        const {params} = action.payload;
        const paramsDto = convertPieceDetailParamsModelToDto(params);

        return this.statsApiService.getPieceDetails(paramsDto).pipe(
          map(page => {
            const pieces = page.PieceDetails.map(dto => convertPieceDetailDtoToModel(dto));
            const piecesCount = page.RecordCount;
            return new GetPieceDetailsSuccessAction({params, pieces, piecesCount});
          }),
          catchError(error => emitErrorActions(error))
        );
      })
    )
  );

  public getExcelFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetPieceDetailsExcelFileAction>(BatchTrackingActionType.GET_PIECE_DETAILS_EXCEL_FILE),
        mergeMap(action => {
          const {params} = action.payload;
          const paramsDto = convertPieceDetailParamsModelToSpreadsheetDto(params);

          return this.statsApiService.getPieceDetailsExcelFile(paramsDto).pipe(
            tap(file => {
              const blob = new Blob([file], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              saveAs(blob, 'Details.xlsx');
            }),
            catchError(error => emitErrorActions(error))
          );
        })
      ),
    {dispatch: false}
  );

  public saveToCSV$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetPieceDetailsCSVFileAction>(BatchTrackingActionType.GET_PIECE_DETAILS_CSV_FILE),
        mergeMap(action => {
          const {params} = action.payload;
          const paramsDto = convertPieceDetailParamsModelToSpreadsheetDto(params);

          return this.statsApiService.getPieceDetails(paramsDto).pipe(
            tap(csvData => {
              const pieces = csvData.PieceDetails.map(dto => convertPieceDetailDtoToCSV(dto));
              const csvExporter = new ExportToCsv(csvOptions);
              csvExporter.generateCsv(pieces);
            }),
            catchError(error => emitErrorActions(error))
          );
        })
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions, private statsApiService: StatsApiService) {}
}
