import {
  BatchMessagePiece,
  ClientDashboardBatch,
  ClientDashboardBatchDisplayField,
  ClientDashboardBatchMessage,
} from '../../models/client-dashboard-batch';
import {
  BatchMessagePieceDto,
  ClientDashboardBatchDisplayFieldDto,
  ClientDashboardBatchDto,
  ClientDashboardBatchMessageDto,
} from '../dto/client-dashboard-batch.dto';

export function convertClientDashboardBatchDtoToModel(dto: ClientDashboardBatchDto): ClientDashboardBatch {
  return {
    accountGroup: dto.AccountGroup,
    appGuid: dto.AppGuid,
    appId: dto.AppID,
    batchDescription: dto.BatchDescription,
    batchHeader: dto.BatchHeader,
    batchId: dto.BatchID,
    batchIdentifier: dto.BatchIdentifier,
    batchLabel: dto.BatchLabel,
    batchNumber: dto.BatchNumber,
    batchType: dto.BatchType,
    batchTypeLabel: dto.BatchTypeLabel,
    cargoControlNumber: dto.CargoControlNumber,
    childBatchNumbers: dto.ChildBatchNumbers,
    childBatches: dto.ChildBatches?.map(batch => convertClientDashboardBatchDtoToModel(batch)) || [],
    carrierTrackingNumber: dto.CarrierTrackingNumber,
    clearanceType: dto.ClearanceType,
    clearanceTypeLabel: dto.ClearanceTypeLabel,
    companyId: dto.CompanyID,
    companyLabel: dto.CompanyLabel,
    containerCount: dto.ContainerCount,
    createdBy: dto.CreatedBy,
    dateCaption: dto.DateCaption,
    dateValue: dto.DateValue,
    depotCode: dto.DepotCode,
    displayFields: dto.DisplayFields?.map(field => convertDisplayFieldsDtoToModel(field)) || [],
    facilityCode: dto.FacilityCode,
    gatewayArrivalDate: dto.GatewayArrivalDate,
    inductionLocation: dto.InductionLocation,
    location: dto.Location,
    messagePieceCount: dto.MessagePieceCount,
    messages: dto.Messages?.map(message => convertClientDashboardBatchMessagesDtoToModel(message)) || null,
    notes: dto.Notes,
    origin: dto.Origin,
    originCountry: dto.OriginCountry,
    originDepotCode: dto.OriginDepotCode,
    originFacilityCode: dto.OriginFacilityCode,
    originId: dto.OriginID,
    originLabel: dto.OriginLabel,
    originLocation: dto.OriginLocation,
    packageCount: dto.PackageCount,
    parentBatchNumber: dto.ParentBatchNumber,
    parentBatchId: dto.ParentBatchID,
    percentClosed: dto.PercentClosed,
    processingComplete: dto.ProcessingComplete,
    reference1: dto.Reference1,
    reference2: dto.Reference2,
    reference3: dto.Reference3,
    reference4: dto.Reference4,
    reference5: dto.Reference5,
    shipDate: dto.ShipDate,
    state: dto.State,
    statusCategory: dto.StatusCategory,
    statusDate: dto.StatusDate,
    statusType: dto.StatusType,
    statusTypeLabel: dto.StatusTypeLabel,
    supportCloseoutPartial: dto.SupportCloseoutPartial,
    totalPieces: dto.TotalPieces,
    totalPiecesOriginal: dto.TotalPiecesOriginal,
    totalPiecesUnexpected: dto.TotalPiecesUnexpected,
    totalPiecesVerified: dto.TotalPiecesVerified,
    totalWeight: dto.TotalWeight,
    totalWeightOriginal: dto.TotalWeightOriginal,
    totalWeightUnexpected: dto.TotalWeightUnexpected,
    totalWeightVerified: dto.TotalWeightVerified,
    virtualBatchType: dto.VirtualBatchType,
  };
}

function convertClientDashboardBatchMessagesDtoToModel(
  dto: ClientDashboardBatchMessageDto
): ClientDashboardBatchMessage {
  return {
    message: dto.Message,
    pieces: dto.Pieces?.map(piece => convertBatchMessagePieceDtoToModel(piece)) || [],
    type: dto.Type,
  };
}

function convertBatchMessagePieceDtoToModel(dto: BatchMessagePieceDto): BatchMessagePiece {
  return {
    pieceId: dto.PieceID,
    pin: dto.Pin,
  };
}

function convertDisplayFieldsDtoToModel(dto: ClientDashboardBatchDisplayFieldDto): ClientDashboardBatchDisplayField {
  return {
    sortIndex: dto.SortIndex,
    value: dto.Value,
    caption: dto.Caption,
  };
}
